import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {ConditioningType} from "../types/conditioning";
import {BaseStoreType} from "../types/base-store";

export const useConditioningsStore = defineStore('conditionings', () => {
    const resourceName = 'conditionings';
    const baseUrl = '/api/conditionings';

    const baseStore:BaseStoreType = new BaseStore<ConditioningType>({
        resourceName,
        baseUrl,
        useStore: useConditioningsStore()
    });

    return {
        ...baseStore,
    };
});
