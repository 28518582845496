<template>
    <div class="flex flex-col gap-4 mb-6">
        <div class="flex flex-col gap-4 justify-between mb-6">
            <FilePckr
                id="uploads"
                v-model="uploads"
                @endLoad="handleLoadFile"
            />
        </div>
    </div>

    <Button @click.prevent="handleSubmit" label="Valider" />
</template>

<script lang="ts" setup>
    import {computed, inject, onMounted, ref} from "vue";
    import Button from "primevue/button";
    import FilePckr from "../../components/file-pckr.vue";
    import {useAuditsStore} from "../../store/audit";
    import {Ref} from "vue/dist/vue";
    import type {DynamicDialogInstance} from "primevue/dynamicdialogoptions";

    const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef')

    const uploads = ref<string>()
    const params = computed( () => dialogRef?.value?.data )

    const auditsStore = useAuditsStore();

    onMounted(async () => {

    })

    const handleLoadFile = ({files, id}: { files: [], id: number }) => {
        uploads.value = files.map((file: { serverId: string }) => file.serverId)[0]
    }

    const handleSubmit = async () => {
        await auditsStore.uploadCorrected({
            audit_id: params.value.item.audit_id,
            item_id: params.value.item.id,
            survey_question_id: params.value.item.survey_question_id,
            survey_response_item_id: params.value.item.survey_response_item_id,
            uploads: uploads.value,
            includes: ['items', 'sections.items', 'sections.items.response', 'sections', 'restaurant', 'itemsCorrected']
        })

        dialogRef?.value?.close()
    }
</script>
