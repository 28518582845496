import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {ComplaintType} from "../types/complaint";
import {BaseStoreType} from "../types/base-store";
import Axios from "axios";
import {ref} from "vue";
import COMPLAINT_STATUS from "../enums/complaint-status"
import {useConfigsStore} from "./config";

export const useComplaintsStore = defineStore('complaints', () => {
    const resourceName = 'complaints';
    const baseUrl = '/api/complaints';

    const baseStore:BaseStoreType = new BaseStore<ComplaintType>({
        resourceName,
        baseUrl,
        useStore: useComplaintsStore()
    });

    const isClosePending = ref(false);
    const configsStore = useConfigsStore();

    const closeItem = ({id}:{id:number}) => {
        isClosePending.value = true;

        return Axios.post(`${baseUrl}/${id}/status`, {status: Number(COMPLAINT_STATUS.values.CLOSED.description)})
            .then( () => {
                //
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isClosePending.value = false;
            });
    }

    return {
        ...baseStore,
        closeItem,
        isClosePending
    };
});
