<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        class="mb-6"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Sélectionnez un restaurant
                </h1>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="restaurants?.filter( (restaurant:RestaurantType) => Number(restaurant.id) !== Number(order.restaurant_id))"
            :loading="isFetchRestaurantsPending"
            :rows="1000"
            :totalRecords="restaurants?.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            @row-click="handleSelectRestaurant($event)"
            lazy paginator showGridlines stripedRows removableSort
            selectionMode="single"
            paginatorTemplate=""
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <Column header="Nom" field="name" :sortable="true" />

        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import Breadcrumb from "primevue/breadcrumb";
    import {computed, onBeforeMount, ref} from "vue";
    import {useOrdersStore, useRestaurantsStore} from "../../../../store";
    import {storeToRefs} from "pinia";

    import InputText from "primevue/inputtext";
    import IconField from "primevue/iconfield";
    import InputIcon from "primevue/inputicon";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import {useRouter} from "vue-router";
    import useDebounce from "../../../../composables/debounce";
    import {RestaurantType} from "../../../../types/restaurant";
    import RESTAURANT_TYPES from "../../../../enums/restaurant-types"

    const breadCrumbsFirstItem = computed( () => { return { label: 'Dépannes' , route: {name: 'orders' } } } );

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: order?.value?.restaurant.name})

        items.push({label: 'Choix restaurants'})

        return items;
    });

    const router = useRouter();

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const isFetchRestaurantsPending = restaurantsRefs.isFetchAllPending;
    const restaurants = restaurantsRefs.all;

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const order = ordersRefs.single

    onBeforeMount(() => {
        if(!order?.value?.restaurant_id) {
            router.push({name: 'conveniences' })
        }else {
            restaurantsStore.getAll({search: {category: RESTAURANT_TYPES.values.RESTAURANT.description}});
        }
    });

    const handleGlobalFilterChange = (value:string) => {
        restaurantsStore.getAll({search: {search: value}, includes: []});
    }


    const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
        handleGlobalFilterChange(event);
    }, 1000);

    const handlePageChange = () => {

    }

    const handleSortChange = (event:any) => {
        restaurantsStore.getItems({sort_by: event.sortField, desc: event.sortOrder === 1 })
    }

    const handleSelectRestaurant = (event:any) => {
        order.value.supplier_id = event.data.id;
        order.value.supplier = event.data;

        router.push({ name: 'convenience-create-products' });
    }
</script>
