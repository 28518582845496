<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        class="mb-6"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Sélectionnez le fournisseur
                </h1>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            :value="suppliers"
            :loading="isFetchSuppliersPending"
            :rows="1000"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="suppliers.length"
            @page="handlePageChange"
            @sort="handleSortChange"
            @row-click="handleSelectSupplier($event)"
            lazy paginator showGridlines stripedRows removableSort
            selectionMode="single"
            paginatorTemplate=""
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" field="name" :sortable="true" />

            <Column header="Jour(s) de commande" field="delivery_days" #body="slotProps">
                {{ slotProps.data.plannings.data.map( planning => planning.weekdays.data.map( day => day.label ) ).flat().join(', ') }}
            </Column>

            <Column header="Nbre de référence produits" field="products_count" :sortable="true" />

        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import Breadcrumb from "primevue/breadcrumb";
    import {computed, onBeforeMount, ref} from "vue";
    import {useOrdersStore, useRestaurantsStore} from "../../../../store";
    import {storeToRefs} from "pinia";

    import InputText from "primevue/inputtext";
    import IconField from "primevue/iconfield";
    import InputIcon from "primevue/inputicon";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import {useRouter} from "vue-router";
    import useDebounce from "../../../../composables/debounce";

    const breadCrumbsFirstItem = computed( () => { return { label: 'Commandes' , route: {name: 'orders' } } } );

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: order?.value?.restaurant.name})

        items.push({label: 'Choix Fournisseur'})

        return items;
    });

    const router = useRouter();

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const isFetchSuppliersPending = restaurantsRefs.isFetchSuppliersPending;
    const suppliers = restaurantsRefs.suppliers;

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const order = ordersRefs.single

    const queryParams = ref({
        search: {
            search: null,
        },
        includes: ['products'],
        sort_by: null,
        desc: false,
    })

    onBeforeMount(() => {
        if(!order?.value?.restaurant_id) {
            router.push({name: 'orders'})
        }else {
            restaurantsStore.getSuppliers({id: order?.value?.restaurant_id, ...queryParams.value});
        }
    });

    const handleGlobalFilterChange = (value:string) => {
        queryParams.value.search.search = value;

        restaurantsStore.getSuppliers({id: order?.value?.restaurant_id, ...queryParams.value});
    }

    const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
        handleGlobalFilterChange(event);
    }, 1000);

    const handlePageChange = () => {

    }

    const handleSortChange = (event:any) => {
        queryParams.value.sort_by = event.sortField
        queryParams.value.desc = event.sortOrder === 1

        restaurantsStore.getSuppliers({id: order?.value?.restaurant_id, ...queryParams.value});
    }

    const handleSelectSupplier = (event:any) => {
        order.value.supplier_id = event.data.id;
        order.value.supplier = event.data;

        router.push({ name: 'order-create-products' });
    }
</script>
