export default {
    values: Object.freeze ({
        RESTAURANT: Symbol(1),
        FACTORY: Symbol(2),
    }),
    labels: {
        1: 'Restaurant',
        2: 'Atelier',
    }
}

