import {RouteRecordRaw} from "vue-router";

import Create from "../views/backoffice/audits/Create.vue"
import Edit from "../views/backoffice/audits/Edit.vue"
import Show from "../views/backoffice/audits/Show.vue"
import Revise from "../views/backoffice/audits/Revise.vue"

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/surveys',
        children: [
            {
                path: '/admin/surveys/:survey_id/audit/create',
                name: 'audit-create',
                component: Create,
            },
            {
                path: '/admin/surveys/audit/:id/edit',
                name: 'audit-edit',
                component: Edit,
            },
            {
                path: '/admin/surveys/audits/:id',
                name: 'audit-show',
                component: Show,
            },
            {
                path: '/admin/surveys/audits/:id/revise',
                name: 'audit-revise',
                component: Revise,
            }
        ]
    }
]

export default module;
