import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/tags/List.vue"
import Save from "../views/backoffice/tags/Save.vue";

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/tags',
        children: [
            {
                path: '',
                name: 'tags',
                component: List,
            },
            {
                path: '/admin/tags/:id?/save',
                name: 'tag-save',
                component: Save,
            }
        ]
    }
]

export default module;
