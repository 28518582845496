import {defineStore, storeToRefs} from 'pinia'
import {BaseStore} from "./base";
import {OrderType} from "../types/order";
import {BaseStoreType} from "../types/base-store";
import Axios from "axios";
import {useConfigsStore} from "./config";
import {ref} from "vue";

export const useOrdersStore = defineStore('orders', () => {
    const resourceName = 'orders';
    const baseUrl = '/api/orders';

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore)

    const baseStore:BaseStoreType = new BaseStore<OrderType>({
        resourceName,
        baseUrl,
        useStore: useOrdersStore()
    });

    const isFetchLogsPending = ref(false);
    const logs = ref(<any>[]);
    const todaysOrder = ref(<any>[]);

    const getLogs = async ({id, includes = []}:{id:number, includes:string[]}) => {
        isFetchLogsPending.value = true;

        return Axios.get(`${baseUrl}/${id}/logs`, {
            params: {
                ...(includes.length > 0 && {include: includes.join('|')}),
            }
        })
            .then(response => {
                logs.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchLogsPending.value = false;
            });
    }

    const isFetchTodayPending = ref(false);
    const getToday = async ({includes = []}:{includes:string[]}) => {
        isFetchTodayPending.value = true;

        return Axios.get(`${baseUrl}/today`, {
            params: {
                ...(includes.length > 0 && {include: includes.join('|')}),
            }
        })
            .then(response => {
                todaysOrder.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchTodayPending.value = false;
            });
    }

    const isFetchItemsPending = ref(false);
    const items = ref(<any>[]);

    const getOrderItems = async ({
        id,
        search = {},
        desc = false,
        sort_by = null,
        includes = [],
        list = false
    }:{id:number, search?: Record<string, any>, desc?: boolean, sort_by?: string | null, includes?: string[], list?: boolean;}) => {
        isFetchItemsPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/${id}/items`, {
            params: {
                page: baseStore?.pagination?.value.current_page,
                per_page: baseStore?.pagination?.value.per_page,
                ...filters,
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
                ...(includes.length > 0 && {include: includes.join('|')}),
                list: list,
                translatable: 1,
                locale: locale.value,
            },
        })
            .then(response => {
                items.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchItemsPending.value = false;
            });
    }

    const exportErrors = ref();
    const isConveniencesExportLoading = ref(false);

    const exportConveniences = async ({ start_at, end_at }: { start_at:any; end_at:any; }) => {
        const configStore = useConfigsStore();
        const configRefs = storeToRefs(configStore);
        
        exportErrors.value = null
        isConveniencesExportLoading.value = true;

        return Axios.post(
            configRefs.appUrl.value + baseUrl + "/export/conveniences",
            {start_at: start_at, end_at: end_at}
        )
            .then((response) => {
                configStore.addToast({
                    severity: "success",
                    summary: "Bravo",
                    detail: "L'export va se télécharger !",
                });
                return new Promise((resolve, reject) => {
                    resolve(response.data.data);
                });
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    configsStore.addToast({
                        severity: 'warn',
                        summary: 'Attention',
                        detail: 'Il y a des erreurs dans le formulaire'
                    })

                    exportErrors.value = error.response.data.errors
                }
                if (error.response.status === 404) {
                    configStore.addToast({
                        severity: "warn",
                        summary: "Oups...",
                        detail: "Resource(s) non trouvée",
                    });
                }
                if (error.response.status === 400) {
                    configStore.addToast({
                        severity: "error",
                        summary: "Erreur",
                        detail: error.response.data.message,
                    });
                }
            })
            .finally(() => {
                isConveniencesExportLoading.value = false;
            });
    };

    return {
        ...baseStore,
        getLogs,
        isFetchLogsPending,
        logs,
        getToday,
        isFetchTodayPending,
        todaysOrder,
        getOrderItems,
        isFetchItemsPending,
        items,
        exportConveniences,
        exportErrors,
        isConveniencesExportLoading,
    };
});
