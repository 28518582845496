<template>
    <div>
        <IconField :ptOptions="{position: 'left'}">
            <InputIcon
                class="pi pi-search"
                pt:root="bg-white border-none"
            />
            {{ sessionFilters?.search?.search }}
            <InputText
                placeholder="Recherche"
                :value="sessionFilters?.filters?.global?.value"
                @update:modelValue="handleGlobalFilterChangeDebounced"
                pt:root="border-b p-3 border-surface-400"
            />
        </IconField>
    </div>
</template>
<script setup lang="ts">
    import IconField from "primevue/iconfield";
    import InputIcon from "primevue/inputicon";
    import InputText from "primevue/inputtext";
    import useDebounce from "../../../composables/debounce";
    import {ref} from "vue";

    const emits = defineEmits(['search']);
    const props = defineProps(['stateKey', 'modelValue'])

    const sessionFilters = props.stateKey && sessionStorage[props.stateKey] ? ref(JSON.parse(sessionStorage[props.stateKey] as string)) : null;

    const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
        if(sessionFilters && sessionFilters.value) {
            sessionFilters.value.filters.global.value = event;
            sessionStorage[props.stateKey] = JSON.stringify(sessionFilters.value);
        }

        emits('search', event)
    }, 1000);
</script>
