export default {
    values: Object.freeze ({
        CONTENT: Symbol(1),
        T: Symbol(2),
        IMAGE: Symbol(3),
    }),
    labels: {
        1: 'Contenu',
        2: 'Titre',
        3: 'Image'
    }
}

