<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Inventaires
                </h1>

                <SplitButton
                    v-if="restaurantsOptions.length > 0"
                    label="+ Effectuer un inventaire"
                    @click.prevent="handleCreateInventory(restaurantsOptions[0].id)"
                    :model="restaurantsOptions"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                />
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="inventories"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Restaurant" field="restaurant.name" :sortable="true" #body="slotProps">
                {{ slotProps.data.restaurant?.name }}
            </Column>

            <Column header="Effectué le" field="created_at" :sortable="true" #body="slotProps">
                {{ new Intl.DateTimeFormat('fr-BE').format(new Date(slotProps.data.created_at)) }}
            </Column>

            <Column header="Action(s)" #body="slotProps" class="flex items-center gap-2">
                <!--
                <Button
                    link
                    label="Modifier"
                    @click.prevent="handleEditInventory(slotProps.data)"
                    v-tooltip="'Éditer'">
                    <i class="pi pi-pencil"></i>
                </Button>
                -->
                <Button
                    link
                    label="Export en XLS"
                    @click.prevent="handleExportXLS(slotProps.data)"
                    v-tooltip="'Export en XLS'">
                    <i class="pi pi-download"></i>
                </Button>
                <Button
                    link
                    label="Supprimer"
                    @click.prevent="handleDeleteInventory(slotProps.data)"
                    v-tooltip="'Supprimer'">
                    <i class="pi pi-trash"></i>
                </Button>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import {useRouter} from "vue-router";
import {useConfirm} from "primevue/useconfirm";
import {computed, onBeforeMount, ref} from "vue";
import {useConfigsStore, useInventoriesStore, useRestaurantsStore} from "../../../store";
import {storeToRefs} from "pinia";
import SplitButton from "primevue/splitbutton";
import {InventoryType} from "../../../types/inventory";
import {RestaurantType} from "../../../types/restaurant";
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import Search from "../../../components/partials/backoffice/search.vue";
import Axios from "axios";
import PRODUCT_STATUS from "@/backoffice/enums/product-status";
import useDatatableActions from "@/backoffice/composables/datatable-actions";

const router = useRouter()
const confirm = useConfirm()

const inventoriesStore = useInventoriesStore();
const inventoriesRefs = storeToRefs(inventoriesStore);
const pagination = inventoriesRefs.pagination;
const isFetchListPending = inventoriesRefs.isFetchListPending;
const inventories = inventoriesRefs.list;

const restaurantsStore = useRestaurantsStore();
const restaurantsRefs = storeToRefs(restaurantsStore);
const restaurants = restaurantsRefs.list;
const restaurantsOptions = ref([]);

const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });
const breadCrumbsItems = computed(() => [{label: 'Inventaires'}]);

const queryParams = ref({
    search: {
        search: null,
    },
    includes: ["restaurant"],
    sort_by: null,
    desc: false,
})

const { handlePageChange, handleSortChange, handleGlobalSearch } = useDatatableActions(pagination, queryParams, inventoriesStore.getItems)

onBeforeMount(async () => {
    await inventoriesStore.getItems({includes: ["restaurant"]});

    await restaurantsStore.getItems();

    restaurants.value.map( ({id, name}: RestaurantType) => {
        restaurantsOptions.value.push({id: id, label: name, command: () => { handleCreateInventory(id)} })
    })
})

const handleDeleteInventory = (inventory:InventoryType) => {
    confirm.require({
        message: 'Êtes vous sur de vouloir supprimer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        acceptClass: 'p-button-danger',
        accept: () => {inventoriesStore.deleteItem(inventory.id)}
    })
}

const handleCreateInventory = (id:number) => {
    router.push({ name: 'restaurant-inventory', params: { id: id} });
}

const handleEditInventory = (inventory:InventoryType) => {
    inventoriesRefs.single.value = null
    router.push({name: 'inventory-save',  params: (inventory ? {id: inventory.id} : {}) });
}

const configsStore = useConfigsStore();
const configsRefs = storeToRefs(configsStore);

const handleExportXLS = (inventory:InventoryType) => {
    Axios.post(`${configsRefs.appUrl.value}/api/inventories/${inventory.id}/export`).then( (response) => {
        window.open(response.data.data.url);
    })
}
</script>
