<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between sm:flex-row flex-col">
            <h1 class="mr-6 text-5xl uppercase font-title">
                {{ survey?.translatable.title[locale] }}
            </h1>
            <div class="flex gap-2">
                <Button severity="success" size="small">Importer en XLS</Button>
                <Button severity="success" size="small">Exporter en XLS</Button>
            </div>
        </div>

        <Button @click="handleAddOrEditSection" class="mb-4">+ Ajouter une section</Button>

        <div class="flex flex-col gap-4">
            <Draggable
                class=""
                handle=".input-handle"
                v-model="survey.sections.data"
                @end="handleSectionDragEnd"
                item-key="id"
            >
                <template #item="{element: section, index}">
                    <div class="mb-4">
                        <div class="bg-surface-50 p-2 rounded-t-md border border-surface-100 flex items-center justify-between">
                            <div class="flex gap-2 items-center">
                                <Button size="small" class="input-handle">
                                    <i class="pi pi-bars"></i>
                                </Button>
                                <h3>{{ section.translatable.title[locale] }}</h3>
                            </div>
                            <div class="flex gap-2">
                                <Button
                                    size="small"
                                    label="Voir"
                                    @click.prevent="handleAddOrEditQuestion(section)"
                                    v-tooltip="{value: 'Ajouter une question', class: 'tooltip-normal'}">
                                    <i class="pi pi-plus"></i>
                                </Button>
                                <Button
                                    label="Voir"
                                    size="small"
                                    @click.prevent="handleDeleteSection(section)"
                                    v-tooltip="{value: 'Supprimer une section', class: 'tooltip-normal'}">
                                    <i class="pi pi-trash"></i>
                                </Button>
                            </div>
                        </div>
                        <div class="p-2 rounded-b-md border border-surface-100">
                            <Draggable
                                class=""
                                handle=".input-handle"
                                v-model="section.questions.data"
                                @end="handleQuestionDragEnd(section)"
                                item-key="id"
                            >
                                <template #item="{element: question, index}">
                                    <div class="bg-surface-50 p-2 rounded-md flex items-center justify-between mb-2">
                                        <div class="flex gap-2 items-center">
                                            <Button size="small" class="input-handle">
                                                <i class="pi pi-bars"></i>
                                            </Button>
                                            <h3>{{ question.translatable.title[locale] }}</h3>
                                        </div>
                                        <div class="flex gap-2">
                                            <Button
                                                size="small"
                                                label="Éditer"
                                                @click.prevent="handleAddOrEditQuestion(section, question)"
                                                v-tooltip="{value: 'Modifier une question', class: 'tooltip-normal'}">
                                                <i class="pi pi-pencil"></i>
                                            </Button>
                                            <Button
                                                label="Voir"
                                                size="small"
                                                @click.prevent="handleDuplicateQuestion(question)"
                                                v-tooltip="{value: 'Dupliquer une question', class: 'tooltip-normal'}">
                                                <i class="pi pi-file"></i>
                                            </Button>
                                            <Button
                                                label="Voir"
                                                size="small"
                                                @click.prevent="handleDeleteQuestion(question)"
                                                v-tooltip="{value: 'Supprimer une question', class: 'tooltip-normal'}">
                                                <i class="pi pi-trash"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </template>
                            </Draggable>
                        </div>
                    </div>
                </template>
            </Draggable>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";

    import ProgressSpinner from "primevue/progressspinner";
    import Button from "primevue/button";

    import {useRoute} from "vue-router";

    import {storeToRefs} from "pinia";
    import {useSurveysStore, useConfigsStore} from "../../../store";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import {useDialog} from "primevue/usedialog";
    import AddSurveySection from "../../../components/dialogs/AddSurveySection.vue";
    import AddSectionQuestion from "../../../components/dialogs/AddSectionQuestion.vue"
    import Draggable from "vuedraggable";
    import {useConfirm} from "primevue/useconfirm";

    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Audits' , route: {name: 'surveys'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (survey?.value?.id) { items.push({label: survey?.value?.translatable.title[locale.value] || 'N/A'}) }

        items.push({label: 'Questions'})

        return items;
    });

    const queryParams = ref({
        search: {
            search: null,
        },
        includes: ['sections', 'sections.questions', 'sections.questions.illustration'],
        sort_by: null,
        desc: false,
    })

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore);

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const survey = surveysRefs.single;

    const isFetchPending = surveysRefs.isFetchSinglePending;

    onBeforeMount(async () => {
        await surveysStore.getItem({...queryParams.value, id: String(route.params.id)})
    })

    const dialog = useDialog();

    const handleAddOrEditSection = (section?:any) => {
        dialog.open(AddSurveySection, {
            props: {
                header: 'Ajouter une section',
                modal: true,
            },
            data: {
                survey: survey.value,
                section: section
            },
            onClose: () => {
                surveysStore.getItem({...queryParams.value, id: String(route.params.id)})
            }
        })
    }

    const confirm = useConfirm();

    const handleDeleteSection = (section:any) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {surveysStore.deleteSection(section.id)}
        })
    }

    const handleAddOrEditQuestion = (section:any, question?:any) => {
        dialog.open(AddSectionQuestion, {
            props: {
                header: 'Ajouter une question',
                modal: true
            },
            data: {
                section: section,
                question: question
            },
            onClose: async () => {
                await surveysStore.getItem({...queryParams.value, id: String(route.params.id)})
            }
        })
    }

    const handleSectionDragEnd = () => {
        surveysStore.moveSections(survey.value.sections.data)
    }

    const handleQuestionDragEnd = (section:any) => {
        surveysStore.moveQuestions(section.questions.data)
    }
</script>
