<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10 mb-24">
        <div class="flex mb-10 justify-between">
            <div class="flex items-center gap-2">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Produits
                </h1>

                <Button @click="handleCreateOrEditProduct" v-if="authStore.isAdmin()">+ Ajouter un produit</Button>

                <SplitButton
                    label="+ Effectuer un inventaire"
                    :model="restaurantsOptions"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                    pt:ptMenu:root="font-normal"
                />

                <Button
                    label="Export en XLS"
                    @click.prevent="handleExportXLS"
                    severity="success"
                    :loading="loadingExport"
                    v-tooltip="'Export en XLS'">
                </Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="products.filter( (product:ProductType) => Number(product.status) === Number(queryParams.search.status))"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <template #header>
                <div class="flex mb-4">
                    <Button
                        link
                        @click.prevent="handleChangeStatus(PRODUCT_STATUS.values.ACTIVE.description)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === PRODUCT_STATUS.values.ACTIVE.description ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Actif
                    </Button>
                    <Button
                        link
                        @click.prevent="handleChangeStatus(PRODUCT_STATUS.values.INACTIVE.description)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === PRODUCT_STATUS.values.INACTIVE.description ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Inactif
                    </Button>
                </div>
            </template>

            <Column header="ID" field="id" :sortable="true" sort-field="id"></Column>

            <Column header="Nom" :sortable="true" sort-field="name" #body="slotProps">
                <div class="flex gap-2">
                    {{ slotProps.data.name }}
                    <small>({{ slotProps.data.conditioning_quantity }}
                        {{ slotProps.data.conditioning.label }})</small>
                    <template v-if="slotProps.data.note">
                        <i class="text-orange-500 pi pi-exclamation-triangle"
                           v-tooltip="slotProps.data.note"
                        ></i>
                    </template>
                </div>
            </Column>

            <Column header="Fournisseur" field="supplier.name" :sortable="true" sort-field="supplier.name" #body="slotProps">
                {{ slotProps.data.supplier.name }}
<!--                <template v-if="authStore.isAdmin()">
                    ({{ slotProps.data.supplier.id }})
                </template>-->
            </Column>

            <Column header="Prix" field="price" :sortable="true" sort-field="price" #body="slotProps">
                {{ slotProps.data.price.toFixed(2) }} €
            </Column>

            <Column header="Utilisation" field="usage" :sortable="true" class="w-1/6"></Column>

            <Column header="Tag(s)" field="tags" :sortable="false" #body="slotProps">
                <template v-for="tag in slotProps.data.tags.slice(0, 2)">
                    <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                </template>
                <template v-if="slotProps.data.tags.length > 2">
                    (+{{ slotProps.data.tags.length - 2 }})
                </template>
            </Column>

            <Column header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center">
                        <template v-if="authStore.isAdmin()">
                            <Button
                                label="Gestion des quantités maximum"
                                @click.prevent="handleStockManagementProduct(slotProps.data)"
                                link
                                v-tooltip="'Gestion des quantités maximum'">
                                <i class="pi pi-barcode"></i>
                            </Button>
                            <Button
                                label="Modifier"
                                @click.prevent="handleCreateOrEditProduct(slotProps.data)"
                                link
                                v-tooltip="'Éditer'">
                                <i class="pi pi-pencil"></i>
                            </Button>
                            <Button
                                label="Supprimer"
                                @click.prevent="handleDeleteProduct(slotProps.data)"
                                link
                                v-tooltip="'Supprimer'">
                                <i class="pi pi-trash"></i>
                            </Button>
                        </template>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";

    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import SplitButton from "primevue/splitbutton";
    import Tag from "primevue/tag";

    import {useRouter} from "vue-router";
    import {useConfirm} from "primevue/useconfirm";

    import {storeToRefs} from "pinia";
    import {useProductsStore, useRestaurantsStore, useAuthStore, useConfigsStore} from "../../../store";

    import {ProductType} from "../../../types/product";
    import {RestaurantType} from "../../../types/restaurant";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";
    import PRODUCT_STATUS from "../../../enums/product-status";
    import useDatatableActions from "../../../composables/datatable-actions";
    import Axios from "axios";

    const router = useRouter();
    const confirm = useConfirm()

    const productsStore = useProductsStore();
    const productsRefs = storeToRefs(productsStore);
    const pagination = productsRefs.pagination;
    pagination.value.per_page = 1000;
    const isFetchListPending = productsRefs.isFetchListPending;
    const products = productsRefs.list;

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const restaurants = restaurantsRefs.list;
    const restaurantsOptions = ref([]);

    const authStore = useAuthStore();

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

    const breadCrumbsItems = computed(() => [{label: 'Produits'}]);

    const loadingExport = ref(false);

    const queryParams = ref({
        search: {
            search: null,
            status: PRODUCT_STATUS.values.ACTIVE.description
        },
        includes: ['tags','supplier', 'conditioning'],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch, handleChangeStatus} = useDatatableActions(pagination, queryParams, productsStore.getItems)

    onBeforeMount(async () => {
        await productsStore.getItems(queryParams.value);

        await restaurantsStore.getItems();

        restaurants.value.map( ({id, name}: RestaurantType) => {
            restaurantsOptions.value.push({id: id, label: name, command: () => { handleTakeInventory(id)} })
        })
    })

    const handleDeleteProduct = (product:ProductType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {productsStore.deleteItem(product.id)}
        })
    }

    const handleCreateOrEditProduct = (product:ProductType) => {
        productsRefs.single.value = null
        router.push({name: 'product-save',  params: (product ? {id: product.id} : {}) });
    }

    const handleStockManagementProduct = (product:ProductType) => {
        router.push({name: 'product-stock',  params: {id: product.id} });
    }

    const handleTakeInventory = (restaurant_id:number) => {
        router.push({name: 'restaurant-inventory', params: {id: restaurant_id}});
    }

    const handleExportXLS = () => {
        loadingExport.value = true;
        Axios.post(`${configsRefs.appUrl.value}/api/products/export`)
            .then( (response) => {
                window.open(response.data.data.url);
            })
            .finally(() => {
                loadingExport.value = false;
            });
    }
</script>
