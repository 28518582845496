<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between gap-2">
            <div class="flex items-center">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Dépannes
                </h1>

                <SplitButton
                    v-if="restaurantsOptions.length > 0"
                    label="+ Demander une dépanne"
                    @click.prevent="handleCreateOrder(restaurantsOptions[0].id)"
                    :model="restaurantsOptions"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                />

                <div v-else class="text-lg text-red-500 font-bold">
                    Vous n'avez accès à aucun restaurant
                </div>
            </div>

            <div class="flex gap-2 items-center justify-center">
                <div class="flex flex-col">
                    <DatePicker dateFormat="dd/mm/yy" placeholder="Date début" v-model="exportStartAt" />
                    <small class="text-red-500" v-if="errors?.start_at">{{ errors?.start_at.join(', ') }}</small>
                </div>

                <div class="flex flex-col">
                    <DatePicker dateFormat="dd/mm/yy" placeholder="Date fin" v-model="exportEndAt" />
                    <small class="text-red-500" v-if="errors?.end_at">{{ errors?.end_at.join(', ')}}</small>
                </div>
                <Button severity="success" @click="handleExportConveniences" :loading="isConveniencesExportLoading">Exporter</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="orders.filter( (order:OrderType) => queryParams.search.status !== null ? order.status === queryParams.search.status : true)"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <template #header>
                <div class="flex mb-4">
                    <Button
                        link
                        @click.prevent="handleChangeStatus(null)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === null ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Toutes
                    </Button>
                    <template v-for="status in ORDER_STATUS.values">
                        <Button
                            v-if="Number(status.description) !== 5 && Number(status.description) !== 6"
                            link
                            @click.prevent="handleChangeStatus(Number(status.description))"
                            class="py-3 pb-4 px-4 rounded-none outline-none"
                            :pt="{root: {class: Number(queryParams.search.status) === Number(status.description) ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                        >
                            {{ ORDER_STATUS.labels[status.description] }}
                        </Button>
                    </template>
                </div>
            </template>

            <Column header="Date" :sortable="true" field="created_at" #body="slotProps">
                {{ new Intl.DateTimeFormat('fr-BE').format(new Date(slotProps.data.created_at)) }}
            </Column>

            <Column v-if="restaurantsOptions.length > 1 " header="Restaurant" :sortable="true" field="restaurant.name" #body="slotProps">
                {{ slotProps.data.restaurant?.name }}
            </Column>

            <Column header="Fournisseur" :sortable="true" field="supplierRestaurant" #body="slotProps">
                {{ slotProps.data.supplier_restaurant?.name }}
            </Column>

            <Column header="Ref" :sortable="true" field="ref" #body="slotProps">
                {{ slotProps.data.reference }}
            </Column>

            <Column header="Prix" :sortable="true" field="price" #body="slotProps">
                {{ slotProps.data.total_amount.toFixed(2) }} €
            </Column>

            <Column header="Statut" :sortable="true" field="status" #body="slotProps">
                <template v-if="slotProps.data.status === 1">
                    <Tag :value="status[1]" severity="secondary" class="bg-surface-400"/>
                </template>
                <template v-if="slotProps.data.status === 2">
                    <Tag :value="status[2]" severity="info"/>
                </template>
                <template v-if="slotProps.data.status === 3">
                    <Tag :value="status[3]" severity="info" />
                </template>
                <template v-if="slotProps.data.status === 4">
                    <Tag :value="status[4]" severity="success" />
                </template>
            </Column>

            <Column header="Action(s)" #body="slotProps">
                <Button
                    link
                    label="Voir"
                    @click.prevent="handleShowOrder(slotProps.data)"
                    v-tooltip="'Voir'">
                    <i class="pi pi-eye"></i>
                </Button>

                <Button
                    v-if="slotProps.data.status === 1 || slotProps.data.status === 2"
                    link
                    label="Modifier"
                    @click.prevent="handleCreateOrEditOrder(slotProps.data)"
                    v-tooltip="'Éditer'">
                    <i class="pi pi-pencil"></i>
                </Button>

                <Button
                    v-if="slotProps.data.status === 3"
                    link
                    label="Réceptionner"
                    @click.prevent="handleReceiveOrder(slotProps.data)"
                    v-tooltip="'Réceptionner'">
                    <i class="pi pi-box"></i>
                </Button>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Tag from "primevue/tag";
import DatePicker from "primevue/datepicker";

import { useRouter} from "vue-router";
import {computed, onBeforeMount, onBeforeUnmount, ref} from "vue";
import {useOrdersStore, useRestaurantsStore} from "../../../store";
import {storeToRefs} from "pinia";
import {OrderType} from "../../../types/order";
import {RestaurantType} from "../../../types/restaurant";

import ORDER_STATUS from "../../../enums/order-status"
import ORDER_TYPE from "../../../enums/order-type";
import {useDialog} from "primevue/usedialog";

import AddCreditNoteNumber from "../../../components/dialogs/AddCreditNoteNumber.vue";
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import Search from "../../../components/partials/backoffice/search.vue";
import useDatatableActions from "../../../composables/datatable-actions";

const status = ORDER_STATUS.labels;

const router = useRouter()

const ordersStore = useOrdersStore();
const ordersRefs = storeToRefs(ordersStore);
const pagination = ordersRefs.pagination;
const isFetchListPending = ordersRefs.isFetchListPending;
const orders = ordersRefs.list;
const errors = ordersRefs.exportErrors;
const isConveniencesExportLoading = ordersRefs.isConveniencesExportLoading

const restaurantsStore = useRestaurantsStore();
const restaurantsRefs = storeToRefs(restaurantsStore);
const restaurants = restaurantsRefs.list;
const restaurantsOptions = ref([]);

const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });
const breadCrumbsItems = computed(() => [{label: 'Dépannes'}]);

pagination.value.per_page = 1000;

const queryParams = ref({
    search: {
        type: ORDER_TYPE.values.CONVENIENCE.description,
        search: null,
        status: null
    },
    includes: ['supplierRestaurant', 'restaurant'],
    sort_by: null,
    desc: false,
})

const {handlePageChange, handleSortChange, handleGlobalSearch, handleChangeStatus} = useDatatableActions(pagination, queryParams, ordersStore.getItems)

onBeforeMount(async () => {
    await ordersStore.getItems({search: {type: ORDER_TYPE.values.CONVENIENCE.description}, includes: ['supplierRestaurant', 'restaurant']});
    await restaurantsStore.getItems();

    restaurants.value.map( ({id, name}: RestaurantType) => {
        restaurantsOptions.value.push({id: id, label: name, command: () => { handleCreateOrder(id)} })
    })
})

const handleCreateOrEditOrder = (order:OrderType) => {
    ordersRefs.single.value = null
    router.push({name: 'convenience-save',  params: (order ? {id: order.id} : {}) });
}

const handleCreateOrder = (id:number) => {
    let restaurant = restaurants.value.find( (restaurant:RestaurantType) => restaurant.id === id)
    ordersRefs.single.value = { restaurant_id: restaurant.id, restaurant: restaurant };
    router.push({ name: 'convenience-create-restaurant' });
}

const handleReceiveOrder = (order:OrderType) => {
    router.push({ name: 'convenience-receive', params: { id: order.id} });
}

const handleShowOrder = (order:OrderType) => {
    router.push({ name: 'convenience-show', params: { id: order.id} });
}

const handleReconcileOrder = (order:OrderType) => {
    router.push({ name: 'convenience-reconcile', params: { id: order.id} });
}

const dialog = useDialog();

const handleCreditNoteNumber = (order:OrderType) => {
    dialog.open(AddCreditNoteNumber, {
        props: {
            header: 'Note de crédit',
            modal: true
        },
        data: {
            order: order
        },
        onClose: () => {
            ordersStore.getItems({search: {type: ORDER_TYPE.values.CONVENIENCE.description}, includes: ['supplierRestaurant', 'restaurant']});
        }
    })
}

onBeforeUnmount( async () => {
    orders.value = [];
})

const exportStartAt = ref();
const exportEndAt = ref();

const handleExportConveniences = () => {
    ordersStore.exportConveniences({start_at: exportStartAt.value, end_at: exportEndAt.value}).then((data:any) => {
        window.location.href = data.url;
    });
}
</script>
