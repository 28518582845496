<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ tag?.id ? 'Modifier un tag' : 'Ajouter un tag' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="label" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="label"
                    name="label"
                    v-model="form.label[locale]"
                    :invalid="form.invalid('label')"
                    @change="form.validate('label')"
                />
                <small v-if="form.invalid('label')" class="text-red-500"> {{ form.errors.label }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="color" class="text-dark-slate-600 uppercase tracking-widest">Couleur</label>
                <ColorPicker
                    id="color"
                    name="color"
                    format="hex"
                    v-model="form.color"
                    :invalid="form.invalid('color')"
                    @change="form.validate('color')"
                />

                <InputText
                    id="color"
                    name="color"
                    format="hex"
                    v-model="form.color"
                    :invalid="form.invalid('color')"
                    @change="form.validate('color')"
                />
                <small v-if="form.invalid('color')" class="text-red-500"> {{ form.errors.color }} </small>
            </div>

            <div>
                <Button @click="handleSubmit">
                    {{ tag?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount} from "vue";

import InputText from "primevue/inputtext";
import Breadcrumb from "primevue/breadcrumb";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import ColorPicker from "primevue/colorpicker";

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";

import {storeToRefs} from "pinia";
import {useTagsStore, useConfigsStore} from "../../../store";

const route = useRoute()
const router = useRouter();

const breadCrumbsFirstItem = computed( () => {
    return { label: 'Tags' , route: {name: 'tags'} }
})

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    if (tag?.value?.id) {
        items.push({label: tag?.value?.label || 'N/A'})
    }

    items.push({label: tag?.value?.id ? 'Modifier' : 'Ajouter'})

    return items;
});

const configsStore = useConfigsStore();
const configsRefs = storeToRefs(configsStore);
const locale = configsRefs.locale;

const tagsStore = useTagsStore();
const tagsRefs = storeToRefs(tagsStore);
const tag = tagsRefs.single;
const isFetchPending = tagsRefs.isFetchSinglePending;

onBeforeMount(async () => {
    if (route.params.id) {
        await tagsStore.getItem({id: String(route.params.id)})
    } else {
        tag.value = {
            translatable: {
                label: {
                    fr: '',
                    en: ''
                },
            },
            color: ''
        }
    }

    form.setData({
        ...tag?.value,
        label: tag?.value?.translatable?.label
    })
})

const form = useForm(
    route.params.id ? 'patch' : 'post',
    route.params.id
        ? '/api/tags/' + route.params.id
        : '/api/tags',
    {
        label: {fr: '', en: ''},
        color: ''
    }
);

const handleSubmit = () => {
    tagsRefs.isSavePending.value = true;

    let untouchedData = form.data();

    form
        .submit()
        .then(() => {
            if (route.params.id) {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le tag a bien été modifié'})
            } else {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le tag a bien été créé'})
            }

            return router.push({name: 'tags'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            tagsRefs.isSavePending.value = false;
        })
}
</script>
