<template>
    <div class="flex flex-col gap-4 mb-6">
        <div class="flex flex-col gap-2">
            <label for="title" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
            <InputText
                id="title"
                type="text"
                label="label"
                v-model="form.title[locale]"
                :invalid="form.invalid('title')"
                @change="form.validate('title')"
            />
            <small v-if="form.invalid('title')" class="text-red-500"> {{ form.errors.title }} </small>
        </div>

        <div class="flex flex-col gap-2">
            <label for="type" class="text-dark-slate-600 uppercase tracking-widest">Type de réponse</label>
            <Select
                id="type"
                name="type"
                optionLabel="name"
                :options="types"
                placeholder="Sélectionnez un type"
                v-model="form.type"
                :invalid="form.invalid('type')"
                @change="form.validate('type')"
            />
            <small v-if="form.invalid('type')" class="text-red-500"> {{ form.errors.type }} </small>
        </div>
    </div>

    <Button @click.prevent="handleSubmit" label="Valider" />
</template>

<script lang="ts" setup>
    import {inject, onMounted, ref} from "vue";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import Select from "primevue/select";

    import RESPONSE_TYPES from "../../enums/response-types"
    import {useForm} from "laravel-precognition-vue";
    import {useConfigsStore} from "../../store";
    import {storeToRefs} from "pinia";

    const dialogRef = inject('dialogRef');
    const isSavePending = ref(false);

    const configsStore = useConfigsStore();
    const { locale } = storeToRefs(configsStore)

    const types = Object.entries(RESPONSE_TYPES.labels).map( content => {
        return {id: content[0], name: content[1]}
    });

    const params = ref(dialogRef.value.data);

    const form = useForm(
        params.value.item && params.value.item.id ? 'put' :'post',
        () => params.value.item && params.value.item.id ?
            '/api/surveys/responses/' + params.value.response.id + '/items/' + params.value.item.id :
            '/api/surveys/responses/' + params.value.response.id + '/items',
        {
            title: {fr: ''},
            type: '',
        }
    );

    if(params.value.item.id) {
        form.setData({
            title: params.value.item.translatable.title,
            type: types.find( (type) => Number(params.value.item.type) === Number(type.id) ),
        })
    }

    const handleSubmit = () => {
        isSavePending.value = true;

        const untouchedData = form.data();

        form
            .setData({
                response_id: params?.value?.response?.id,
                type: untouchedData?.type?.id
            })
            .submit()
            .then(() => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La réponse a bien été ajoutée'})

                dialogRef.value.close()
            })
            .catch(error => {
                form.setData({...untouchedData});

                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }
            })
            .finally(() => {
                isSavePending.value = false;
            })
    }
</script>
