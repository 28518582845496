<template>
    <div class="flex flex-col gap-4 mb-6">
        <div class="flex flex-col gap-2">
            <label for="weekdays" class="text-dark-slate-600 uppercase tracking-widest">Jour(s) de commande</label>
            <MultiSelect
                id="weekdays"
                name="weekdays"
                display="chip"
                optionLabel="label"
                :options="weekdays"
                placeholder="Sélectionnez un jour"
                v-model="form.weekdays"
                :invalid="form.invalid('weekdays')"
                @change="form.validate('weekdays')"
            />
            <small v-if="form.invalid('weekdays')" class="text-red-500"> {{ form.errors.weekdays }} </small>
        </div>

        <div class="flex flex-col gap-2 flex-1">
            <label for="delivery_days" class="text-dark-slate-600 uppercase tracking-widest">Délais de livraison (en jour(s) ouvrable(s))</label>
            <InputNumber
                id="delivery_days"
                name="delivery_days"
                v-model="form.delivery_days"
                :invalid="form.invalid('delivery_days')"
                @change="form.validate('delivery_days')"
            />
            <small v-if="form.invalid('delivery_days')" class="text-red-500"> {{ form.errors.delivery_days }} </small>
        </div>

        <div class="flex flex-col gap-2">
            <label for="restaurants" class="text-dark-slate-600 uppercase tracking-widest">Concerne</label>
            <MultiSelect
                v-model="form.restaurants"
                :options="restaurantsOptions"
                display="chip"
                placeholder="Sélectionnez un ou plusieurs restaurant(s)"
                optionLabel="label"
                :invalid="form.invalid('restaurants')"
                @change="form.validate('restaurants')"
            />
            <small v-if="form.invalid('restaurants')" class="text-red-500"> {{ form.errors.restaurants }} </small>
        </div>

        <div class="flex flex-col gap-2 flex-1">
            <label for="note" class="text-dark-slate-600 uppercase tracking-widest">Information complémentaire</label>
            <Textarea
                id="note"
                name="note"
                v-model="form.note"
                :invalid="form.invalid('note')"
                @change="form.validate('note')"
            />
            <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
        </div>
    </div>

    <Button @click.prevent="handleSubmit" label="Valider" />
</template>

<script lang="ts" setup>
    import {inject, onMounted, ref} from "vue";
    import {useForm} from "laravel-precognition-vue";
    import {useConfigsStore, useRestaurantsStore} from "../../store";
    import Button from "primevue/button";
    import Axios from "axios";
    import {storeToRefs} from "pinia";
    import MultiSelect from "primevue/multiselect";
    import InputNumber from "primevue/inputnumber";
    import Textarea from "primevue/textarea";
    import {RestaurantType} from "../../types/restaurant";

    const dialogRef = inject('dialogRef');
    const isSavePending = ref(false);

    const configStore = useConfigsStore();
    const configRefs = storeToRefs(configStore);

    const weekdays = ref();

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const restaurants = restaurantsRefs.list;
    const restaurantsOptions = ref([]);
    const params = dialogRef.value.data;

    onMounted(async () => {
        await Axios.get(configRefs.appUrl.value + '/api/weekdays?list=true', { params: { list: true } })
            .then(response => {
                weekdays.value = response.data.data
            })
            .catch(error => {
                console.warn(error);
                if (error?.response?.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error?.response?.status === 400) {
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
                if(error?.code === 'ERR_NETWORK') {
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: 'Erreur serveur'});
                }
            })

        await restaurantsStore.getItems({list: true});

        restaurants.value.map( ({id, name}: RestaurantType) => {
            restaurantsOptions.value.push({id: id, label: name })
        })

        if(params.planning.id) {
            form.setData({
                ...params.planning,
                weekdays: weekdays.value.filter( (weekday:any) => params.planning.weekdays.data.map( (weekday:any) => weekday.id ).includes(weekday.id) ),
                restaurants: restaurantsOptions.value.filter( (restaurant:any) => params.planning.restaurants.data.map( (restaurant:any) => restaurant.id ).includes(restaurant.id) )
            })
        }else {
            form.setData({
                supplier_id: params.supplier.id,
            })
        }
    })

    const form = useForm(
        params.planning.id ? 'patch' : 'post',
        params.planning.id ? '/api/supplier-plannings/' + params.planning.id : '/api/supplier-plannings',
        {
            supplier_id: null,
            delivery_days: null,
            note: null,
            weekdays: [],
            restaurants: [],
        }
    );

    const handleSubmit = () => {
        isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                weekdays: form.weekdays.map( weekday => weekday.id ),
                restaurants: form.restaurants.map( restaurant => restaurant.id )
            })
            .submit()
            .then(() => {
                if(params.planning) {
                    configStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le planning a bien été modifiée'})
                }else {
                    configStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le planning a bien été crée'})
                }

                dialogRef.value.close()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                isSavePending.value = false;
            })
    }
</script>
