<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between text-surface-600">
            <div>
                <h2 class="text-3xl mb-2 font-semibold">Note de livraison</h2>
                <div v-html="order?.supplier?.note" />
            </div>
            <div>
                <h2 class="text-3xl mb-2 font-semibold">Planning</h2>
                <ul class="text-sm">
                    <li v-for="day in order?.supplier?.plannings.data.map( (planning:any) => planning.weekdays.data.map( (weekday:any) => [ weekday.label + ' (j+' + planning.delivery_days + ') - ' + planning.note ]) ).flat(2)">
                        {{ day }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Ajouter une commande
                </h1>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="products"
            :loading="isFetchProductsPending"
            :rows="1000"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            @sort="handleSortChange($event)"
            paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #header>
                <MultiSelect
                    v-model="selectedColumns"
                    :options="columnsOptions"
                    optionLabel="name"
                    :maxSelectedLabels="3"
                    placeholder="Select Columns"
                    selectedItemsLabel="{0} cols. sélectionnée(s)"
                    class="mb-6"
                />
            </template>

            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" field="name" :sortable="true" #body="slotProps" v-if="selectedColumns.map( col => col.id).includes('name')">
                {{ slotProps.data.name }}
                <template v-if="slotProps.data.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle" v-tooltip="slotProps.data.note"></i>
                </template>
            </Column>

            <Column class="w-1/6" :header="isMobile ? 'Condit.' : 'Conditionnement'" field="conditioning" :sortable="true" #body="slotProps" v-if="selectedColumns.map( col => col.id).includes('conditioning')">
                {{ slotProps.data.conditioning_quantity }}
                {{ slotProps.data.conditioning.label }}
            </Column>

            <Column header="Prix" field="price" :sortable="true" #body="slotProps" v-if="selectedColumns.map( col => col.id).includes('price')">
               <span>{{ slotProps.data.price.toFixed(2) }} €</span>
            </Column>

            <Column header="Utilisation" field="usage" :sortable="true" v-if="selectedColumns.map( col => col.id).includes('usage')" />

            <Column header="Tags" field="tags" :sortable="false" #body="slotProps" v-if="selectedColumns.map( col => col.id).includes('tags')" >
                <template v-for="tag in slotProps.data.tags.slice(0, 2)">
                    <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                </template>
                <template v-if="slotProps.data.tags.length > 2">
                    (+{{ slotProps.data.tags.length - 2 }})
                </template>
            </Column>

            <Column field="max" :sortable="true" v-if="selectedColumns.map( col => col.id).includes('max')">
                <template #header>
                    <i class="text-blue-500 pi pi-info-circle mx-2" v-tooltip="'Conseillée - Limite - Par semaine '" />
                    Max.
                </template>
                <template #body="slotProps">
                    <template v-if="slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)">
                        {{ slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_informative }}
                        -
                        {{ slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_blocking }}
                        -
                        {{ slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.consumption_per_week }}
                    </template>
                    <p v-else>
                        Pas de quantité conseillée ou limite
                    </p>
                </template>
            </Column>

            <Column header="Q. commandée" #body="slotProps">
                <div class="flex flex-col items-center gap-2">
                    <InputNumber
                        placeholder="Quantité à commander"
                        v-model="slotProps.data.quantity"
                        @input="handleAddQuantity(slotProps.data, $event)"
                        :min="0"
                        :max="slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_blocking || Number.POSITIVE_INFINITY"
                    />
                    <small v-if="slotProps.data.quantity > slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_blocking" class="p-error">Attention la quantitée maximum est dépasée</small>
                    <small v-if="slotProps.data.quantity > slotProps.data.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_informative" class="text-orange-500 text-[10px]">Attention la quantitée conseillée est dépasée</small>
                </div>
            </Column>
        </DataTable>

        <div class="mb-6">
            <h2 class="mb-6" v-if="order?.items">Total :
                {{ order?.items.reduce((sum:number, item:any) => sum + Number(item.quantity ? item.quantity : 0), 0) }}
                ({{ order?.items.reduce((sum:number, item:any) => sum + (Number(item.product.price) * Number(item.quantity ? item.quantity : 0)), 0).toFixed(2) }} €)
            </h2>
            <Button @click="handleSubmitProducts">Voir mon panier</Button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref, watch} from "vue";
    import {useConfigsStore, useOrdersStore, useRestaurantsStore} from "../../../../store";
    import {storeToRefs} from "pinia";

    import InputText from "primevue/inputtext"
    import InputNumber from "primevue/inputnumber"
    import IconField from "primevue/iconfield"
    import InputIcon from "primevue/inputicon"
    import Tag from "primevue/tag"
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import MultiSelect from "primevue/multiselect";
    import {useRouter} from "vue-router";
    import useDebounce from "../../../../composables/debounce";
    import {RestaurantType} from "../../../../types/restaurant";
    import {ProductType} from "../../../../types/product";
    import Breadcrumbs from "../../../../components/partials/backoffice/breadcrumbs.vue";

    const breadCrumbsFirstItem = computed( () => { return { label: 'Commandes' , route: {name: 'orders' } } } );

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: order?.value?.restaurant?.name})

        items.push({label: order?.value?.supplier?.name})

        items.push({label: 'Choix produits'})

        return items;
    });

    const router = useRouter();

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const order = ref(ordersRefs.single);

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const isFetchProductsPending = restaurantsRefs.isFetchProductsPending;
    const products = restaurantsRefs.products;

    const configsStore = useConfigsStore();
    const isMobile = ref(configsStore.isMobile());

    const columnsOptions = [
        {name: 'Prix', id: 'price'},
        {name: 'Nom', id: 'name'},
        {name: 'Conditionnement', id: 'conditioning'},
        {name: 'Utilisation(s)', id: 'usage'},
        {name: 'Tags', id: 'tags'},
        {name: 'Max.', id: 'max'}
    ]

    const selectedColumns = ref(
        isMobile.value ?
        [
            {name: 'Prix', id: 'price'},
            {name: 'Nom', id: 'name'},
            {name: 'Conditionnement', id: 'conditioning'},
            {name: 'Max.', id: 'max'}
        ]:
        [
            {name: 'Prix', id: 'price'},
            {name: 'Nom', id: 'name'},
            {name: 'Conditionnement', id: 'conditioning'},
            {name: 'Utilisation(s)', id: 'usage'},
            {name: 'Tags', id: 'tags'},
            {name: 'Max.', id: 'max'}
        ]
    );

    onBeforeMount(async () => {
        if(!order?.value?.restaurant_id || !order?.value?.supplier_id) {
            await router.push({name: 'orders'})
        }else {
            await restaurantsStore.getProductsBySupplier({
                restaurant_id: order?.value?.restaurant_id,
                supplier_id: order?.value?.supplier_id,
            });

            if(order?.value?.items) {
                products.value = products.value.map( (product:any) => {
                    let oldQuantity = order.value.items.find( (item:any) => Number(item.product_id) === Number(product.id) )?.quantity;

                    return {
                        ...product,
                        quantity: oldQuantity
                    }
                })
            }else{
                order.value.items = [];
            }
        }
    });

    const handleGlobalFilterChange = async (value:string) => {
        await restaurantsStore.getProductsBySupplier({
            restaurant_id: order?.value?.restaurant_id,
            supplier_id: order?.value?.supplier_id,
            search: {search: value}
        });

        if(order?.value?.items) {
            products.value = products.value.map( (product:any) => {
                let oldQuantity = order.value.items.find( (item:any) => Number(item.product_id) === Number(product.id) )?.quantity;

                return {
                    ...product,
                    quantity: oldQuantity
                }
            })
        }
    }

    const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
        handleGlobalFilterChange(event);
    }, 1000);

    const handleSortChange = (event:any) => {

    }

    const handleSubmitProducts = (event:any) => {
        if(order.value.items.length > 0) {
            router.push({ name: 'order-create-validate' })
        }else {
            configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il n\'y a pas de produit dans votre commande'})
        }
    }

    const handleAddQuantity = (product:any, event:any) => {
        if(order?.value?.items && order.value.items.find( (item:any) => Number(item.product_id) === Number(product.id) )) {
            order.value.items.find( (item:any) => Number(item.product_id) === Number(product.id) ).quantity = event.value;
        }else{
            order.value.items.push({
                product_id: Number(product.id),
                product: {...product},
                quantity: event.value
            })
        }
    }
</script>
