import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/restaurants/List.vue"
import Save from "../views/backoffice/restaurants/Save.vue";
import Inventory from "../views/backoffice/restaurants/Inventory.vue";

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/restaurants',
        children: [
            {
                path: '',
                name: 'restaurants',
                component: List,
            },
            {
                path: '/admin/restaurants/:id?/save',
                name: 'restaurant-save',
                component: Save,
                meta: { authorize: [1] }
            },
            {
                path: '/admin/restaurants/:id/inventory',
                name: 'restaurant-inventory',
                component: Inventory,
            }
        ]
    }
]

export default module;
