import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {TagType} from "../types/tag";
import {BaseStoreType} from "../types/base-store";

export const useTagsStore = defineStore('tags', () => {
    const resourceName = 'tags';
    const baseUrl = '/api/tags';

    const baseStore:BaseStoreType = new BaseStore<TagType>({
        resourceName,
        baseUrl,
        useStore: useTagsStore()
    });

    return {
        ...baseStore,
    };
});
