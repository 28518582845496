<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between items-end">
            <div>
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Réceptionner la commande {{ order.reference }}
                </h1>
                <p><strong>Fournisseur</strong>: {{ order.supplier_restaurant.name }}</p>
                <p><strong>Restaurant</strong>: {{ order.restaurant.name }}</p>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="form?.items"
            :loading="isFetchSinglePending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="order?.items?.data.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
            class="mb-6"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" field="name" :sortable="true" #body="slotProps">
                {{slotProps.data.product.name }}
                <template v-if="slotProps.data.product.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle"
                       v-tooltip="slotProps.data.product.note"
                    ></i>
                </template>
            </Column>

            <Column header="Prix" field="price" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.price }} €
            </Column>

            <Column header="Conditionnement" field="conditioning" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.conditioning_quantity }}
                {{ slotProps.data.product.conditioning.label }}
            </Column>

            <Column header="Q. Commandée" field="quantity" :sortable="true" />

            <Column header="Q. Reçue" #body="slotProps">
                <InputNumber
                    placeholder="Quantité à commander"
                    v-model="form.items.find( (item:any) => item.id === slotProps.data.id).received_quantity"
                    :minFractionDigits="1"
                    :maxFractionDigits="3"
                    :min="0"
                />
            </Column>
        </DataTable>

        <div class="mb-6 flex justify-between">
            <div>
                <p>
                    <strong>TOTAL COMMANDE:</strong>
                    {{ order.items.data.reduce((sum:number, item:any) => sum + item.quantity, 0) }}
                    ({{ order.items.data.reduce((sum:number, item:any) => sum + (item.price*item.quantity), 0).toFixed(2) }} €)
                </p>
                <p>
                    <strong>TOTAL REÇU:</strong>
                    <span class="ml-1" :class="order.items.data.reduce((sum:number, item:any) => sum + item.quantity, 0) !== order.items.data.reduce((sum:number, item:any) => sum + Number(item.received_quantity), 0) ? 'text-red-500' : ''">
                     {{ order.items.data.reduce((sum:number, item:any) => sum + Number(item.received_quantity), 0) }}
                    ({{ order.items.data.reduce((sum:number, item:any) => sum + (Number(item.price) * Number(item.received_quantity)), 0).toFixed(2) }} €)
                    </span>
                </p>
            </div>
        </div>

        <div class="flex gap-2 flex-col mb-6">
            <label for="received_note" class="text-surface-400 tracking-widest">Commentaires</label>
            <Editor
                id="received_note"
                name="received_note"
                class="w-full"
                v-model="form.received_note"
                ref="editorRef"
                :invalid="form.invalid('received_note')"
                @change="form.validate('received_note')"
            />
            <small v-if="form.invalid('received_note')" class="text-red-500"> {{ form.errors.received_note }} </small>
        </div>

        <div class="flex flex-col gap-4 justify-between mb-6">
            <div class="flex-1">
                <FilePckr
                    id="uploads"
                    multiple
                    v-model="form.uploads"
                    @end-load="handleLoadReceipt"
                />
            </div>
            <div>
                <Button @click.prevent="handleSubmit">Valider</Button>
            </div>
        </div>

        <div class="flex gap-4 flex-col">
            <Logs :logs="logs" />
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref, watch} from "vue";
import {useConfigsStore, useOrdersStore} from "../../../store";
import {storeToRefs} from "pinia";

import Editor from "primevue/editor";
import Button from "primevue/button"
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import FilePckr from "../../../components/file-pckr.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";

import Logs from "../../../components/partials/backoffice/logs.vue"
import Search from "../../../components/partials/backoffice/search.vue"
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import InputNumber from "primevue/inputnumber";

const breadCrumbsFirstItem = computed( () => { return { label: 'Dépannes' , route: {name: 'orders' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: order?.value?.restaurant?.name})

    items.push({label: order?.value?.supplier_restaurant?.name})

    items.push({label: 'Réceptionner'})

    return items;
});

const router = useRouter();
const route = useRoute()

const ordersStore = useOrdersStore();
const ordersRefs = storeToRefs(ordersStore);
const isFetchSinglePending = ordersRefs.isFetchSinglePending;
const order = ordersRefs.single;
const logs = ordersRefs.logs;

const pagination = ref({
    per_page: 50,
    current_page: 1
});

const handleGlobalSearch = (value:string) => {
    ordersStore.getItems({search: {search: value}})
}

onBeforeMount(async () => {
    await ordersStore.getItem({id: route.params.id, includes: ['items', 'items.product', 'items.product.conditioning']})

    order.value.items.data = order.value.items.data.map( (item:any) => {
            return {
                ...item,
                received_quantity: 0
            }
    });

    await ordersStore.getLogs({id: Number(route.params.id)});

    form.setData({
        items : order?.value?.items.data
    })
});

const handlePageChange = (event:any) => {

}

const handleSortChange = (event:any) => {

}

const form = useForm('post',`/api/orders/${route.params.id}/receipt`,
    {
        delivery_number: null,
        received_note: null,
        uploads: [],
        items: []
    }
);

const isFilepondPending = ref(false);

const handleLoadReceipt = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = true;
}

const configsStore = useConfigsStore();

const handleSubmit = () => {
    ordersRefs.isSavePending.value = true;

    let untouchedData = form.data();

    form
        .submit()
        .then(() => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La dépanne a bien été réceptionnée'})

            return router.push({name: 'conveniences'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            ordersRefs.isSavePending.value = false;
        })
}

const editorRef = ref()

watch(editorRef, (editor:any) => {
    if (!editor) return
    // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
    editor.renderValue = function renderValue(value:any) {
        if (this.quill) {
            if (value) {
                const delta = this.quill.clipboard.convert({ html: value })
                this.quill.setContents(delta, 'silent')
            } else {
                this.quill.setText('')
            }
        }
    }.bind(editor) // Bind needed for production build
})
</script>
