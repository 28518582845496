<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex items-center gap-2">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Fournisseurs
                </h1>

                <Button @click="handleCreateOrEditSupplier" v-if="authStore.isAdmin()">+ Ajouter un fournisseur</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="suppliers"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <template #header>
                <div class="flex mb-4">
                    <Button
                        link
                        @click.prevent="handleChangeStatus(SUPPLIER_STATUS.values.ACTIVE.description)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === SUPPLIER_STATUS.values.ACTIVE.description ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Actif
                    </Button>
                    <Button
                        link
                        @click.prevent="handleChangeStatus(SUPPLIER_STATUS.values.INACTIVE.description)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === SUPPLIER_STATUS.values.INACTIVE.description ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Inactif
                    </Button>
                </div>
            </template>

            <Column header="Nom" field="name" :sortable="true"></Column>

            <Column header="Téléphone" field="contact_phone" :sortable="true" />

            <Column header="Email" field="contact_email" :sortable="true"></Column>

            <Column header="Action(s)" class="w-1/4" #body="slotProps">
                <div class="flex items-center gap-2">
                    <Button
                        link
                        label="Voir"
                        @click.prevent="handleShowSupplier(slotProps.data)"
                        v-tooltip="'Voir'">
                        <i class="pi pi-eye"></i>
                    </Button>
                    <Button
                        link
                        label="Planning"
                        @click.prevent="handleSupplierSchedule(slotProps.data)"
                        v-tooltip="'Planning'">
                        <i class="pi pi-calendar"></i>
                    </Button>
                    <template v-if="authStore.isAdmin()">
                        <Button
                            link
                            label="Modifier"
                            @click.prevent="handleCreateOrEditSupplier(slotProps.data)"
                            v-tooltip="'Éditer'">
                            <i class="pi pi-pencil"></i>
                        </Button>
                        <Button
                            link
                            label="Supprimer"
                            @click.prevent="handleDeleteSupplier(slotProps.data)"
                            v-tooltip="'Supprimer'">
                            <i class="pi pi-trash"></i>
                        </Button>
                    </template>
                </div>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";

    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";

    import { useRouter} from "vue-router";
    import {useConfirm} from "primevue/useconfirm";

    import {storeToRefs} from "pinia";
    import {useAuthStore, useSuppliersStore} from "../../../store";

    import {SupplierType} from "../../../types/supplier";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";

    import SUPPLIER_STATUS from "../../../enums/supplier-status"
    import useDatatableActions from "../../../composables/datatable-actions";

    const router = useRouter()
    const confirm = useConfirm()

    const authStore = useAuthStore()

    const suppliersStore = useSuppliersStore()
    const suppliersRefs = storeToRefs(suppliersStore)
    const pagination = suppliersRefs.pagination
    const isFetchListPending = suppliersRefs.isFetchListPending
    const suppliers = suppliersRefs.list

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

    const breadCrumbsItems = computed(() => [{label: 'Fournisseurs'}]);

    const queryParams = ref({
        search: {
            search: null,
            status: SUPPLIER_STATUS.values.ACTIVE.description
        },
        includes: [],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch, handleChangeStatus} = useDatatableActions(pagination, queryParams, suppliersStore.getItems)

    onBeforeMount(() => {
        suppliersStore.getItems(queryParams.value);
    })

    const handleDeleteSupplier = (supplier:SupplierType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => { suppliersStore.deleteItem(supplier.id) }
        })
    }

    const handleCreateOrEditSupplier = (supplier:SupplierType) => {
        suppliersRefs.single.value = null
        router.push({name: 'supplier-save',  params: (supplier ? {id: supplier.id} : {}) });
    }

    const handleShowSupplier = (supplier:SupplierType) => {
        router.push({name: 'supplier-show',  params: {id: supplier.id} });
    }

    const handleSupplierSchedule = (supplier:SupplierType) => {
        router.push({name: 'supplier-schedule',  params: {id: supplier.id} });
    }
</script>
