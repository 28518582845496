export default {
    values: Object.freeze ({
        DISABLED: Symbol(0),
        ENABLED: Symbol(1),
    }),
    labels: {
        0: 'Inactif',
        1: 'Actif',
    }
}

