<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ response?.id ? 'Modifier un type de réponses' : 'Ajouter un type de réponses' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="title" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="title"
                    type="text"
                    label="label"
                    v-model="form.title[locale]"
                    :invalid="form.invalid('title')"
                    @change="form.validate('title')"
                />
                <small v-if="form.invalid('title')" class="text-red-500"> {{ form.errors.title }} </small>
            </div>

            <template v-if="response.id">
                <label for="name" class="text-dark-slate-600 uppercase tracking-widest flex items-center justify-between">
                    Réponses
                    <Button size="small" @click="handleAddOrEditItem">+ Ajouter une réponse</Button>
                </label>
                <DataTable :value="response.items.data" tableStyle="min-width: 50rem">
                    <Column field="title" header="Titre"></Column>
                    <Column field="type" header="Type" #body="slotProps">
                        {{ RESPONSE_TYPES.labels[slotProps.data.type] }}
                    </Column>
                    <Column key="actions" header="Action(s)">
                        <template #body="slotProps">
                            <div class="flex items-center gap-2">
                                <Button
                                    link
                                    label="Modifier"
                                    @click.prevent="handleAddOrEditItem(slotProps.data)"
                                    v-tooltip="{value: 'Modifier', class: 'tooltip-normal'}">
                                    <i class="pi pi-pencil"></i>
                                </Button>
                                <Button
                                    link
                                    label="Supprimer"
                                    @click.prevent="handleDeleteItem(slotProps.data)"
                                    v-tooltip="{value: 'Supprimer', class: 'tooltip-normal'}">
                                    <i class="pi pi-trash"></i>
                                </Button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </template>

            <div>
                <Button @click="handleSubmit">
                    {{ response?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";

    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useResponsesStore, useConfigsStore} from "../../../store";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import AddResponseItemToResponse from "../../../components/dialogs/AddResponseItemToResponse.vue";
    import {useDialog} from "primevue/usedialog";
    import RESPONSE_TYPES from "../../../enums/response-types"
import {useConfirm} from "primevue/useconfirm";

    const router = useRouter()
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Types de réponse' , route: {name: 'responses'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (response?.value?.id) {
            items.push({label: response?.value?.translatable?.title ? response?.value?.translatable?.title[locale.value] : 'N/A'})
        }

        items.push({label: response?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore);

    const responsesStore = useResponsesStore();
    const responsesRefs = storeToRefs(responsesStore);
    const response = responsesRefs.single;
    const isFetchPending = responsesRefs.isFetchSinglePending;

    onBeforeMount(async () => {
        if (route.params.id) {
            await responsesStore.getItem({id: String(route.params.id), includes: ['items']})
        } else {
            response.value = {
                title: {fr: ''}
            }
        }

        form.setData({
            ...response?.value,
            title: response?.value?.translatable?.title || {fr: ''},
        })
    })

    const form = useForm(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/surveys/responses/' + route.params.id
            : '/api/surveys/responses',
        {
            title: {fr: ''},
        }
    );

    const handleSubmit = () => {
        responsesRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .submit()
            .then(() => {
                if (route.params.form_id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La raison a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La raison a bien été créé'})
                }

                return router.push({name: 'responses'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                responsesRefs.isSavePending.value = false;
            })
    }

    const dialog = useDialog()

    const handleAddOrEditItem = (item?:any) => {
        dialog.open(AddResponseItemToResponse, {
            props: {
                header: 'Ajouter une réponse à retourner',
                modal: true
            },
            data: {
                response: response,
                item: item
            },
            onClose: () => {
                responsesStore.getItem({id: String(route.params.id), includes: ['items']})
            }
        })
    }

    const confirm = useConfirm();

    const handleDeleteItem = (item:any) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {responsesStore.deleteResponseItem(response.value.id, item.id)}
        })
    }
</script>
