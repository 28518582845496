<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ storageUnit?.id ? 'Modifier un conditionnement' : 'Ajouter un conditionnement' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="unit" class="text-dark-slate-600 uppercase tracking-widest">Unité</label>
                <InputText
                    id="unit"
                    name="unit"
                    v-model="form.unit"
                    :invalid="form.invalid('unit')"
                    @change="form.validate('unit')"
                />
                <small v-if="form.invalid('unit')" class="text-red-500"> {{ form.errors.unit }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="supplier_id" class="text-dark-slate-600 uppercase tracking-widest">Fournisseur</label>
                <Select
                    v-model="form.supplier_id"
                    :options="suppliersOptions"
                    optionLabel="name"
                    placeholder="Choisissez un fournisseur"
                    :invalid="form.invalid('supplier_id')"
                />
                <small v-if="form.invalid('supplier_id')" class="text-red-500"> {{ form.errors.supplier_id }} </small>
            </div>

            <div>
                <Button @click="handleSubmit">{{ storageUnit?.id ? 'Modifier' : 'Ajouter' }}</Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";

import InputText from "primevue/inputtext";
import Button from "primevue/button";

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";

import {storeToRefs} from "pinia";
import {useStorageUnitsStore, useConfigsStore, useSuppliersStore} from "../../../store";
import ProgressSpinner from "primevue/progressspinner";
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import Select from "primevue/select";
import {SupplierType} from "../../../types/supplier";

const route = useRoute()
const router = useRouter();

const breadCrumbsFirstItem = computed( () => {
    return { label: 'Packaging' , route: {name: 'storageUnits'} }
})

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    if (storageUnit?.value?.id) {
        items.push({label: storageUnit?.value?.unit || 'N/A'})
    }

    items.push({label: storageUnit?.value?.id ? 'Modifier' : 'Ajouter'})

    return items;
});

const configsStore = useConfigsStore();
const configsRefs = storeToRefs(configsStore);
const locale = configsRefs.locale;

const storageUnitsStore = useStorageUnitsStore();
const storageUnitsRefs = storeToRefs(storageUnitsStore);
const storageUnit = storageUnitsRefs.single;
const isFetchPending = storageUnitsRefs.isFetchSinglePending;

const suppliersStore = useSuppliersStore();
const suppliersRefs = storeToRefs(suppliersStore);
const suppliersOptions = ref<{ name: string, id: number }[]>([]);

onBeforeMount(async () => {
    if (route.params.id) {
        await storageUnitsStore.getItem({id: String(route.params.id)})
    } else {
        storageUnit.value = {
            unit: null,
            supplier_id: null
        }
    }

    await suppliersStore.getItems({list: true});
    suppliersOptions.value = suppliersRefs.list.value.map((supplier: SupplierType) => {
        return {name: supplier?.name, id: supplier.id}
    });

    form.setData({
        ...storageUnit?.value,
        supplier_id: suppliersOptions.value.find((supplier:any) => Number(storageUnit?.value?.supplier_id) === Number(supplier.id)) || null,
    })
})

const form = useForm(
    route.params.id ? 'patch' : 'post',
    route.params.id
        ? '/api/storage_units/' + route.params.id
        : '/api/storage_units',
    {
        unit: null,
        supplier_id: null
    }
);

const handleSubmit = () => {
    storageUnitsRefs.isSavePending.value = true;

    let untouchedData = form.data();

    form
        .setData({
            supplier_id: untouchedData?.supplier_id?.id,
        })
        .submit()
        .then(() => {
            if (route.params.form_id) {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le conditionnement a bien été modifié'})
            } else {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le conditionnement a bien été créé'})
            }

            return router.push({name: 'storageUnits'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            storageUnitsRefs.isSavePending.value = false;
        })
}
</script>
