<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <h1 class="mr-6 text-5xl uppercase font-title">
                {{ audit?.translatable?.title[locale] }}
            </h1>
            <div class="flex items-center gap-2">
                <label for="">Copie fantôme</label>
                <ToggleSwitch />
            </div>
        </div>
        <div class="flex gap-12">
            <div class="flex flex-col w-[300px]">
                <div>
                    <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                        <div class="flex gap-2 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(-1)">
                            <h3 class="flex items-center gap-1">
                                <i class="pi" :class="openedSection === -1 ? 'pi-angle-up' : 'pi-angle-down'" />
                                Introduction
                            </h3>
                        </div>
                    </div>
                </div>
                <div v-for="section in audit?.sections?.data">
                    <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                        <div class="flex gap-2 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(section.id)">
                            <h3 class="flex items-center gap-1">
                                <i class="pi" :class="openedSection === section.id ? 'pi-angle-up' : 'pi-angle-down'" />
                                {{ section.title }}
                            </h3>
                        </div>
                    </div>
                    <Transition name="fadeHeight">
                        <div v-show="openedSection === section.id" class="p-2 rounded-b-md border border-surface-100 transition-all">
                            <div class="bg-surface-50 p-2 rounded-md flex items-center justify-between mb-1" v-for="item in section?.items?.data">
                                <div class="flex gap-2 items-center">
                                    <h3>{{ item.extra_attributes.question }}</h3>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div>
                    <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                        <div class="flex gap-2 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(-2)">
                            <h3 class="flex items-center gap-1">
                                <i class="pi" :class="openedSection === -2 ? 'pi-angle-up' : 'pi-angle-down'" />
                                Conclusion
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex flex-col gap-4" v-show="openedSection === -1">
                    <div class="flex flex-col gap-2">
                        <label for="restaurant_id" class="text-dark-slate-600 uppercase tracking-widest">Restaurant</label>
                        <Select
                            v-model="form.restaurant_id"
                            :options="restaurants"
                            optionLabel="name"
                            empty-message="Aucun restaurants"
                            placeholder="Sélectionnez un restaurant"
                            :invalid="form.invalid('restaurant_id')"
                            @select="form.validate('restaurant_id')"
                        />
                        <small v-if="form.invalid('restaurant_id')" class="text-red-500"> {{ form.errors.restaurant_id }} </small>
                    </div>
                    <div class="flex gap-4">
                        <div class="flex flex-col gap-2">
                            <label for="date_at" class="text-dark-slate-600 uppercase tracking-widest">Date de Début</label>
                            <DatePicker
                                dateFormat="dd/mm/yy"
                                showTime
                                hourFormat="24"
                                fluid
                                v-model="form.date_at"
                                :invalid="form.invalid('date_at')"
                                @select="form.validate('date_at')"
                            />
                            <small v-if="form.invalid('date_at')" class="text-red-500"> {{ form.errors.date_at }} </small>
                        </div>
                        <div class="flex flex-col gap-2">
                            <label for="type" class="text-dark-slate-600 uppercase tracking-widest">Type de contrôle</label>
                            <Select
                                v-model="form.type"
                                :options="typeOptions"
                                optionLabel="name"
                                placeholder="Sélectionnez un type"
                                :invalid="form.invalid('type')"
                                @select="form.validate('type')"
                            />
                            <small v-if="form.invalid('type')" class="text-red-500"> {{ form.errors.type }} </small>
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="note_expectations" class="text-dark-slate-600 uppercase tracking-widest">Attentes</label>
                        <Textarea
                            v-model="form.note_expectations"
                            :invalid="form.invalid('note_expectations')"
                            @select="form.validate('note_expectations')"
                        />
                        <small v-if="form.invalid('note_expectations')" class="text-red-500"> {{ form.errors.note_expectations }} </small>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="note_preliminary" class="text-dark-slate-600 uppercase tracking-widest">Remarques préalables</label>
                        <Textarea
                            v-model="form.note_preliminary"
                            :invalid="form.invalid('note_preliminary')"
                            @select="form.validate('note_preliminary')"
                        />
                        <small v-if="form.invalid('note_preliminary')" class="text-red-500"> {{ form.errors.note_preliminary }} </small>
                    </div>

                    <Button @click="handleSaveAudit">Modifier l'enquête</Button>
                </div>

                <div v-if="audit?.id" v-for="section in audit?.sections?.data">
                    <div v-show="openedSection === section.id" class="flex flex-col gap-4">
                        <div v-for="item in section?.items?.data">
                            <div v-show="openedItem === item.id">
                                <h3 class="font-bold">{{ item.extra_attributes.question }}</h3>
                                <p> {{ item.note }} </p>
{{ item }}
                                <div class="flex flex-col gap-2 mb-4">
                                    <Select
                                        v-model="auditItemForm.survey_response_item_id"
                                        :options="item?.response?.items?.data"
                                        optionLabel="title"
                                        empty-message="Aucune réponse"
                                        placeholder="Sélectionnez une réponse"
                                        :invalid="auditItemForm.invalid('survey_response_item_id')"
                                        @select="auditItemForm.validate('survey_response_item_id')"
                                    />
                                    <small v-if="auditItemForm.invalid('survey_response_item_id')" class="text-red-500"> {{ auditItemForm.errors.survey_response_item_id }} </small>
                                </div>

                                <div class="flex flex-col gap-2 mb-4">
                                    <Textarea
                                        v-model="auditItemForm.note"
                                        :invalid="auditItemForm.invalid('note')"
                                        @select="auditItemForm.validate('note')"
                                    />
                                    <small v-if="auditItemForm.invalid('note')" class="text-red-500"> {{ auditItemForm.errors.note }} </small>
                                </div>

                                <div class="flex flex-col gap-2 mb-6">
                                    <FilePckr
                                        id="illustration"
                                        :accepted-file-types="config?.media['mime-types'].image.join(',')"
                                        v-model="auditItemForm.illustration"
                                        @start-load="handleStartLoadFiles"
                                        @end-load="handleEndLoadFiles"
                                    />
                                    <small v-if="auditItemForm.invalid('illustration')" class="text-red-500"> {{ auditItemForm.errors.illustration }} </small>
                                </div>

                                <Button @click="handleSaveAuditItem(item)">Question suivante</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-4" v-show="openedSection === -2">
                    <div class="flex flex-col gap-2">
                        <label for="end_at" class="text-dark-slate-600 uppercase tracking-widest">Date de fin</label>
                        <DatePicker
                            dateFormat="dd/mm/yy"
                            showTime
                            hourFormat="24"
                            fluid
                            v-model="form.end_at"
                            :invalid="form.invalid('end_at')"
                            @select="form.validate('end_at')"
                        />
                        <small v-if="form.invalid('end_at')" class="text-red-500"> {{ form.errors.end_at }} </small>
                    </div>

                    <div class="flex flex-col gap-2">
                        <label for="note_final" class="text-dark-slate-600 uppercase tracking-widest">Attentes</label>
                        <Textarea
                            v-model="form.note_final"
                            :invalid="form.invalid('note_final')"
                            @select="form.validate('note_final')"
                        />
                        <small v-if="form.invalid('note_final')" class="text-red-500"> {{ form.errors.note_final }} </small>
                    </div>

                    <Button @click="handleSaveAudit">Sauvegarder l'enquête</Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref, watch} from "vue";

import ProgressSpinner from "primevue/progressspinner";
import ToggleSwitch from "primevue/toggleswitch";
import Select from "primevue/select";
import Textarea from "primevue/textarea";
import DatePicker from "primevue/datepicker";
import Button from "primevue/button"

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";

import {storeToRefs} from "pinia";
import {useConfigsStore, useRestaurantsStore} from "../../../store";
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import {useAuditsStore} from "../../../store/audit";
import AUDIT_TYPES from "../../../enums/audit-types";
import FilePckr from "../../../components/file-pckr.vue";
import {RestaurantType} from "../../../types/restaurant";

const route = useRoute()

const breadCrumbsFirstItem = computed( () => {
    return { label: audit?.value?.translatable?.title[locale.value] , route: {name: 'survey-audits', params: {id: route.params.survey_id}} }
})

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: 'Contrôle'})

    items.push({label: 'Modifier'})

    return items;
});

const configsStore = useConfigsStore();
const {locale, config} = storeToRefs(configsStore)

const auditsStore = useAuditsStore();
const { single: audit, isFetchSinglePending, isSavePending } = storeToRefs(auditsStore);

const restaurantsStore = useRestaurantsStore();
const { list: restaurants } = storeToRefs(restaurantsStore);

const typeOptions = Object.entries(AUDIT_TYPES.labels).map( type => {
    return {id: type[0], name: type[1]}
});

const form = useForm(
    'patch',
    '/api/surveys/audits/' + route.params.id,
    {
        restaurant_id: null,
        date_at: null,
        type: null,
        end_at: null,
        note_expectations: null,
        note_preliminary: null,
        note_final: null,
        survey_id: null,
        include: 'sections,sections.items,sections.items.response,items,items.response,items.response.items'
    }
);

const auditItemForm = useForm(
    'post',
    () => '/api/surveys/audits/' + audit?.value?.id + '/item',
    {
        survey_response_item_id: null,
        note: '',
        illustration: '',
        survey_question_id: null
    }
);

onBeforeMount(async () => {
    await auditsStore.getItem({id: String(route.params.id), includes: ['sections', 'sections.items', 'sections.items.response', 'items', 'items.response', 'items.response.items']})
    await restaurantsStore.getItems();

    form.setData({
        ...audit.value,
        date_at: new Date(audit.value.date_at),
        type: typeOptions.find( (type:any) => Number(type.id) === Number(audit.value.type) ),
        restaurant_id: restaurants.value.find( (restaurant:RestaurantType) => restaurant.id === audit.value.restaurant_id),
    })
})

const openedSection = ref(-1);
const openedItem = ref(0);

const handleOpenSection = (section_id:number) => {
    openedSection.value = openedSection.value === section_id ? 0 : section_id;
    openedItem.value = audit?.value?.sections?.data?.find( (section:any) => Number(section.id) === section_id)?.items?.data[0].id
}

const isFilepondPending = ref(false);

const handleStartLoadFiles = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)[0]})
    isFilepondPending.value = true;
}

const handleEndLoadFiles = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)[0]})
    isFilepondPending.value = false;
}

const handleSaveAudit = () => {
    isSavePending.value = true;

    let untouchedData = form.data();

    console.warn(audit.value.survey_id);

    form
        .setData({
            date_at: untouchedData.date_at ? new Date(untouchedData.date_at).toISOString().replace('T', ' ').split('.')[0] : null,
            restaurant_id: untouchedData?.restaurant_id?.id,
            type: untouchedData?.type?.id,
        })
        .submit()
        .then((response:any) => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'L\'audit a bien été modifiée'})
            openedSection.value = audit?.value?.sections?.data[0]?.id;
            openedItem.value = audit?.value?.sections?.data[0]?.items?.data[0]?.id;

            audit.value = response.data.data;
        })
        .catch((error:any) => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            isSavePending.value = true;
        })
}

const handleSaveAuditItem = (item:any) => {
    isSavePending.value = true;

    let untouchedData = auditItemForm.data();

    auditItemForm
        .setData({
            survey_response_item_id: untouchedData?.survey_response_item_id?.id,
            survey_question_id: item.id
        })
        .submit()
        .then((response:any) => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'L\'audit a bien été commencé'})

            try {
                if(audit.value.items) {
                    audit.value.items.push( response.data.data )
                }else {
                    audit.value.items = [response.data.data]
                }

                const questionIndex = audit?.value?.sections?.data[0]?.questions?.data.findIndex( (item:any) => Number(item.id) === Number(item.id) );
                const sectionIndex = audit?.value?.sections?.data.findIndex( (section:any) => Number(section.id) === Number(item.survey_section_id) );

                if(questionIndex !== -1 && questionIndex+1 < audit?.value?.sections?.data[0]?.questions?.data.length) {
                    openedItem.value = audit?.value?.sections?.data[0]?.questions?.data[questionIndex+1]?.id;
                }else if(sectionIndex !== -1 && sectionIndex+1 < audit?.value?.sections?.data.length) {
                    openedSection.value = audit?.value?.sections?.data[sectionIndex+1]?.id;
                    openedItem.value = audit?.value?.sections?.data[sectionIndex+1]?.questions?.data[0]?.id;
                }else{
                    openedSection.value = -2;
                }

                auditItemForm.setData({
                    survey_response_item_id: null,
                    note: '',
                    illustration: '',
                    survey_question_id: null
                })
            }catch( e ) {
                console.warn(e);
            }
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            auditItemForm.setData({...untouchedData});
        })
        .finally(() => {
            isSavePending.value = true;
        })
}

watch( openedItem, () => {
    let item = audit.value.items.data.find( (item:any) => item.id === openedItem.value);

    console.warn(item.response);

    auditItemForm.setData({
        ...item,
        survey_response_item_id: ''
    })
})

const editorRef = ref()

watch(editorRef, (editor) => {
    if (!editor) return
    // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
    editor.renderValue = function renderValue(value) {
        if (this.quill) {
            if (value) {
                const delta = this.quill.clipboard.convert({ html: value })
                this.quill.setContents(delta, 'silent')
            } else {
                this.quill.setText('')
            }
        }
    }.bind(editor) // Bind needed for production build
})
</script>

<style>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.01, 1, 0.01);
    max-height: 250px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
    opacity: 0;
    max-height: 0;
}
</style>
