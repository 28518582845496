<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchRestaurantPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between items-end">
            <div>
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Inventaire
                </h1>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <div class="flex flex-col gap-4">
            <DataTable
                :value="products"
                :rows="1000"
                :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
                @sort="handleSortChange($event)"
                paginator showGridlines stripedRows removableSort
                currentPageReportTemplate="{currentPage}/{totalPages}"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                :pt="{
                    header: {class: 'text-surface-600 font-bold text-sm'},
                    mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                    loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
                }"
            >
                <Column header="Nom" field="name" :sortable="true" sort-field="name"></Column>

                <Column header="Fournisseur" field="supplier" :sortable="true" sort-field="supplier" #body="slotProps">
                    {{ slotProps.data.supplier.name }}
                </Column>

                <Column header="Prix" field="price" :sortable="true" sort-field="price" #body="slotProps">
                    {{ slotProps.data.inventory_price }} €
                </Column>

                <Column header="Tags" field="tags" :sortable="false" #body="slotProps">
                    <template v-for="tag in slotProps.data.tags.slice(0, 2)">
                        <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                    </template>
                    <template v-if="slotProps.data.tags.length > 2">
                        (+{{ slotProps.data.tags.length - 2 }})
                    </template>
                </Column>

                <Column header="Conditionnement" field="conditioning" :sortable="true" #body="slotProps">
                    <template v-if="slotProps.data.inventory_conditioning_quantity">
                        {{ slotProps.data.inventory_conditioning_quantity }}
                    </template>
                    <template v-else>
                        {{ slotProps.data.conditioning_quantity }}
                    </template>
                    {{ slotProps.data.conditioning.label }}
                </Column>

                <Column header="Stock" #body="slotProps">
                    <InputNumber :minFractionDigits="2" :maxFractionDigits="3" placeholder="Quantité en stock" v-model="slotProps.data.quantity"/>
                </Column>
            </DataTable>

            <div>
                <Button @click="handleSubmit" label="Valider" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";
    import {useConfigsStore, useInventoriesStore, useRestaurantsStore} from "../../../store";
    import {storeToRefs} from "pinia";

    import Button from "primevue/button"
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import ProgressSpinner from "primevue/progressspinner";
    import InputNumber from "primevue/inputnumber";
    import Tag from "primevue/tag";

    import {useRoute, useRouter} from "vue-router";
    import {ProductType} from "../../../types/product";
    import {useForm} from "laravel-precognition-vue";

    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Restaurants' , route: {name: 'restaurants'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (restaurant?.value?.id) {
            items.push({label: restaurant?.value?.name || 'N/A'})
        }

        items.push({label: 'Inventaire'})

        return items;
    });

    const route = useRoute();
    const router = useRouter()

    const configsStore = useConfigsStore();

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const isFetchRestaurantPending = restaurantsRefs.isFetchSinglePending;
    const restaurant = ref(restaurantsRefs.single);
    const products = restaurantsRefs.products;

    const inventoriesStore = useInventoriesStore();
    const inventoriesRefs = storeToRefs(inventoriesStore);

    let searchForce = {inventory_enabled: 1};

    onBeforeMount(async () => {
        await restaurantsStore.getItem({id: route.params.id})
        await restaurantsStore.getProducts({
            search: searchForce,
            restaurant_id: route.params.id,
            includes: ['conditioning', 'tags', 'supplier'],
            sort_by: 'supplier.name',
        });
    });

    const handleGlobalSearch = (value:string) => {

        let search = { search: { search: value } };

        restaurantsStore.getProducts(
            {
                search: {
                    ...search.search,
                    ...searchForce
                },
                restaurant_id: Number(route.params.id),
                includes: ['conditioning', 'tags', 'supplier']
            })
    }

    const handlePageChange = (event:any) => {

    }

    const handleSortChange = (event:any) => {

    }

    const form = useForm('post', '/api/inventories',
        {
            'restaurant_id': null,
            'items': [],
        }
    );

    const handleSubmit = () => {
        form
            .setData({
                restaurant_id: route.params.id,
                items: products.value.map( (product:ProductType) => {
                    return {
                        product_id: Number(product.id),
                        quantity: Number(product.quantity).toFixed(2)
                    }
                })
            })
            .submit()
            .then(() => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'l\'inventaire a bien été effectué'})

                return router.push({name: 'inventories'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }
            })
            .finally(() => {
                inventoriesRefs.isSavePending.value = false;
            })
    }
</script>
