<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between sm:flex-row flex-col">
            <h1 class="mr-6 text-5xl uppercase font-title">
                {{ survey?.translatable?.title[locale] }}
            </h1>
            <div class="flex items-center gap-2">
                <label for="">Copie fantôme</label>
                <ToggleSwitch />
            </div>
        </div>
        <div class="flex gap-12 sm:flex-row flex-col">
            <div class="flex flex-col w-[300px]">
                <div>
                    <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                        <div class="flex gap-2 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(-1)">
                            <h3 class="flex items-center gap-1">
                                <i class="pi" :class="openedSection === -1 ? 'pi-angle-up' : 'pi-angle-down'" />
                                Introduction
                            </h3>
                        </div>
                    </div>
                </div>
                <div v-for="section in survey?.sections?.data">
                    <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                        <div class="flex gap-2 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(section.id)">
                            <h3 class="flex items-center gap-1">
                                <i class="pi" :class="openedSection === section.id ? 'pi-angle-up' : 'pi-angle-down'" />
                                {{ section.translatable.title[locale] }}
                            </h3>
                        </div>
                    </div>
                    <Transition name="fadeHeight">
                        <div v-show="openedSection === section.id" class="p-2 rounded-b-md border border-surface-100 transition-all">
                            <div class="bg-surface-50 p-2 rounded-md flex items-center justify-between mb-1 cursor-pointer hover:opacity-75 transition-opacity" v-for="question in section?.questions?.data" @click="handleOpenQuestion(question)">
                                <div class="flex gap-2 items-center" >
                                    <h3>{{ question.translatable.title[locale] }}</h3>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div>
                    <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                        <div class="flex gap-2 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(-2)">
                            <h3 class="flex items-center gap-1">
                                <i class="pi" :class="openedSection === -2 ? 'pi-angle-up' : 'pi-angle-down'" />
                                Conclusion
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex flex-col gap-4" v-show="openedSection === -1">
                    <div class="flex flex-col gap-2">
                        <label for="restaurant_id" class="text-dark-slate-600 uppercase tracking-widest">Restaurant</label>
                        <Select
                            v-model="auditForm.restaurant_id"
                            :options="restaurants"
                            optionLabel="name"
                            empty-message="Aucun restaurants"
                            placeholder="Sélectionnez un restaurant"
                            :invalid="auditForm.invalid('restaurant_id')"
                            @select="auditForm.validate('restaurant_id')"
                        />
                        <small v-if="auditForm.invalid('restaurant_id')" class="text-red-500"> {{ auditForm.errors.restaurant_id }} </small>
                    </div>
                    <div class="flex gap-4 sm:flex-row flex-col">
                        <div class="flex flex-col gap-2">
                            <label for="date_at" class="text-dark-slate-600 uppercase tracking-widest">Date de Début</label>
                            <DatePicker
                                dateFormat="dd/mm/yy"
                                showTime
                                hourFormat="24"
                                v-model="auditForm.date_at"
                                :invalid="auditForm.invalid('date_at')"
                                @select="auditForm.validate('date_at')"
                            />
                            <small v-if="auditForm.invalid('date_at')" class="text-red-500"> {{ auditForm.errors.date_at }} </small>
                        </div>
                        <div class="flex flex-col gap-2">
                            <label for="type" class="text-dark-slate-600 uppercase tracking-widest">Type de contrôle</label>
                            <Select
                                v-model="auditForm.type"
                                :options="typeOptions"
                                optionLabel="name"
                                placeholder="Sélectionnez un type"
                                :invalid="auditForm.invalid('type')"
                                @select="auditForm.validate('type')"
                            />
                            <small v-if="auditForm.invalid('type')" class="text-red-500"> {{ auditForm.errors.type }} </small>
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="note_expectations" class="text-dark-slate-600 uppercase tracking-widest">Attentes</label>
                        <Textarea
                            v-model="auditForm.note_expectations"
                            :invalid="auditForm.invalid('note_expectations')"
                            @select="auditForm.validate('note_expectations')"
                        />
                        <small v-if="auditForm.invalid('note_expectations')" class="text-red-500"> {{ auditForm.errors.note_expectations }} </small>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="note_preliminary" class="text-dark-slate-600 uppercase tracking-widest">Remarques préalables</label>
                        <Textarea
                            v-model="auditForm.note_preliminary"
                            :invalid="auditForm.invalid('note_preliminary')"
                            @select="auditForm.validate('note_preliminary')"
                        />
                        <small v-if="auditForm.invalid('note_preliminary')" class="text-red-500"> {{ auditForm.errors.note_preliminary }} </small>
                    </div>

                    <Button @click="handleStartAudit">Démarrer l'enquête</Button>
                </div>

                <div v-if="audit?.id" v-for="section in survey?.sections?.data">
                    <div v-show="Number(openedSection) === Number(section.id)" class="flex flex-col">
                        <div v-for="question in section?.questions?.data">
                            <div v-show="Number(openedQuestion) === Number(question.id)">
                                <h3 class="font-bold">{{ question.translatable.title[locale] }}</h3>
                                <p class="mb-2"> {{ question.translatable.note[locale] }} </p>

                                <div class="flex flex-col gap-2 mb-4">
                                    <Select
                                        v-model="auditItemForm.survey_response_item_id"
                                        :options="question?.response?.items?.data"
                                        optionLabel="title"
                                        empty-message="Aucune réponse"
                                        placeholder="Sélectionnez une réponse"
                                        :invalid="auditItemForm.invalid('survey_response_item_id')"
                                        @select="auditItemForm.validate('survey_response_item_id')"
                                    />
                                    <small v-if="auditItemForm.invalid('survey_response_item_id')" class="text-red-500"> {{ auditItemForm.errors.survey_response_item_id }} </small>
                                </div>

                                <div class="flex flex-col gap-2 mb-4">
                                    <Textarea
                                        v-model="auditItemForm.note"
                                        :invalid="auditItemForm.invalid('note')"
                                        @select="auditItemForm.validate('note')"
                                    />
                                    <small v-if="auditItemForm.invalid('note')" class="text-red-500"> {{ auditItemForm.errors.note }} </small>
                                </div>

                                <div class="flex flex-col gap-2 mb-6">
                                    <FilePckr
                                        id="illustration"
                                        :accepted-file-types="config?.media['mime-types'].image.join(',')"
                                        v-model="auditItemForm.illustration"
                                        @start-load="handleStartLoadFiles"
                                        @end-load="handleEndLoadFiles"
                                    />
                                    <small v-if="auditItemForm.invalid('illustration')" class="text-red-500"> {{ auditItemForm.errors.illustration }} </small>
                                </div>

                                <Button @click="handleSaveAuditItem(question)">Question suivante</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-4" v-show="openedSection === -2">
                    <div class="flex flex-col gap-2">
                        <label for="end_at" class="text-dark-slate-600 uppercase tracking-widest">Date de fin</label>
                        <DatePicker
                            dateFormat="dd/mm/yy"
                            showTime
                            hourFormat="24"
                            fluid
                            v-model="auditFinalizeForm.end_at"
                            :invalid="auditFinalizeForm.invalid('end_at')"
                            @select="auditFinalizeForm.validate('end_at')"
                        />
                        <small v-if="auditFinalizeForm.invalid('end_at')" class="text-red-500"> {{ auditFinalizeForm.errors.end_at }} </small>
                    </div>

                    <div class="flex flex-col gap-2">
                        <label for="note_final" class="text-dark-slate-600 uppercase tracking-widest">Attentes</label>
                        <Textarea
                            v-model="auditFinalizeForm.note_final"
                            :invalid="auditFinalizeForm.invalid('note_final')"
                            @select="auditFinalizeForm.validate('note_final')"
                        />
                        <small v-if="auditFinalizeForm.invalid('note_final')" class="text-red-500"> {{ auditFinalizeForm.errors.note_final }} </small>
                    </div>

                    <Button @click="handleEndAudit">Finaliser l'enquête</Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref, watch} from "vue";

    import ProgressSpinner from "primevue/progressspinner";
    import ToggleSwitch from "primevue/toggleswitch";
    import Select from "primevue/select";
    import Textarea from "primevue/textarea";
    import DatePicker from "primevue/datepicker";
    import Button from "primevue/button"

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useSurveysStore, useConfigsStore, useRestaurantsStore} from "../../../store";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import {useAuditsStore} from "../../../store/audit";
    import AUDIT_TYPES from "../../../enums/audit-types";
    import FilePckr from "../../../components/file-pckr.vue";

    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: survey?.value?.translatable?.title[locale.value] , route: {name: 'survey-audits', params: {id: route.params.survey_id}} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: 'Contrôle'})

        return items;
    });

    const configsStore = useConfigsStore();
    const {locale, config} = storeToRefs(configsStore)

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const survey = surveysRefs.single;

    const auditsStore = useAuditsStore();
    const { single: audit, isFetchSinglePending, isSavePending } = storeToRefs(auditsStore);

    const restaurantsStore = useRestaurantsStore();
    const { list: restaurants } = storeToRefs(restaurantsStore);

    const typeOptions = Object.entries(AUDIT_TYPES.labels).map( type => {
        return {id: type[0], name: type[1]}
    });

    const auditForm = useForm(
        'post',
        '/api/surveys/audits',
        {
            restaurant_id: '',
            date_at: new Date(),
            type: '',
            note_expectations: '',
            note_preliminary: '',
            survey_id: ''
        }
    );

    const auditItemForm = useForm(
        'post',
        () => '/api/surveys/audits/' + audit?.value?.id + '/item',
        {
            survey_response_item_id: null,
            note: '',
            illustration: '',
            survey_question_id: null
        }
    );

    const auditFinalizeForm = useForm(
        'post',
        () => '/api/surveys/audits/' + audit?.value?.id + '/finalize',
        {
            end_at: '',
            note_final: ''
        }
    );

    onBeforeMount(async () => {
        await surveysStore.getItem({id: String(route.params.survey_id), includes: ['sections', 'sections.questions', 'sections.questions.response.items', 'sections.questions.illustration']})

        if (route.params.id) {
            await surveysStore.getItem({id: String(route.params.id)})
        } else {
            audit.value = {
                restaurant_id: ''
            }
        }

        await restaurantsStore.getItems();
    })

    const openedSection = ref(-1);
    const openedQuestion = ref(0);

    const handleStartAudit = () => {
        isSavePending.value = true;

        let untouchedData = auditForm.data();

        auditForm
            .setData({
                date_at: untouchedData.date_at ? new Date(untouchedData.date_at).toISOString().replace('T', ' ').split('.')[0] : null,
                restaurant_id: untouchedData?.restaurant_id?.id,
                type: untouchedData?.type?.id,
                survey_id: survey.value.id
            })
            .submit()
            .then((response:any) => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'L\'audit a bien été commencé'})
                openedSection.value = survey?.value?.sections?.data[0]?.id;
                openedQuestion.value = survey?.value?.sections?.data[0]?.questions?.data[0]?.id;

                audit.value = response.data.data;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }

                auditForm.setData({...untouchedData});
            })
            .finally(() => {
                isSavePending.value = true;
            })
    }

    const handleOpenSection = (section_id:number) => {
        if (audit?.value?.id) {
            openedSection.value = openedSection.value === section_id ? 0 : section_id;
            openedQuestion.value = survey?.value?.sections?.data?.find((section: any) => Number(section.id) === section_id)?.questions?.data[0]?.id

            let question = survey?.value?.sections?.data?.find((section: any) => Number(section.id) === section_id)?.questions?.data[0];

            let item = audit.value.sections.data.find( (audit_section:any) => Number(audit_section.survey_section_id) === Number(openedSection.value))?.items?.data?.find( (audit_question:any) => Number(audit_question.survey_question_id) === Number(openedQuestion.value));

            auditItemForm.setData({
                survey_response_item_id: question?.response?.items?.data.find( (response:any) => response.id === item.survey_response_item_id),
                note: item?.note,
                illustration: item?.illustration,
                survey_question_id: item?.survey_question_id
            })
        }else {
            configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Veuillez d\'abord remplir l\'introduction'})
        }
    }

    const handleOpenQuestion = (question:any) => {
        openedQuestion.value = question.id

        let item = audit.value.sections.data.find( (audit_section:any) => Number(audit_section.survey_section_id) === Number(openedSection.value)).items.data.find( (audit_question:any) => Number(audit_question.survey_question_id) === Number(question.id));

        auditItemForm.setData({
            survey_response_item_id: question?.response?.items?.data.find( (response:any) => response.id === item.survey_response_item_id),
            note: item.note,
            illustration: item.illustration,
            survey_question_id: item.survey_question_id
        })
    }

    const isFilepondPending = ref(false);

    const handleStartLoadFiles = ({files, id}: { files: [], id: number }) => {
        auditItemForm.setData({[id]: files.map((file: { serverId: string }) => file.serverId)[0]})
        isFilepondPending.value = true;
    }

    const handleEndLoadFiles = ({files, id}: { files: [], id: number }) => {
        auditItemForm.setData({[id]: files.map((file: { serverId: string }) => file.serverId)[0]})
        isFilepondPending.value = false;
    }

    const handleSaveAuditItem = (question:any) => {
        isSavePending.value = true;

        let untouchedData = auditItemForm.data();

        auditItemForm
            .setData({
                survey_response_item_id: untouchedData?.survey_response_item_id?.id,
                survey_question_id: question.id
            })
            .submit()
            .then((response:any) => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Réponse enregistrée'})

                try {
                    audit.value = response.data.data;

                    let sections = survey?.value?.sections?.data || [];
                    let sectionIndex = sections.findIndex( (section:any) => Number(section.id) === Number(question.survey_section_id) );
                    let questions = sections[sectionIndex]?.questions?.data || [];
                    let questionIndex = questions.findIndex( (item:any) => Number(item.id) === Number(question.id) );

                    if (questionIndex === questions.length - 1) {
                        sectionIndex++;
                        questionIndex = 0;
                        // Sauter les sections sans questions
                        while (sectionIndex < sections.length && questions.length === 0) sectionIndex++
                    }else{
                        questionIndex++;
                    }

                    // Vérifier si on a dépassé toutes les sections
                    if (sectionIndex >= sections.length) {
                        openedSection.value = -2
                    } else {
                        openedSection.value = sections[sectionIndex]?.id;
                        openedQuestion.value = questions[questionIndex].id;
                    }

                    auditItemForm.setData({
                        survey_response_item_id: null,
                        note: '',
                        illustration: '',
                        survey_question_id: null
                    })
                }catch( e ) {
                    console.warn(e);
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }

                auditItemForm.setData({...untouchedData});
            })
            .finally(() => {
                isSavePending.value = true;
            })
    }

    const router = useRouter();

    const handleEndAudit = () => {
        isSavePending.value = true;

        let untouchedData = auditFinalizeForm.data();

        auditFinalizeForm
            .setData({
                end_at: untouchedData.end_at ? new Date(untouchedData.end_at).toISOString().replace('T', ' ').split('.')[0] : null,
            })
            .submit()
            .then(() => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'L\'audit a bien été terminé'})

                return router.push({name: 'survey-audits', params: {id: survey.value.id}});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }

                auditFinalizeForm.setData({...untouchedData});
            })
            .finally(() => {
                isSavePending.value = true;
            })
    }

    const editorRef = ref()

    watch(editorRef, (editor) => {
        if (!editor) return
        // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
        editor.renderValue = function renderValue(value) {
            if (this.quill) {
                if (value) {
                    const delta = this.quill.clipboard.convert({ html: value })
                    this.quill.setContents(delta, 'silent')
                } else {
                    this.quill.setText('')
                }
            }
        }.bind(editor) // Bind needed for production build
    })
</script>

<style>
    .fadeHeight-enter-active,
    .fadeHeight-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.01, 1, 0.01);
        max-height: 250px;
    }
    .fadeHeight-enter,
    .fadeHeight-leave-to
    {
        opacity: 0;
        max-height: 0;
    }
</style>
