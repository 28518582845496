<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Retourner des produits
                </h1>
            </div>

            <div>
                <Button
                    label="+ Ajouter"
                    @click.prevent="handleAddProduct"
                    size="small"
                />
            </div>
        </div>

        <small v-if="form.invalid('items')" class="text-red-500"> {{ form.errors.items }} </small>

        <DataTable
            :value="complaint?.items"
            :rows="1000"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            @sort="handleSortChange($event)"
            paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats, cliquez sur
                    <Button
                        label="+ Ajouter"
                        @click.prevent="handleAddProduct"
                        size="small"
                        class="mx-2"
                    />
                </p>
            </template>

            <Column header="Produits retourné" field="name" #body="slotProps">
                {{ slotProps.data?.product?.name }}
            </Column>

            <Column header="Prix" #body="slotProps">
                {{ Number(slotProps.data.product.inventory_price) }} €
            </Column>

            <Column header="Conditionnement" field="conditioning" :sortable="true" #body="slotProps">
                {{ slotProps.data?.product?.inventory_conditioning_quantity }}
                {{ slotProps.data?.product?.conditioning?.label }}
            </Column>

            <Column header="Quantité" field="quantity" #body="slotProps">
                {{ slotProps.data?.quantity }}
            </Column>

            <Column header="Coût" #body="slotProps">
                {{ Number(slotProps.data.product.inventory_price *  slotProps.data?.quantity) }} €
            </Column>

            <Column header="Raison" field="raisons" #body="slotProps">
                {{ slotProps.data?.complaint_reason?.label }}
                {{ slotProps.data?.complaint_reason_note }}
            </Column>
        </DataTable>

        <div class="flex flex-col gap-2 mb-6">
            <label for="order_id" class="text-dark-slate-600 uppercase tracking-widest">Référence commande</label>
            <Select
                id="order_id"
                name="order_id"
                optionLabel="label"
                :options="ordersOption"
                placeholder="Sélectionnez une commande"
                v-model="form.order_id"
                :required="true"
            />
            <small v-if="form.invalid('order_id')" class="text-red-500"> {{ form.errors.order_id }} </small>
        </div>

        <div class="flex gap-2 flex-col mb-6">
            <label for="note" class="text-surface-400 tracking-widest">Informations complémentaires</label>
            <Editor
                id="note"
                name="note"
                class="w-full"
                v-model="form.note"
                :invalid="form.invalid('note')"
                @change="form.validate('note')"
            />
            <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
        </div>

        <div>
            <Button @click="handleSubmit" :disabled="isSavePending">
                Ajouter
                <ProgressSpinner v-if="isSavePending" :style="{height: '20px', width: '20px', marginLeft: '10px'}" stroke-width="6"/>
            </Button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, onUnmounted, ref} from "vue";
    import {useComplaintsStore, useConfigsStore, useRestaurantsStore} from "../../../../store";
    import {storeToRefs} from "pinia";

    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import Select from "primevue/select"
    import Editor from "primevue/editor"
    import ProgressSpinner from "primevue/progressspinner";

    import {useRouter} from "vue-router";
    import {useDialog} from "primevue/usedialog";
    import AddProductToComplaint from "../../../../components/dialogs/AddProductToComplaint.vue";
    import {useForm} from "laravel-precognition-vue";
    import {OrderType} from "../../../../types/order";
    import COMPLAINT_TYPES from "../../../../enums/complaint-types"
    import Breadcrumbs from "../../../../components/partials/backoffice/breadcrumbs.vue";

    const breadCrumbsFirstItem = computed( () => { return { label: 'Retours' , route: {name: 'returns' } } } );

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: complaint?.value?.restaurant.name})

        items.push({label: complaint?.value?.supplier.name})

        items.push({label: 'Choix produits'})

        return items;
    });

    const router = useRouter()
    const dialog = useDialog()

    const complaintsStore = useComplaintsStore();

    const { isSavePending, single: complaint } = storeToRefs(complaintsStore)

    const restaurantsStore = useRestaurantsStore()
    const restaurantsRefs = storeToRefs(restaurantsStore)
    const products = restaurantsRefs.products
    const orders = restaurantsRefs.orders
    const ordersOption = ref();

    const configsStore = useConfigsStore()

    onBeforeMount(async () => {
        if(!complaint?.value?.restaurant_id || !complaint?.value?.supplier_id) {
            complaint.value = null;
            await router.push({name: 'returns'})
        }else {
            await restaurantsStore.getOrders({restaurant_id: complaint?.value?.restaurant_id, supplier_id: complaint?.value?.supplier_id, list: true})

            ordersOption.value = orders?.value?.map( (order:OrderType) => {
                return {
                    label: `${order.reference} (${new Intl.DateTimeFormat('fr-BE').format(new Date(order.created_at))})`, id: order.id
                }
            })
        }
    });

    onUnmounted( () => {
        complaint.value = null;
        products.value = null;
    })

    const handlePageChange = (event:any) => {

    }

    const handleSortChange = (event:any) => {

    }

    const form = useForm(
        'post',
        '/api/complaints',
        {
            'type': null,
            'order_id': null,
            'note': '',
            'items': [],
        }
    );

    const handleSubmit = (event:any) => {
        isSavePending.value = true;

        const untouchedData = form.data();

        if(!untouchedData.order_id) {
            form.setErrors({order_id: 'Ce champ est obligatoire'})
            isSavePending.value = false;
        }else {
            form.setData({
                type: Number(COMPLAINT_TYPES.values.RETURN.description),
                order_id: untouchedData?.order_id?.id || null,
                items: complaint?.value?.items?.map( (item:any) => {
                    if(item.product) {
                        return {
                            product_id: item.product.id,
                            quantity: item.quantity,
                            complaint_reason_id: item.complaint_reason.id,
                            complaint_reason_note: item.complaint_reason_note,
                            uploads: item.uploads
                        }
                    }
                })
            })
                .submit()
                .then(() => {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le retour a bien été envoyé'})

                    return router.push({name: 'returns'});
                })
                .catch(error => {
                    if (error?.response?.status === 422) {
                        configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                    }
                    if (error?.response?.status === 500) {
                        configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                    }

                    form.setData({...untouchedData});
                })
                .finally(() => {
                    isSavePending.value = false;
                })
        }
    }

    const handleAddProduct = () => {
        dialog.open(AddProductToComplaint, {
            props: {
                header: 'Ajouter un produit à retourner',
                modal: true
            },
            data: {
                complaint: complaint,
                type: Number(COMPLAINT_TYPES.values.RETURN.description)
            },
            onClose: (payload:any) => {
                if(payload.data?.quantity && payload.data?.product?.id) {
                    complaint.value.items.push(payload.data);
                }
            }
        })
    }
</script>
