<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex md:flex-row flex-col">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Réponses - {{ survey?.translatable?.title[locale] }}
                </h1>

                <Button @click="handleCreateAudit">+ Ajouter un contrôle</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>
        <DataTable
            :value="audits"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            stateStorage="session"
            stateKey="dt-state-orders"
            :globalFilterFields="['date', 'restaurant.name', 'supplier.name', 'ref', 'price', 'status', 'invoice_number']"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Date" :sortable="true" sort-field="created_at" #body="slotProps">
                {{ new Intl.DateTimeFormat('fr-BE').format(new Date(slotProps.data.created_at)) }}
            </Column>

            <Column header="Restaurant" :sortable="true" sort-field="restaurant.name" #body="slotProps">
                {{ slotProps.data.restaurant.name }}
            </Column>

            <Column header="Type" :sortable="true" sort-field="type" #body="slotProps">
                {{ AUDIT_TYPES.labels[slotProps.data.type] }}
            </Column>

            <Column header="Auteur" :sortable="true" sort-field="user.name" #body="slotProps">
                {{ slotProps.data?.user?.name }}
            </Column>

            <Column header="A corriger" :sortable="true" sort-field="items_to_correct_count" #body="slotProps">
                <span :class="slotProps.data.items_corrected_count < slotProps.data.items_not_compliant_count ? 'text-red-500' : 'text-black'">{{ slotProps.data.items_corrected_count }} / {{ slotProps.data.items_not_compliant_count }}</span>
            </Column>

            <Column key="actions" header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center gap-2">
                        <Button
                            link
                            label="Voir"
                            @click.prevent="handleShowAudit(slotProps.data)"
                            v-tooltip="'Voir'">
                            <i class="pi pi-eye"></i>
                        </Button>
                        <Button
                            link
                            label="Modifier"
                            @click.prevent="handleEditAudit(slotProps.data)"
                            v-tooltip="'Éditer'">
                            <i class="pi pi-pencil"></i>
                        </Button>
                        <Button
                            v-if="slotProps.data.items_corrected_count < slotProps.data.items_not_compliant_count"
                            link
                            label="Réviser"
                            @click.prevent="handleReviseAudit(slotProps.data)"
                            v-tooltip="'Corriger'">
                            <i class="pi pi-check"></i>
                        </Button>
                        <Button
                            v-if="authStore.isRoot()"
                            link
                            label="Supprimer"
                            @click.prevent="handleDeleteAudit(slotProps.data)"
                            class="text-red-600"
                            v-tooltip="'Supprimer'">
                            <i class="pi pi-trash"></i>
                        </Button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import Search from "../../../components/partials/backoffice/search.vue";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Button from "primevue/button";
    import {computed, onBeforeMount, ref} from "vue";
    import {useSurveysStore, useConfigsStore, useAuthStore} from "../../../store";
    import {storeToRefs} from "pinia";
    import {useRoute, useRouter} from "vue-router";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import {AuditType} from "../../../types/audit";
    import {useAuditsStore} from "../../../store/audit";
    import useDatatableActions from "../../../composables/datatable-actions";
    import AUDIT_TYPES from "../../../enums/audit-types"
    import {useConfirm} from "primevue/useconfirm";

    const router = useRouter()
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });
    const breadCrumbsItems = computed(() => [{label: 'Audits'}]);

    const queryParams = ref({
        search: {
            search: null,
            survey_id: route.params.id
        },
        includes: ['restaurant', 'itemsNotCompliantCount', 'itemsCorrectedCount', 'user'],
        sort_by: null,
        desc: false,
    })

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore);

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const survey = surveysRefs.single;

    const auditsStore = useAuditsStore();
    const { list: audits, pagination, isFetchListPending, single } = storeToRefs(auditsStore)

    const {handlePageChange, handleSortChange, handleGlobalSearch} = useDatatableActions(pagination, queryParams, auditsStore.getItems)

    onBeforeMount(async () => {
        await surveysStore.getItem({id: String(route.params.id)})
        await auditsStore.getItems(queryParams.value)
    })

    const handleCreateAudit = () => {
        single.value = null
        router.push({name: 'audit-create',  params: {survey_id: route.params.id} });
    }

    const handleEditAudit = (audit:AuditType) => {
        single.value = null
        router.push({name: 'audit-edit',  params: {id: audit.id }});
    }

    const handleShowAudit = (audit:AuditType) => {
        single.value = null
        router.push({name: 'audit-show',  params: (audit ? {id: audit.id} : {}) });
    }

    const handleReviseAudit = (audit:AuditType) => {
        single.value = null
        router.push({name: 'audit-revise',  params: (audit ? {id: audit.id} : {}) });
    }

    const authStore = useAuthStore();

    const confirm = useConfirm()

    const handleDeleteAudit = (audit:AuditType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {auditsStore.deleteItem(audit.id)}
        })
    }
</script>
