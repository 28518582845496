import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/conveniences/List.vue"
import CreateRestaurant from "../views/backoffice/conveniences/create/Restaurant.vue"
import CreateProducts from "../views/backoffice/conveniences/create/Products.vue"
import Store from "../views/backoffice/conveniences/create/Validate.vue"
import Receive from "../views/backoffice/conveniences/Receive.vue"
import Show from "../views/backoffice/conveniences/Show.vue"
import Save from "../views/backoffice/conveniences/Save.vue";

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/conveniences',
        children: [
            {
                path: '',
                name: 'conveniences',
                component: List,
            },
            {
                path: '/admin/conveniences/create/restaurant',
                name: 'convenience-create-restaurant',
                component: CreateRestaurant,
            },
            {
                path: '/admin/conveniences/create/products',
                name: 'convenience-create-products',
                component: CreateProducts,
            },
            {
                path: '/admin/conveniences/create/validate',
                name: 'convenience-create-validate',
                component: Store,
            },
            {
                path: '/admin/conveniences/:id/receive',
                name: 'convenience-receive',
                component: Receive,
            },
            {
                path: '/admin/conveniences/:id/show',
                name: 'convenience-show',
                component: Show,
            },
            {
                path: '/admin/conveniences/:id/save',
                name: 'convenience-save',
                component: Save,
            }
        ]
    },
]

export default module;
