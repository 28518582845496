<template>
    <Breadcrumb :home="breadCrumbsFirstItem" />

    <DataTable
        scrollable scrollHeight="100vh"
        class="mx-20"
        :value="contents"
        :loading="isFetchListPending"
        :rows="pagination?.per_page"
        :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
        :globalFilterFields="['name']"
        :totalRecords="pagination?.total"
        @page="handlePageChange($event)"
        @sort="handleSortChange($event)"
        lazy
        paginator
        showGridlines
        stripedRows
        removableSort
        currentPageReportTemplate="{currentPage}/{totalPages}"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    >
        <template #empty> Aucun contenu pour l'instant </template>
        <template #loadingicon>
            <ProgressSpinner />
        </template>
        <template #header>
            <div class="flex justify-between py-4 px-2">
                <div class="flex items-center">
                    <h2 class="mr-2 text-4xl text-dark-slate-800">Contenu(s)</h2>
                    <Button @click.prevent="handleCreateOrEditContent" severity="primary" class="w-[130px] flex justify-center text-[15px]"><i class="pi pi-plus-circle mr-2"/>Ajouter</Button>
                </div>

                <div class="flex">
                    <span class="p-input-icon-left text-dark-slate-700">
                        <i class="pi pi-search" />
                        <InputText
                            placeholder="Recherche"
                            v-model="filters['global'].value"
                            class="small-input w-[230px] h-[40px] placeholder-dark-slate-700"
                            @update:modelValue="handleGlobalFilterChangeDebounced"
                        />
                    </span>
                </div>
            </div>
        </template>
        <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="col.sortable" :body="col.body">
            <template v-if="col.filterable" #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by name" />
            </template>
        </Column>
        <Column key="content" header="Contenu">
            <template #body="slotProps">
                <template v-if="slotProps.data.type === Number(CONTENT_TYPES.values.T.description) || slotProps.data.type === Number(CONTENT_TYPES.values.CONTENT.description)">
                    {{ slotProps.data.title ? slotProps.data.title[locale] : ''}}
                    {{ slotProps.data.content ? slotProps.data.content[locale] : ''}}
                </template>
                <template v-if="slotProps.data.type === Number(CONTENT_TYPES.values.IMAGE.description)">
                    <img :src="slotProps.data.picture.original_url" alt="" class="h-full w-full max-w-xs object-fill">
                </template>
            </template>
        </Column>
        <Column key="actions" header="Action(s)">
            <template #body="slotProps">
                <div class="flex items-center gap-2">
                    <Button
                        label="Modifier"
                        @click.prevent="handleCreateOrEditContent(slotProps.data)"
                        severity="secondary"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Éditer'"
                    >
                        <i class="pi pi-pencil"></i>
                    </Button>
                    <Button
                        label="Supprimer"
                        @click.prevent="handleDeleteSpeaker(slotProps.data)"
                        severity="danger"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Supprimer'">
                        <i class="pi pi-trash"></i>
                    </Button>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script setup lang="ts">
import {ref, onBeforeMount, computed} from "vue";
import Breadcrumb from "primevue/breadcrumb";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {useConfigsStore, useContentsStore} from "../../../store";
import { storeToRefs } from "pinia";
import useDebounce from "../../../composables/debounce";
import ProgressSpinner from "primevue/progressspinner";
import {useRoute, useRouter} from "vue-router";
import {useConfirm} from "primevue/useconfirm";
import {ContentType} from "../../../types/content";
import CONTENT_TYPES from "../../../enums/content-types";

const route = useRoute()
const router = useRouter();
const confirm = useConfirm()

const breadCrumbsFirstItem = computed( () => {
    return { label: 'Contenu(s)' , to: '/admin/contents' }
})

const configsStore = useConfigsStore();
const configsRefs = storeToRefs(configsStore);

const locale = configsRefs.locale;

const columns = [
    { field: 'name', header: 'Nom', sortable: false, filterable: false},
];

const contentsStore = useContentsStore();
const contentsRefs = storeToRefs(contentsStore);

const pagination = contentsRefs.pagination;
const isFetchListPending = contentsRefs.isFetchListPending;
const contents = contentsRefs.list;

const fetchContentsList = (search?:any) => {
    contentsStore.getItems({includes: ['picture'], search: search, per_page: pagination.value.per_page});
}

const handlePageChange = (event:any) => {
    console.warn("page change");
    pagination.value.per_page = event.rows;
    pagination.value.current_page = event.page + 1;
    fetchContentsList();
}

const handleSortChange = (event:any) => {
    contentsStore.getItems({sort_by: event.sortField })
}

const handleGlobalFilterChange = () => {
    fetchContentsList({ global: filters.value.global.value });
}

const handleGlobalFilterChangeDebounced = useDebounce(() => {
    handleGlobalFilterChange();
}, 500);

onBeforeMount(() => {
    fetchContentsList();
})

const handleDeleteSpeaker = (form:ContentType) => {
    confirm.require({
        message: 'Êtes vous sur de vouloir supprimer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        acceptClass: 'p-button-danger',
        accept: () => contentsStore.deleteItem(form.id)
    })
}

const handleCreateOrEditContent = (content:ContentType) => {
    contentsRefs.single.value = null
    console.warn(content);
    router.push({name: 'content-save',  params: (content ? {content_id: content.id} : {}) });
}
</script>
