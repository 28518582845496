import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/responses/List.vue"
import Save from "../views/backoffice/responses/Save.vue";

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/responses',
        children: [
            {
                path: '',
                name: 'responses',
                component: List,
            },
            {
                path: '/admin/responses/:id?/save',
                name: 'response-save',
                component: Save,
            },
        ]
    }
]

export default module;
