<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>
    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex justify-between items-start">
            <div class="mb-4 flex items-center gap-2">
                <h1 class="text-5xl uppercase font-title">
                    {{ audit?.translatable?.title[locale] }}
                </h1>
                <Button icon="pi pi-pencil" size="small" class="w-5 h-5" @click.prevent="handleEditAudit"></Button>
            </div>

            <div class="flex gap-2 items-center">
                <Button size="small" @click.prevent="handleSendReport">Envoyer le rapport</Button>
                <SplitButton
                    size="small"
                    label="Export en PDF"
                    :model="exportOptions"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                />
            </div>
        </div>


        <div class="flex items-center gap-4 mb-6">
            <h2 class="text-2xl font-title">
                {{ audit.restaurant.name }}
            </h2>
            {{ new Intl.DateTimeFormat('fr-BE').format(new Date(audit.created_at)) }}
        </div>

        <h2 class="text-3xl font-title mb-2" :class="`text-${RESPONSE_TYPES.colors[audit.result]}-700`">
            {{ RESPONSE_TYPES.labels[audit.result] }}
        </h2>
        <DataTable :value="audit.items_stats_count" class="mb-6 max-w-[800px]">
            <Column header="" #body="slotProps">
                {{ RESPONSE_TYPES.labels[slotProps.index+1] }}
            </Column>
            <Column field="manager" header="Équipe"></Column>
            <Column field="team" header="Manager"></Column>
            <Column field="total" header="Total"></Column>
        </DataTable>

        <p class="flex items-center mb-6 gap-2">Affiche uniquement les résultats avec commentaire <ToggleSwitch v-model="withNotes"/></p>

        <div class="flex flex-col">
            <div v-for="section in audit?.sections?.data">
                <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between">
                    <div class="flex gap-10 items-center cursor-pointer hover:opacity-80 transition-opacity" @click="handleOpenSection(section.id)">
                        <h3 class="flex items-center gap-1">
                            <i class="pi" :class="openedSection === section.id ? 'pi-angle-up' : 'pi-angle-down'" />
                            {{ section.translatable.title[locale] }}
                        </h3>
                        <p :class="`font-bold text-${RESPONSE_TYPES.colors[section.result]}-700`">
                            {{ RESPONSE_TYPES.labels[section.result] }}
                        </p>
                    </div>
                </div>
                <Transition name="fadeHeight">
                    <div v-show="openedSection === section.id" class="p-2 rounded-b-md border border-surface-100 transition-all">
                        <template  v-for="item in section?.items?.data">
                            <div class="p-2 rounded-md" v-if="!withNotes || (withNotes && item.note)">
                                <div class="flex gap-4 mb-2">
                                    <h3 class="font-bold">{{ item.extra_attributes.question }}</h3>
                                    <p :class="`font-bold text-${RESPONSE_TYPES.colors[item.response_type]}-700`">
                                        {{ RESPONSE_TYPES.labels[item.response_type] }}
                                    </p>
                                </div>
                                <p v-if="item.response_type === 2 || item.response_type === 3">
                                    {{ item.note }}
                                </p>
                            </div>
                        </template>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref } from "vue";

    import ProgressSpinner from "primevue/progressspinner";
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";

    import {useRoute} from "vue-router";

    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";

    import {storeToRefs} from "pinia";
    import {useSurveysStore, useConfigsStore} from "../../../store";
    import {useAuditsStore} from "../../../store/audit";

    import RESPONSE_TYPES from "../../../enums/response-types"
    import ToggleSwitch from "primevue/toggleswitch";
    import SplitButton from "primevue/splitbutton";

    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: survey?.value?.translatable?.title[locale.value] , route: {name: 'survey-audits', params: {id: route.params.survey_id}} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: 'Contrôle'})

        return items;
    });

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore)

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const survey = surveysRefs.single;

    const auditsStore = useAuditsStore();
    const { single: audit, isFetchSinglePending } = storeToRefs(auditsStore);

    onBeforeMount(async () => {
        await auditsStore.getItem({id: String(route.params.id), includes: ['items', 'sections.items', 'sections.items.response', 'sections', 'restaurant']})
    })

    const openedSection = ref(-1);
    const openedItem = ref(0);

    const handleOpenSection = (section_id:number) => {
        openedSection.value = openedSection.value === section_id ? 0 : section_id;
        openedItem.value = audit?.value?.sections?.data?.find( (section:any) => Number(section.id) === section_id)?.items?.data[0].survey_question_id
    }

    const withNotes = ref(false);

    const exportOptions = [
        {label: 'Version complète', command: () => { handleExportComplete() }},
        {label: 'Version pour les équipes', command: () => { handleExportAbstract() }}
    ]

    const handleExportComplete = () => {
        console.warn("exporter au complet")
    }

    const handleExportAbstract = () => {
        console.warn("exporter pour équipe")
    }

    const handleSendReport = () => {
        console.warn("send report")
    }

    const handleEditAudit = () => {
        console.warn("edit audit")
    }
</script>

<style>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.01, 1, 0.01);
    max-height: 250px;
}
.fadeHeight-enter,
.fadeHeight-leave-to
{
    opacity: 0;
    max-height: 0;
}
</style>
