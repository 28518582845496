<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending || isFetchProductsPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between items-end">
            <div>
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Modifier la commande {{ order.reference }}
                </h1>
                <p><strong>Fournisseur</strong>: {{ order.supplier.name }}</p>
                <p><strong>Restaurant</strong>: {{ order.restaurant.name }}</p>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            :value="products"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="products?.length"
            @sort="handleSortChange($event)"
            paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" :sortable="true" #body="slotProps">
                {{ slotProps.data.name }}
                <template v-if="slotProps.data.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle"
                       v-tooltip="slotProps.data.note"
                    ></i>
                </template>
            </Column>

            <Column header="Prix" :sortable="true" #body="slotProps">
                {{ slotProps.data.price.toFixed(2) }} €
            </Column>

            <Column header="Conditionnement" :sortable="true" #body="slotProps">
                {{ slotProps.data.conditioning_quantity }}
                {{ slotProps.data.conditioning.label }}
            </Column>

            <Column header="Utilisation" :sortable="true" #body="slotProps">
                {{ slotProps.data.usage }}
            </Column>

            <Column header="Tags" field="tags" :sortable="false" #body="slotProps">
                <template v-for="tag in slotProps.data.tags.slice(0, 2)">
                    <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                </template>
                <template v-if="slotProps.data.tags.length > 2">
                    (+{{ slotProps.data.tags.length - 2 }})
                </template>
            </Column>

            <Column header="Commande max" field="max" :sortable="true" #body="slotProps">
                <template v-if="slotProps?.data?.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)">
                    <p>
                        {{ slotProps?.data?.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id).product_restaurant.max_quantity_informative }} {{ slotProps?.data?.conditioning.label }} max (conseillée)
                    </p>
                    <p>
                        {{ slotProps?.data?.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id).product_restaurant.max_quantity_blocking }} {{ slotProps?.data?.conditioning.label }} max (limite)
                    </p>
                </template>
                <p v-else>
                    Pas de quantitée conseillée ou limite
                </p>
            </Column>

            <Column header="Q. commandée" #body="slotProps">
                <div class="flex flex-col items-center gap-2">
                    <InputNumber
                        placeholder="Quantité à commander"
                        v-model="slotProps.data.quantity"
                        :min="0"
                        :max="slotProps?.data?.product?.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_blocking || Number.POSITIVE_INFINITY"
                    />
                    <small v-if="slotProps.data.quantity > slotProps?.data?.product?.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_blocking" class="p-error">Attention la quantitée maximum est dépasée</small>
                    <small v-if="slotProps.data.quantity > slotProps?.data?.product?.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_informative" class="text-orange-500 text-[10px]">Attention la quantitée conseillée est dépasée</small>
                </div>
            </Column>
        </DataTable>

        <div class="mb-6">
            <Textarea
                id="note"
                name="note"
                placeholder="Commentaire libre"
                class="w-full"
                v-model="order.note"
            />
        </div>

        <div class="mb-6">
            <h2 class="mb-6" v-if="order?.items.data">Total :
                {{ order?.items.data.reduce((sum:number, item:any) => sum + Number(item.quantity ? item.quantity : 0), 0) }}
                ({{ order?.items.data.reduce((sum:number, item:any) => sum + (Number(item.price) * Number(item.quantity ? item.quantity : 0)), 0).toFixed(2) }} €)
            </h2>
            <SplitButton
                label="Valider ma commande"
                @click="handleSubmitOrder(ORDER_STATUS.values.PLANNED.description)"
                :model="[{
                    label: 'Enregistrer comme brouillon',
                    command: () => { handleSubmitOrder(ORDER_STATUS.values.DRAFT.description) }
                }]"
                pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
            />
        </div>

        <div class="flex gap-4 flex-col">
            <Logs :logs="logs" />
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";
    import {useConfigsStore, useOrdersStore, useRestaurantsStore} from "../../../store";
    import {storeToRefs} from "pinia";

    import ProgressSpinner from "primevue/progressspinner";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";
    import {RestaurantType} from "../../../types/restaurant";
    import InputNumber from "primevue/inputnumber";
    import Tag from "primevue/tag";
    import ORDER_STATUS from "../../../enums/order-status";
    import Textarea from "primevue/textarea";
    import SplitButton from "primevue/splitbutton";
    import ORDER_TYPE from "../../../enums/order-type";

    import Logs from "../../../components/partials/backoffice/logs.vue"
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";

    const breadCrumbsFirstItem = computed( () => { return { label: 'Commandes' , route: {name: 'orders' } } } );

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: order?.value?.restaurant.name})

        items.push({label: order?.value?.supplier.name})

        items.push({label: 'Modifier'})

        return items;
    });

    const router = useRouter();
    const route = useRoute()

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const isFetchSinglePending = ordersRefs.isFetchSinglePending;
    const order = ordersRefs.single
    const logs = ordersRefs.logs

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore)
    const products = restaurantsRefs.products;
    const isFetchProductsPending = restaurantsRefs.isFetchProductsPending

    const pagination = ref({
        per_page: 50,
        current_page: 1
    });

    const handleGlobalSearch = (value:string) => {
        ordersStore.getItems({search: {search: value}})
    }

    onBeforeMount(async () => {
        await ordersStore.getItem({id: route.params.id, includes: ['items', 'items.product', 'items.product.tags', 'items.product.conditioning', 'items.product.restaurants']});

        await ordersStore.getLogs({id: Number(route.params.id)});

        await restaurantsStore.getProductsBySupplier({
            restaurant_id: order?.value?.restaurant_id,
            supplier_id: order?.value?.supplier_id,
        });

        products.value = products.value.map( product => {
            return {
                ...product,
                item_id: order.value.items.data.find( item => item.product.id === product.id )?.id,
                quantity: order.value.items.data.find( item => item.product.id === product.id )?.quantity || 0
            }
        })

        form.setData({
            items : order?.value?.items.data,
        })
    });

    const handleSortChange = (event:any) => {

    }

    const form = useForm('put', '/api/orders/' + route.params.id,
        {
            items: [],
            note: '',
            status: null
        }
    );

    const isFilepondPending = ref(false);

    const configsStore = useConfigsStore();

    const handleSubmitOrder = (status:number) => {
        // handle save as final or draft
        // type = 1 because it's an order
        order.value.status = Number(status);
        order.value.type = Number(ORDER_TYPE.values.ORDER.description);

        form
            .setData({
                ...order.value,
                items: products.value.map( (product:any) => {
                    return {
                        id: product.item_id,
                        product_id: product.id,
                        quantity: product.quantity
                    }
                })
            })
            .submit()
            .then(() => {
                if (route.params.id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La commande a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La commande a bien été créé'})
                }

                return router.push({name: 'orders'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }
            })
            .finally(() => {
                ordersRefs.isSavePending.value = false;
            })

    }
</script>
