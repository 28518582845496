export default {
    values: Object.freeze ({
        CONFORM: Symbol(1),
        NOT_CONFORM: Symbol(2),
        CONFORM_REMARK: Symbol(3),
        NOT_APPLICABLE: Symbol(4),
    }),
    labels: {
        1: 'Conforme',
        2: 'Non conforme',
        3: 'Conforme avec remarque',
        4: 'Non applicable'
    },
    colors: {
        1: 'green',
        2: 'red',
        3: 'orange',
        4: 'grey'
    }
}

