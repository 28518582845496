import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/orders/List.vue"
import CreateSupplier from "../views/backoffice/orders/create/Supplier.vue"
import CreateProducts from "../views/backoffice/orders/create/Products.vue"
import Store from "../views/backoffice/orders/create/Validate.vue"
import Receive from "../views/backoffice/orders/Receive.vue"
import Reconcile from "../views/backoffice/orders/Reconcile.vue"
import Show from "../views/backoffice/orders/Show.vue"
import Save from "../views/backoffice/orders/Save.vue"
import Today from "../views/backoffice/orders/Today.vue";

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/orders/today',
        name: 'orders-today',
        component: Today
    },
    {
        path: '/admin/orders',
        children: [
            {
                path: '',
                name: 'orders',
                component: List,
            },
            {
                path: '/admin/orders/create/supplier',
                name: 'order-create-supplier',
                component: CreateSupplier,
            },
            {
                path: '/admin/orders/create/products',
                name: 'order-create-products',
                component: CreateProducts,
            },
            {
                path: '/admin/orders/create/validate',
                name: 'order-create-validate',
                component: Store,
            },
            {
                path: '/admin/orders/:id/receive',
                name: 'order-receive',
                component: Receive,
            },
            {
                path: '/admin/orders/:id/reconcile',
                name: 'order-reconcile',
                component: Reconcile,
            },
            {
                path: '/admin/orders/:id/show',
                name: 'order-show',
                component: Show,
            },
            {
                path: '/admin/orders/:id/save',
                name: 'order-save',
                component: Save,
            },
        ]
    },
]

export default module;
