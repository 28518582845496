<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isFetchPending || isFetchRestaurantsPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ supplier?.id ? 'Modifier un fournisseur' : 'Ajouter un fournisseur' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="name" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="name"
                    type="text"
                    name="name"
                    v-model="form.name"
                    :invalid="form.invalid('name')"
                    @change="form.validate('name')"
                />
                <small v-if="form.invalid('name')" class="text-red-500"> {{ form.errors.name }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="street" class="text-dark-slate-600 uppercase tracking-widest">Adresse</label>
                <InputText
                    id="address"
                    type="text"
                    name="address"
                    v-model="form.address"
                    :invalid="form.invalid('address')"
                    @change="form.validate('address')"
                />
                <small v-if="form.invalid('address')" class="text-red-500"> {{ form.errors.address }} </small>
            </div>

            <div>
                <div class="flex gap-2">
                    <div class="flex flex-col gap-2 flex-1">
                        <label for="postal_code" class="text-dark-slate-600 uppercase tracking-widest">Code postal</label>
                        <InputText
                            id="postal_code"
                            name="postal_code"
                            v-model="form.postal_code"
                            :invalid="form.invalid('postal_code')"
                            @change="form.validate('postal_code')"
                        />
                        <small v-if="form.invalid('postal_code')" class="text-red-500"> {{ form.errors.postal_code }} </small>
                    </div>

                    <div class="flex flex-col gap-2 flex-1">
                        <label for="city" class="text-dark-slate-600 uppercase tracking-widest">Ville</label>
                        <InputText
                            id="city"
                            name="city"
                            v-model="form.city"
                            :invalid="form.invalid('city')"
                            @change="form.validate('city')"
                        />
                        <small v-if="form.invalid('city')" class="text-red-500"> {{ form.errors.city }} </small>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <label for="vat_number" class="text-dark-slate-600 uppercase tracking-widest">N° de TVA</label>
                <InputText
                    id="vat_number"
                    name="vat_number"
                    v-model="form.vat_number"
                    :invalid="form.invalid('vat_number')"
                    @change="form.validate('vat_number')"
                />
                <small v-if="form.invalid('vat_number')" class="text-red-500"> {{ form.errors.vat_number }} </small>
            </div>

            <div class="flex flex-col gap-2 flex-1">
                <label for="contact_person" class="text-dark-slate-600 uppercase tracking-widest">Personne de contact</label>
                <InputText
                    id="contact_person"
                    name="contact_person"
                    v-model="form.contact_person"
                    :invalid="form.invalid('contact_person')"
                    @change="form.validate('contact_person')"
                />
                <small v-if="form.invalid('contact_person')" class="text-red-500"> {{ form.errors.contact_person }} </small>
            </div>

            <div class="flex flex-col gap-2 flex-1">
                <label for="contact_email" class="text-dark-slate-600 uppercase tracking-widest">Email</label>
                <InputText
                    id="contact_email"
                    name="contact_email"
                    v-model="form.contact_email"
                    :invalid="form.invalid('contact_email')"
                    @change="form.validate('contact_email')"
                />
                <small v-if="form.invalid('contact_email')" class="text-red-500"> {{ form.errors.contact_email }} </small>
            </div>

            <div class="flex flex-col gap-2 flex-1">
                <label for="contact_phone" class="text-dark-slate-600 uppercase tracking-widest">Numéro de téléphone</label>
                <InputText
                    id="contact_phone"
                    type="text"
                    name="contact_phone"
                    v-model="form.contact_phone"
                    :invalid="form.invalid('contact_phone')"
                    @change="form.validate('contact_phone')"
                />
                <small v-if="form.invalid('contact_phone')" class="text-red-500"> {{ form.errors.contact_phone }} </small>
            </div>

            <div class="flex flex-col gap-2 flex-1">
                <label for="note" class="text-dark-slate-600 uppercase tracking-widest">Commentaire libre (visible lors de la commande)</label>
                <Editor
                    id="note"
                    name="note"
                    ref="editorRef"
                    v-model="form.note[locale]"
                    :invalid="form.invalid('note')"
                    @change="form.validate('note')"
                />
                <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <div class="flex align-items-center">
                    <Checkbox
                        v-model="form.status"
                        inputId="status"
                        name="status"
                        :binary="true"
                        :class="form.invalid('status') ? 'p-invalid' : ''"
                    />
                    <label for="status" class="ml-2"> Actif </label>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <div class="flex align-items-center">
                    <Checkbox
                        v-model="form.is_weekend_opened"
                        inputId="is_weekend_opened"
                        name="is_weekend_opened"
                        :binary="true"
                        :class="form.invalid('is_weekend_opened') ? 'p-invalid' : ''"
                    />
                    <label for="is_weekend_opened" class="ml-2"> Weekend ouvrable </label>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <label for="tags" class="text-dark-slate-600 uppercase tracking-widest">Visible pour</label>
                <MultiSelect
                    v-model="form.visible_for"
                    :options="categoriesOptions"
                    display="chip"
                    optionLabel="name"
                    empty-message="Aucune categories"
                    placeholder="Sélectionnez un ou plusieurs catégories"
                    :invalid="form.invalid('visible_for')"
                    @select="form.validate('visible_for')"
                />
                <small v-if="form.invalid('visible_for')" class="text-red-500"> {{ form.errors.visible_for }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="excluded_restaurants" class="text-dark-slate-600 uppercase tracking-widest">Masquer pour</label>
                <MultiSelect
                    v-model="form.excluded_restaurants"
                    :options="restaurantsOptions"
                    display="chip"
                    optionLabel="name"
                    empty-message="Aucun restaurants"
                    placeholder="Sélectionnez un ou plusieurs restaurant(s)"
                    label
                    :invalid="form.invalid('excluded_restaurants')"
                    @select="form.validate('excluded_restaurants')"
                />
                <small v-if="form.invalid('excluded_restaurants')" class="text-red-500"> {{ form.errors.excluded_restaurants }} </small>
            </div>

            <div>
                <Button @click="handleSubmit">
                    {{ supplier?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref, watch} from "vue";

    import Breadcrumb from "primevue/breadcrumb";
    import InputText from "primevue/inputtext";
    import MultiSelect from "primevue/multiselect";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";
    import Checkbox from "primevue/checkbox";
    import Editor from "primevue/editor";
    import "quill/dist/quill.snow.css";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useRestaurantsStore, useConfigsStore, useSuppliersStore} from "../../../store";
    import {RestaurantType} from "../../../types/restaurant";
    import SUPPLIER_STATUS from "../../../enums/supplier-status"
    import RESTAURANT_TYPES from "../../../enums/restaurant-types"

    const router = useRouter();
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Fournisseurs' , route: {name: 'suppliers'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (supplier?.value?.id) {
            items.push({label: supplier?.value?.name || 'N/A'})
        }

        items.push({label: supplier?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const suppliersStore = useSuppliersStore();
    const suppliersRefs = storeToRefs(suppliersStore);
    const supplier = suppliersRefs.single;
    const isFetchPending = suppliersRefs.isFetchSinglePending;

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const isFetchRestaurantsPending = restaurantsRefs.isFetchListPending;
    const restaurantsOptions = ref<{ name: string, id: number }[]>([]);

    const categoriesOptions = ref<{ name: string, id: number }[]>([]);

    categoriesOptions.value = Object.entries(RESTAURANT_TYPES.labels).map( type => {
        return {id: type[0], name: type[1]}
    });

    onBeforeMount(async () => {
        if (route.params.id) {
            await suppliersStore.getItem({id: String(route.params.id), includes: ['excludedRestaurants']})
        } else {
            supplier.value = {
                name: null,
                note: {"fr": null},
                address: null,
                postal_code: null,
                city: null,
                vat_number: null,
                contact_person: null,
                contact_email: null,
                contact_phone: null,
                visible_for: [],
                excluded_restaurants: [],
                status: null,
                is_weekend_opened: null
            }
        }

        await restaurantsStore.getItems({list: true});
        restaurantsOptions.value = restaurantsRefs.list.value.map((restaurant: RestaurantType) => {
            return {name: restaurant?.name, id: restaurant.id}
        });

        form.setData({
            ...supplier?.value,
            note: supplier?.value?.translatable?.note?.fr ? supplier?.value?.translatable?.note : {fr: ''},
            visible_for: supplier.value.visible_for ? categoriesOptions.value.filter((category:any) => Object.values(supplier.value.visible_for).includes(String(category.id))) : [],
            excluded_restaurants: restaurantsOptions.value.filter((restaurant:any) => supplier.value.excluded_restaurants.data?.map( (restaurant:any) => restaurant.id).includes(Number(restaurant.id))),
            status: supplier?.value?.status === 1
        })
    })

    const form = useForm(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/suppliers/' + route.params.id
            : '/api/suppliers',
        {
            name: null,
            note: {"fr": null},
            address: null,
            postal_code: null,
            city: null,
            vat_number: null,
            contact_person: null,
            contact_email: null,
            contact_phone: null,
            is_weekend_opened: null,
            visible_for: [],
            excluded_restaurants: [],
            status: null
        }
    );

    const handleSubmit = () => {
        suppliersRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                excluded_restaurants: untouchedData?.excluded_restaurants?.map( (restaurant:RestaurantType) => restaurant.id ) || [],
                visible_for: untouchedData?.visible_for?.map( (category) => category.id ) || [],
                status: untouchedData?.status ? Number(SUPPLIER_STATUS.values.ACTIVE.description) : Number(SUPPLIER_STATUS.values.INACTIVE.description),
                is_weekend_opened: untouchedData?.is_weekend_opened ? 1 : 0,
            })
            .submit()
            .then(() => {
                if (route.params.form_id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le fournisseur a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le fournisseur a bien été créé'})
                }

                return router.push({name: 'suppliers'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                suppliersRefs.isSavePending.value = false;
            })
    }

    const editorRef = ref()

    watch(editorRef, (editor) => {
        if (!editor) return
        // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
        editor.renderValue = function renderValue(value) {
            if (this.quill) {
                if (value) {
                    const delta = this.quill.clipboard.convert({ html: value })
                    this.quill.setContents(delta, 'silent')
                } else {
                    this.quill.setText('')
                }
            }
        }.bind(editor) // Bind needed for production build
    })
</script>
