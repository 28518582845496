<template>
    <div class="flex flex-col gap-2 mb-4">
        <label for="credit_note_number" class="text-dark-slate-600 uppercase tracking-widest">N° de note de crédit</label>
        <InputText
            id="credit_note_number"
            name="credit_note_number"
            v-model="form.credit_note_number"
            :invalid="form.invalid('credit_note_number')"
            @change="form.validate('credit_note_number')"
        />
        <small v-if="form.invalid('credit_note_number')" class="text-red-500"> {{ form.errors.credit_note_number }} </small>
    </div>

    <Button @click.prevent="handleSubmit" label="Valider" />
</template>

<script lang="ts" setup>
    import {inject, onMounted, ref} from "vue";
    import InputText from "primevue/inputtext";
    import {useForm} from "laravel-precognition-vue";
    import {useRoute, useRouter} from "vue-router";
    import {useConfigsStore} from "../../store";
    import Button from "primevue/button";

    import type {DynamicDialogInstance} from "primevue/dynamicdialogoptions";
    import {Ref} from "vue/dist/vue";

    const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef')
    const order = ref();
    const router = useRouter()
    const isSavePending = ref(false);

    const configsStore = useConfigsStore();

    const url = ref();

    onMounted(async () => {
        const params = dialogRef?.value?.data;

        url.value = '/api/orders/' + params.order.id + '/litigation'
    })

    const form = useForm('post',() => url.value,
        {
            credit_note_number: null
        }
    );

    const handleSubmit = () => {
        isSavePending.value = true;

        form
            .submit()
            .then(() => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le order a bien été modifié'})

                dialogRef.value.close()
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }
            })
            .finally(() => {
                isSavePending.value = false;
            })
    }
</script>
