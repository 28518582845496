import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/products/List.vue"
import Save from "../views/backoffice/products/Save.vue"
import Stock from "../views/backoffice/products/Stock.vue"

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/products',
        children: [
            {
                path: '',
                name: 'products',
                component: List,
            },
            {
                path: '/admin/products/:id?/save',
                name: 'product-save',
                component: Save,
                meta: { authorize: [1] }
            },
            {
                path: '/admin/products/:id/stock',
                name: 'product-stock',
                component: Stock,
            }
        ]
    },
]

export default module;
