export default {
    values: Object.freeze ({
        ACTIVE: Symbol(1),
        ARCHIVED: Symbol(2),
    }),
    labels: {
        1: 'Actifs',
        2: 'Archivés',
    }
}
