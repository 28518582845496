<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Type de réponses
                </h1>

                <Button @click="handleCreateOrEditResponse">+ Ajouter un type de réponse</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            :value="responses"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" :sortable="true" #body="slotProps">
                {{ slotProps.data.translatable.title[locale] }}
            </Column>

            <Column key="actions" header="Action(s)" style="width: 25%">
                <template #body="slotProps">
                    <div class="flex items-center gap-2">
                        <Button
                            link
                            label="Modifier"
                            @click.prevent="handleCreateOrEditResponse(slotProps.data)"
                            v-tooltip="{value: 'Éditer', class: 'tooltip-normal'}">
                            <i class="pi pi-pencil"></i>
                        </Button>
                        <Button
                            link
                            label="Supprimer"
                            @click.prevent="handleDeleteResponse(slotProps.data)"
                            v-tooltip.top="{value: 'Supprimer', class: 'tooltip-normal'}">
                            <i class="pi pi-trash"></i>
                        </Button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue"

    import DataTable from "primevue/datatable"
    import Column from "primevue/column"
    import Button from "primevue/button"

    import {useRouter} from "vue-router"
    import {useConfirm} from "primevue/useconfirm"

    import {storeToRefs} from "pinia";
    import {useConfigsStore, useResponsesStore} from "../../../store"

    import {ResponseType} from "../../../types/response"
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";

    import COMPLAINT_TYPES from "../../../enums/complaint-types"

    import useDatatableActions from "../../../composables/datatable-actions"

    const router = useRouter()
    const confirm = useConfirm()

    const configsStore = useConfigsStore()
    const {locale} = storeToRefs(configsStore)

    const responsesStore = useResponsesStore();
    const responsesRefs = storeToRefs(responsesStore);
    const pagination = responsesRefs.pagination;
    const isFetchListPending = responsesRefs.isFetchListPending;
    const responses = responsesRefs.list;

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });
    const breadCrumbsItems = computed(() => [{label: 'Type de réponses'}]);

    const queryParams = ref({
        search: {
            search: null,
        },
        includes: [],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch} = useDatatableActions(pagination, queryParams, responsesStore.getItems)

    onBeforeMount(() => {
        responsesStore.getItems();
    })

    const handleDeleteResponse = (response:ResponseType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {responsesStore.deleteItem(response.id)}
        })
    }

    const handleCreateOrEditResponse = (response:ResponseType) => {
        responsesRefs.single.value = null
        router.push({name: 'response-save',  params: (response ? {id: response.id} : {}) });
    }
</script>
