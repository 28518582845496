import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {StorageUnitType} from "../types/storage-unit";
import {BaseStoreType} from "../types/base-store";

export const useStorageUnitsStore = defineStore('storageUnits', () => {
    const resourceName = 'storageUnits';
    const baseUrl = '/api/storage_units';

    const baseStore:BaseStoreType = new BaseStore<StorageUnitType>({
        resourceName,
        baseUrl,
        useStore: useStorageUnitsStore()
    });

    return {
        ...baseStore,
    };
});
