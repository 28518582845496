<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between items-end">
            <div>
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Inventaire
                </h1>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <div class="flex flex-col gap-4">
            <DataTable
                scrollable scrollHeight="100vh"
                :value="inventory.products"
                :loading="isFetchSinglePending"
                :rows="1000"
                :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
                @sort="handleSortChange($event)"
                paginator showGridlines stripedRows removableSort
                currentPageReportTemplate="{currentPage}/{totalPages}"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                :pt="{
                    header: {class: 'text-surface-600 font-bold text-sm'},
                    mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                    loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
                }"
            >
                <template #empty>
                    <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
                </template>

                <Column header="Nom" field="name" :sortable="true" sort-field="name"></Column>

                <Column header="Fournisseur" field="supplier" :sortable="true" sort-field="supplier" #body="slotProps">
                    {{ slotProps.data.supplier.name }}
                </Column>

                <Column header="Prix" field="price" :sortable="true" sort-field="price" #body="slotProps">
                    {{ slotProps.data.price }} €
                </Column>

                <Column header="Tags" field="tags" :sortable="false" #body="slotProps">
                    <template v-for="tag in slotProps.data.tags.slice(0, 2)">
                        <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                    </template>
                    <template v-if="slotProps.data.tags.length > 2">
                        (+{{ slotProps.data.tags.length - 2 }})
                    </template>
                </Column>

                <Column header="Conditionnement" field="conditioning" :sortable="true" #body="slotProps">
                    {{ slotProps.data.conditioning_quantity }}
                    {{ slotProps.data.conditioning.label }}
                </Column>

                <Column header="Stock" #body="slotProps">
                    <InputNumber :minFractionDigits="2" :maxFractionDigits="3" placeholder="Quantité en stock" v-model="slotProps.data.quantity"/>
                </Column>
            </DataTable>

            <div>
                <Button @click="handleSubmit" label="Valider" />
            </div>
        </div>
    </div>

</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";
import {useConfigsStore, useInventoriesStore} from "../../../store";
import {storeToRefs} from "pinia";

import Button from "primevue/button"
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import ProgressSpinner from "primevue/progressspinner";
import InputNumber from "primevue/inputnumber";
import {useRoute, useRouter} from "vue-router";
import Tag from "primevue/tag";
import {ProductType} from "../../../types/product";
import {useForm} from "laravel-precognition-vue";
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import Search from "../../../components/partials/backoffice/search.vue";

const breadCrumbsFirstItem = computed( () => {
    return { label: 'Restaurants' , route: {name: 'restaurants'} }
})

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    if (inventory?.value?.restaurant?.id) {
        items.push({label: inventory?.value?.restaurant?.value?.name || 'N/A'})
    }

    items.push({label: 'Inventaire'})

    items.push({label: 'Modifier'})

    return items;
});

const route = useRoute();
const router = useRouter()

const configsStore = useConfigsStore();

const inventoriesStore = useInventoriesStore();
const inventoriesRefs = storeToRefs(inventoriesStore);
const inventory = inventoriesRefs.single;
const isFetchSinglePending = inventoriesRefs.isFetchSinglePending;

onBeforeMount(async () => {
    await inventoriesStore.getItem({id: route.params.id})
});

const handleGlobalSearch = async (value:string) => {
    await inventoriesStore.getItem({id: route.params.id})
}

const handleSortChange = (event:any) => {

}

const form = useForm('post', '/api/inventories',
    {
        'restaurant_id': null,
        'items': [],
    }
);

const handleSubmit = () => {
    form
        .setData({
            restaurant_id: route.params.id,
            items: inventory.value.products.value.map( (product:ProductType) => {
                return {
                    product_id: Number(product.id),
                    quantity: Number(product.quantity).toFixed(2)
                }
            })
        })
        .submit()
        .then(() => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'l\'inventaire a bien été effectué'})

            return router.push({name: 'inventories'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }
        })
        .finally(() => {
            inventoriesRefs.isSavePending.value = false;
        })
}
</script>
