<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ survey?.id ? 'Modifier une enquête' : 'Ajouter une enquête' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">

            <div class="flex flex-col gap-2">
                <label for="title" class="text-dark-slate-600 uppercase tracking-widest">Titre</label>
                <InputText
                    id="title"
                    type="text"
                    name="title"
                    v-model="form.title[locale]"
                    :invalid="form.invalid('title')"
                    @change="form.validate('title')"
                />
                <small v-if="form.invalid('title')" class="text-red-500"> {{ form.errors.title }} </small>
            </div>

            <div class="flex gap-2 flex-col mb-6">
                <label for="description" class="text-surface-400 tracking-widest">Description</label>
                <Editor
                    id="description"
                    name="description"
                    class="w-full"
                    v-model="form.description[locale]"
                    ref="editorRef"
                    :invalid="form.invalid('description')"
                    @change="form.validate('description')"
                />
                <small v-if="form.invalid('description')" class="text-red-500"> {{ form.errors.description }} </small>
            </div>
            <!--// add status selector (active, archived)-->
            <div>
                <Button @click="handleSubmit">
                    {{ survey?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref, watch} from "vue";

    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useSurveysStore, useConfigsStore} from "../../../store";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Editor from "primevue/editor";

    const router = useRouter();
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Enquêtes' , route: {name: 'surveys'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (survey?.value?.id) { items.push({label: survey?.value?.title || 'N/A'}) }

        items.push({label: survey?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const survey = surveysRefs.single;
    const isFetchPending = surveysRefs.isFetchSinglePending;

    onBeforeMount(async () => {
        if (route.params.id) {
            await surveysStore.getItem({id: String(route.params.id), includes: ['users']})
        } else {
            survey.value = {
                title: {"fr": null},
                description: {"fr": null},
            }
        }

        form.setData({
            ...survey?.value,
            title: survey?.value?.translatable?.title || {fr: ''},
            description: survey?.value?.translatable?.description || {fr: ''},
        })
    })

    const form = useForm(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/surveys/' + route.params.id
            : '/api/surveys',
        {
            title: {"fr": null},
            description: {"fr": null},
        }
    );

    const handleSubmit = () => {
        surveysRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .submit()
            .then(() => {
                if (route.params.form_id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'L\'audit a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'L\'audit a bien été créé'})
                }

                return router.push({name: 'surveys'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                surveysRefs.isSavePending.value = false;
            })
    }

    const editorRef = ref()

    watch(editorRef, (editor) => {
        if (!editor) return
        // Hack needed for Quill v2: https://github.com/primefaces/primevue/issues/5606#issuecomment-2093536386
        editor.renderValue = function renderValue(value) {
            if (this.quill) {
                if (value) {
                    const delta = this.quill.clipboard.convert({ html: value })
                    this.quill.setContents(delta, 'silent')
                } else {
                    this.quill.setText('')
                }
            }
        }.bind(editor) // Bind needed for production build
    })
</script>
