export default {
    values: Object.freeze ({
        DRAFT: Symbol(1),
        PLANNED: Symbol(2),
        ORDERED: Symbol(3),
        RECEIVED: Symbol(4),
        PAID: Symbol(5),
        LITIGATION: Symbol(6),
        ERROR: Symbol(7),
    }),
    labels: {
        1: 'Brouillon',
        2: 'Planifiée',
        3: 'Commandée',
        4: 'Reçue',
        5: 'Facturé',
        6: 'Réclamation',
        7: 'Erreur envoi api'
    }
}
