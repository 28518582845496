<template>
    <div class="flex flex-col gap-2 mb-6">
        <label for="title" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
        <InputText
            id="title"
            type="text"
            label="label"
            v-model="form.title[locale]"
            :invalid="form.invalid('title')"
            @change="form.validate('title')"
        />
        <small v-if="form.invalid('title')" class="text-red-500"> {{ form.errors.title }} </small>
    </div>

    <Button @click.prevent="handleSubmit" label="Valider" />
</template>

<script lang="ts" setup>
import {inject, ref} from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {useForm} from "laravel-precognition-vue";
import {useConfigsStore} from "../../store";
import {storeToRefs} from "pinia";

const dialogRef = inject('dialogRef');
const isSavePending = ref(false);

const configsStore = useConfigsStore();
const { locale } = storeToRefs(configsStore)

const params = ref(dialogRef.value.data);

const form = useForm(
    params?.value?.section?.id ? 'put' :'post',
    () => params?.value?.section?.id ?
        '/api/surveys/sections/' + params?.value?.section?.id :
        '/api/surveys/sections',
    {
        title: {fr: ''},
        survey_id: params.value.survey.id
    }
);

if(params.value.section.id) {
    form.setData({
        title: params.value.item.translatable.title,
    })
}

const handleSubmit = () => {
    isSavePending.value = true;

    const untouchedData = form.data();

    console.warn(params.value.survey.id);

    form
        .setData({
            survey_id: params?.value?.survey?.id,
        })
        .submit()
        .then(() => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La section a bien été ajoutée'})

            dialogRef.value.close()
        })
        .catch(error => {
            form.setData({...untouchedData});

            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }
        })
        .finally(() => {
            isSavePending.value = false;
        })
}
</script>
