import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/surveys/List.vue"
import Save from "../views/backoffice/surveys/Save.vue"
import Show from "../views/backoffice/surveys/Show.vue"
import Audits from "../views/backoffice/surveys/Audits.vue"

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/surveys',
        children: [
            {
                path: '',
                name: 'surveys',
                component: List,
            },
            {
                path: '/admin/surveys/:id?/save',
                name: 'survey-save',
                component: Save,
            },
            {
                path: '/admin/surveys/:id?/show',
                name: 'survey-show',
                component: Show,
            },
            {
                path: '/admin/surveys/:id?/audits',
                name: 'survey-audits',
                component: Audits,
            }
        ]
    }
]

export default module;
