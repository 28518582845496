<template>
    <Breadcrumb :home="breadCrumbsFirstItem" />

    <div class="mx-20 mt-4">
        <h2 class="ml-2 mb-3 text-3xl text-dark-slate-800 font-normal">Paramètre(s)</h2>
    </div>

    <Card class="mx-20 my-5 shadow-2xl shadow-black">
        <template #content>
            <div>
                <div class="mx-4 flex items-center mb-2" v-for="setting in settings">
                    <label :for="setting.key.toLowerCase()" class="mr-2 w-1/3">{{ setting.key }}</label>
                    <InputText
                        :id="setting.key.toLowerCase()"
                        type="text"
                        :name="setting.key.toLowerCase()"
                        v-model="setting.value"
                        class="w-2/3"
                        @blur="handleSave(setting)"
                    />
                </div>
            </div>
        </template>
    </Card>
</template>

<script lang="ts" setup>
    import Breadcrumb from "primevue/breadcrumb";
    import Card from "primevue/card";
    import InputText from "primevue/inputtext";
    import {computed, onBeforeMount, ref} from "vue";
    import {SettingType} from "../../types/setting";
    import {storeToRefs} from "pinia";
    import {useForm} from "laravel-precognition-vue";
    import { useSettingsStore} from "../../../store";
    import {useToast} from "primevue/usetoast";

    const toast = useToast();

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Paramètre(s)' , to: '/admin/settings' }
    })

    const settingsStore = useSettingsStore();
    const settingsRefs = storeToRefs(settingsStore);
    const settings = ref(settingsRefs.list);

    onBeforeMount( async () => {
        await settingsStore.getItems({'per_page': 100});
    })

    const handleSave = (setting: SettingType) => {
        const form = useForm(
            'patch',
            '/api/settings/' + setting.id,
            {
                key: null,
                value: null,
            }
        );

        form
            .setData({...setting})
            .submit()
            .then( () => {
                toast.add({severity: 'success', summary: 'Bravo', detail: 'Le paramètre a bien été modifié', life: 8000});
            })
            .catch(error => {
                if(error.response.status === 422) {
                    toast.add({'severity': 'warn', 'summary': 'Attention', 'detail': 'Il y a des erreurs dans le formulaire', life: 8000})
                }
                if(error.response.status === 500) {
                    toast.add({'severity': 'error', 'summary': 'Attention', 'detail': 'Il y a une erreur serveur', life: 8000})
                }
            })
            .finally( () => {
                settingsRefs.isSavePending.value = false;
            })
    }
</script>
