export default {
    values: Object.freeze ({
        ORDER: Symbol(1),
        CONVENIENCE: Symbol(2),
    }),
    labels: {
        1: 'Commande',
        2: 'Dépanne',
    }
}

