import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {InventoryType} from "../types/inventory";
import {BaseStoreType} from "../types/base-store";

export const useInventoriesStore = defineStore('inventories', () => {
    const resourceName = 'inventories';
    const baseUrl = '/api/inventories';

    const baseStore:BaseStoreType = new BaseStore<InventoryType>({
        resourceName,
        baseUrl,
        useStore: useInventoriesStore()
    });

    return {
        ...baseStore,
    };
});
