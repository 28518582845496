import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {PlanningType} from "../types/planning";
import {BaseStoreType} from "../types/base-store";

export const usePlanningsStore = defineStore('plannings', () => {
    const resourceName = 'plannings';
    const baseUrl = '/api/supplier-plannings';

    const baseStore:BaseStoreType = new BaseStore<PlanningType>({
        resourceName,
        baseUrl,
        useStore: usePlanningsStore()
    });

    return {
        ...baseStore,
    };
});
