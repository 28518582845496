<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex items-center">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Commandes
                </h1>

                <SplitButton
                    v-if="restaurantsOptions.length > 0"
                    label="+ Effectuer une commande"
                    @click.prevent="handleCreateOrder(restaurantsOptions[0].id)"
                    :model="restaurantsOptions"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                />

                <div v-else class="text-lg text-red-500 font-bold">
                    Vous n'avez accès à aucun restaurant
                </div>
            </div>

            <Search
                @search="handleSearch"
                v-model="filters['global'].value"
                stateKey="dt-state-orders"
            />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="orders.filter( (order:OrderType) => queryParams.search.status !== null ? order.status === queryParams.search.status : true)"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            stateStorage="session"
            stateKey="dt-state-orders"
            :filters="filters"
            :globalFilterFields="['date', 'restaurant.name', 'supplier.name', 'ref', 'price', 'status', 'invoice_number']"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <template #header>
                <div class="flex mb-4">
                    <Button
                        link
                        @click.prevent="handleChangeStatus(null)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === null ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Toutes
                    </Button>
                    <template v-for="status in ORDER_STATUS.values">
                        <Button
                            link
                            @click.prevent="handleChangeStatus(Number(status.description))"
                            class="py-3 pb-4 px-4 rounded-none outline-none"
                            :pt="{root: {class: Number(queryParams.search.status) === Number(status.description) ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                        >
                            {{ ORDER_STATUS.labels[status.description] }}
                        </Button>
                    </template>
                </div>
            </template>

            <Column header="Date" :sortable="true" field="created_at" #body="slotProps">
                {{ new Intl.DateTimeFormat('fr-BE', {dateStyle: 'short', timeStyle: 'short',}).format(new Date(slotProps.data.created_at)) }}
            </Column>

            <Column v-if="restaurantsOptions.length > 1 " header="Restaurant" :sortable="true" field="restaurant.name" #body="slotProps">
                {{ slotProps.data.restaurant?.name }}
            </Column>

            <Column header="Fournisseur" :sortable="true" field="supplier.name" #body="slotProps">
                {{ slotProps.data.supplier?.name }}
            </Column>

            <Column header="Ref" :sortable="true" field="ref" #body="slotProps">
                {{ slotProps.data.reference }}
            </Column>

            <Column header="Prix" :sortable="true" field="price" #body="slotProps">
                {{ slotProps.data.total_amount.toFixed(2) }} €
            </Column>

            <Column header="Statut" :sortable="true" field="status" #body="slotProps">
                <template v-if="slotProps.data.status === 1">
                    <Tag :value="status[1]" severity="secondary" class="bg-surface-400"/>
                </template>
                <template v-if="slotProps.data.status === 2">
                    <Tag :value="status[2]" severity="info"/>
                </template>
                <template v-if="slotProps.data.status === 3">
                    <Tag :value="status[3]" severity="info" />
                </template>
                <template v-if="slotProps.data.status === 4">
                    <Tag :value="status[4]" severity="success" />
                </template>
                <template v-if="slotProps.data.status === 5">
                    <Tag :value="status[5]" class="bg-[#256556]" />
                </template>
                <template v-if="slotProps.data.status === 6">
                    <Tag :value="status[6]" severity="danger" />
                </template>
                <template v-if="slotProps.data.status === 7">
                    <Tag :value="status[7]" severity="danger" />
                </template>
            </Column>

            <Column header="N° de facture" :sortable="true" field="invoice_number" />

            <Column key="actions" header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center gap-2">
                        <Button
                            link
                            label="Voir"
                            @click.prevent="handleShowOrder(slotProps.data)"
                            v-tooltip="'Voir'">
                            <i class="pi pi-eye"></i>
                        </Button>
                        <template v-if="slotProps.data.status === 3">
                            <Button
                                link
                                label="Réceptionner"
                                @click.prevent="handleReceiveOrder(slotProps.data)"
                                v-tooltip="'Réceptionner'">
                                <i class="pi pi-box"></i>
                            </Button>
                        </template>
                        <template v-if="slotProps.data.status === 4">
                            <Button
                                link
                                label="Réconcilier"
                                @click.prevent="handleReconcileOrder(slotProps.data)"
                                v-tooltip="'Réconcilier'">
                                <i class="pi pi-clipboard"></i>
                            </Button>
                        </template>
                        <template v-if="slotProps.data.status === 1 || slotProps.data.status === 2 || slotProps.data.status === 7">
                            <Button
                                link
                                label="Modifier"
                                @click.prevent="handleCreateOrEditOrder(slotProps.data)"
                                v-tooltip="'Éditer'">
                                <i class="pi pi-pencil"></i>
                            </Button>
                        </template>
                        <template v-if="slotProps.data.status === 1">
                            <Button
                                link
                                label="Supprimer"
                                @click.prevent="handleDeleteOrder(slotProps.data)"
                                v-tooltip="'Supprimer'">
                                <i class="pi pi-trash"></i>
                            </Button>
                        </template>
                        <template v-if="slotProps.data.status === 6">
                            <Button
                                link
                                label="Modifier"
                                @click.prevent="handleCreditNoteNumber(slotProps.data)"
                                v-tooltip="'Ajouter note de crédit'">
                                <i class="pi pi-check"></i>
                            </Button>
                        </template>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import SplitButton from "primevue/splitbutton";
    import Tag from "primevue/tag";

    import { useRouter} from "vue-router";
    import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
    import {useOrdersStore, useRestaurantsStore} from "../../../store";
    import {storeToRefs} from "pinia";

    import {OrderType} from "../../../types/order";
    import {RestaurantType} from "../../../types/restaurant";

    import ORDER_STATUS from "../../../enums/order-status"
    import ORDER_TYPE from "../../../enums/order-type";

    import useDatatableActions from "../../../composables/datatable-actions";

    import AddCreditNoteNumber from "../../../components/dialogs/AddCreditNoteNumber.vue";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue"

    import {useConfirm} from "primevue/useconfirm";
    import {useDialog} from "primevue/usedialog";

    const status = ORDER_STATUS.labels;

    const router = useRouter()
    const dialog = useDialog()
    const confirm = useConfirm()

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const pagination = ordersRefs.pagination;
    const isFetchListPending = ordersRefs.isFetchListPending;
    const orders = ordersRefs.list;

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const restaurants = restaurantsRefs.list;
    const restaurantsOptions = ref([]);

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

    const breadCrumbsItems = computed(() => [{label: 'Commandes'}]);

    const sessionFilters = ref(sessionStorage['dt-state-orders'] ? JSON.parse(sessionStorage['dt-state-orders']) : null);
    const filters = ref({ global: { value: null } } );

    const queryParams = ref({
        search: {
            type: ORDER_TYPE.values.ORDER.description,
            search: sessionFilters?.value?.filters?.global?.value,
            status: null
        },
        includes: ['supplier', 'restaurant'],
        sort_by: sessionFilters?.value?.sortField,
        desc: sessionFilters?.value?.sortOrder === 1,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch, handleChangeStatus} = useDatatableActions(pagination, queryParams, ordersStore.getItems)

    const handleSearch = (event:string) => {
        filters.value.global.value = event;
        handleGlobalSearch(event)
    }

    onBeforeMount(async () => {
        if(sessionFilters.value) {
            filters.value.global.value = sessionFilters.value.filters.global.value;
        }

        await ordersStore.getItems({...queryParams.value, search: Object.fromEntries(Object.entries(queryParams.value.search).filter(([_, v]) => v != null))});
        await restaurantsStore.getItems();

        restaurants.value.map( ({id, name}: RestaurantType) => {
            restaurantsOptions.value.push({id: id, label: name, command: () => { handleCreateOrder(id)} })
        })
    })

    const handleCreateOrEditOrder = (order:OrderType) => {
        ordersRefs.single.value = null
        router.push({name: 'order-save',  params: (order ? {id: order.id} : {}) });
    }

    const handleCreateOrder = (id:number) => {
        let restaurant = restaurants.value.find( (restaurant:RestaurantType) => restaurant.id === id)
        ordersRefs.single.value = null;
        ordersRefs.single.value = { restaurant_id: restaurant.id, restaurant: restaurant };
        router.push({ name: 'order-create-supplier' });
    }

    const handleReceiveOrder = (order:OrderType) => {
        router.push({ name: 'order-receive', params: { id: order.id} });
    }

    const handleShowOrder = (order:OrderType) => {
        router.push({ name: 'order-show', params: { id: order.id} });
    }

    const handleReconcileOrder = (order:OrderType) => {
        router.push({ name: 'order-reconcile', params: { id: order.id} });
    }


    const handleCreditNoteNumber = (order:OrderType) => {
        dialog.open(AddCreditNoteNumber, {
            props: {
                header: 'Note de crédit',
                modal: true
            },
            data: {
                order: order
            },
            onClose: () => {
                ordersStore.getItems({search: {type: ORDER_TYPE.values.ORDER.description}, includes: ['supplier', 'restaurant']});
            }
        })
    }

    const handleDeleteOrder = (order:OrderType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {ordersStore.deleteItem(order.id)}
        })
    }

    onBeforeUnmount( async () => {
        orders.value = [];
    })
</script>
