<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        class="mb-6"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Sélectionnez le fournisseur
                </h1>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            :value="suppliers"
            :loading="isFetchSuppliersPending"
            :rows="1000"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="suppliers.length"
            @page="handlePageChange()"
            @sort="handleSortChange()"
            @row-click="handleSelectSupplier($event)"
            lazy paginator showGridlines stripedRows removableSort
            selectionMode="single"
            paginatorTemplate=""
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <Column header="Nom" field="name" :sortable="true" />
            <Column header="Jour(s) de commande" field="delivery_days" #body="slotProps">
                {{ slotProps.data.plannings.data.map( (planning:any) => planning.weekdays.data.map( (day:any) => day.label ) ).flat().join(', ') }}
            </Column>
            <Column header="Nbre de référence produits" field="products_count" :sortable="true" />

        </DataTable>
    </div>
</template>
<script setup lang="ts">
import Breadcrumb from "primevue/breadcrumb";
import {computed, onBeforeMount, ref} from "vue";
import {useComplaintsStore, useRestaurantsStore} from "../../../../store";
import {storeToRefs} from "pinia";

import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import {useRouter} from "vue-router";
import useDebounce from "../../../../composables/debounce";

const breadCrumbsFirstItem = computed( () => { return { label: 'Pertes' , route: {name: 'losses' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: complaint?.value?.restaurant.name})

    items.push({label: 'Choix Fournisseur'})

    return items;
});

const router = useRouter();

const restaurantsStore = useRestaurantsStore();
const restaurantsRefs = storeToRefs(restaurantsStore);
const isFetchSuppliersPending = restaurantsRefs.isFetchSuppliersPending;
const suppliers = restaurantsRefs.suppliers;

const complaintsStore = useComplaintsStore();
const complaintsRefs = storeToRefs(complaintsStore);
const complaint = complaintsRefs.single

onBeforeMount(() => {
    if(!complaint?.value?.restaurant_id) {
        router.push({name: 'losses'})
    }else {
        restaurantsStore.getSuppliers({id: complaint?.value?.restaurant_id, includes: []});
    }
});

const handleGlobalFilterChange = (value:string) => {
    complaintsStore.getItems({search: {search: value}})
}

const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
    handleGlobalFilterChange(event);
}, 1000);

const handlePageChange = () => {

}

const handleSortChange = () => {

}

const handleSelectSupplier = (event:any) => {
    complaint.value.supplier_id = event.data.id;
    complaint.value.supplier = event.data;

    router.push({ name: 'loss-create-products' });
}
</script>
