<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex items-center">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Retours
                </h1>

                <SplitButton
                    v-if="restaurantsOptions.length > 0"
                    label="+ Effectuer un retour"
                    @click.prevent="handleCreateReturn(restaurantsOptions[0].id)"
                    :model="restaurantsOptions"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                />

                <div v-else class="text-lg text-red-500 font-bold">
                    Vous n'avez accès à aucun restaurant
                </div>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="complaints.filter( (complaint:ComplaintType) => queryParams.search.status > 0 ? complaint.status === queryParams.search.status : true)"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <template #header>
                <div class="flex mb-4">
                    <Button
                        link
                        @click.prevent="handleChangeStatus(null)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: queryParams.search.status === null ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Toutes
                    </Button>
                    <template v-for="status in COMPLAINT_STATUS.values">
                        <Button
                            link
                            @click.prevent="handleChangeStatus(Number(status.description))"
                            class="py-3 pb-4 px-4 rounded-none outline-none"
                            :pt="{root: {class: Number(queryParams.search.status) === Number(status.description) ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                        >
                            {{ COMPLAINT_STATUS.labels[status.description] }}
                        </Button>
                    </template>
                </div>
            </template>

            <Column header="Date" field="created_at" :sortable="true" #body="slotProps">
                {{ new Intl.DateTimeFormat('fr-BE').format(new Date(slotProps.data.created_at)) }}
            </Column>

            <Column header="Fournisseur" field="supplier.name" :sortable="true" #body="slotProps">
                {{ slotProps.data.supplier.name }}
            </Column>

            <Column header="Ref" #body="slotProps">
                {{ slotProps.data.order.reference }}
            </Column>

            <Column header="Statut" field="status" #body="slotProps">
                <template v-if="slotProps.data.status === 1">
                    <Tag :value="COMPLAINT_STATUS.labels[1]" severity="info"/>
                </template>
                <template v-if="slotProps.data.status === 2">
                    <Tag :value="COMPLAINT_STATUS.labels[2]" class="bg-surface-400"/>
                </template>
            </Column>

            <Column header="Action(s)" #body="slotProps" class="flex items-center gap-2">
                <Button
                    link
                    label="Voir"
                    @click.prevent="handleShowReturn(slotProps.data)"
                    v-tooltip="'Voir'">
                    <i class="pi pi-eye" />
                </Button>
                <Button
                    link
                    label="Supprimer"
                    @click.prevent="handleDeleteReturn(slotProps.data)"
                    v-tooltip="'Supprimer'">
                    <i class="pi pi-trash" />
                </Button>
                <Button
                    v-if="Number(slotProps.data.status) === Number(COMPLAINT_STATUS.values.OPEN.description)"
                    link
                    label="Cloturer"
                    @click.prevent="handleClose(slotProps.data)"
                    v-tooltip="'Cloturer'">
                    <i class="pi pi-lock" />
                </Button>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import SplitButton from "primevue/splitbutton";

    import {useRouter} from "vue-router";
    import {useConfirm} from "primevue/useconfirm";
    import {computed, onBeforeMount, onBeforeUnmount, ref} from "vue";
    import {useComplaintsStore, useRestaurantsStore} from "../../../store";
    import {storeToRefs} from "pinia";

    import {RestaurantType} from "../../../types/restaurant";
    import {ComplaintType} from "../../../types/complaint";

    import COMPLAINT_TYPES from "../../../enums/complaint-types"
    import COMPLAINT_STATUS from "../../../enums/complaint-status"
    import Tag from "primevue/tag";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";
    import useDatatableActions from "../../../composables/datatable-actions";

    const router = useRouter()
    const confirm = useConfirm()

    const complaintsStore = useComplaintsStore();
    const complaintsRefs = storeToRefs(complaintsStore);
    const pagination = complaintsRefs.pagination;
    const isFetchListPending = complaintsRefs.isFetchListPending;
    const complaints = complaintsRefs.list;

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const restaurants = restaurantsRefs.list;
    const restaurantsOptions = ref<{id: number, label: string, command: Function}[]>([]);

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

    const breadCrumbsItems = computed(() => [{label: 'Retours'}]);

    const queryParams = ref({
        search: {
            type: COMPLAINT_TYPES.values.RETURN.description,
            search: null,
            status: null
        },
        includes: ['order', 'supplier'],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch, handleChangeStatus} = useDatatableActions(pagination, queryParams, complaintsStore.getItems)

    onBeforeMount(async () => {
        await complaintsStore.getItems({...queryParams.value, search: Object.fromEntries(Object.entries(queryParams.value.search).filter(([_, v]) => v != null))});
        await restaurantsStore.getItems();

        restaurants.value.map( ({id, name}: RestaurantType) => {
            restaurantsOptions.value.push({id: id, label: name, command: () => { handleCreateReturn(id)} })
        })
    })

    onBeforeUnmount( async () => {
        complaints.value = [];
    })

    const handleDeleteReturn = (complaint:ComplaintType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {complaintsStore.deleteItem(complaint.id)}
        })
    }

    const handleShowReturn = (complaint:ComplaintType) => {
        complaintsRefs.single.value = null
        router.push({name: 'return-show',  params: (complaint ? {id: complaint.id} : {}) });
    }

    const handleCreateReturn = (id:number) => {
        let restaurant = restaurants.value.find( (restaurant:RestaurantType) => restaurant.id === id)
        complaintsRefs.single.value = { items: [], uploads: [], restaurant_id: restaurant.id, restaurant: restaurant };

        router.push({ name: 'return-create-supplier' });
    }
    const handleClose = (complaint:ComplaintType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir cloturer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Cloturer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => { complaintsStore.closeItem({id: complaint.id }).then( () => {
                complaintsStore.getItems({search: {type: COMPLAINT_TYPES.values.RETURN.description}, includes: ['order', 'supplier']});
            }) }
        })
    }
</script>
