export default {
    values: Object.freeze ({
        OPEN: Symbol(1),
        CLOSED: Symbol(2),
    }),
    labels: {
        1: 'Ouverte',
        2: 'Fermée',
    }
}

