import { createWebHistory, createRouter } from "vue-router";

import {useAuthStore} from "../store"
import {storeToRefs} from "pinia";

import Auth from '../router/auth'
import Category from "./category";
import Page from "./page";
import Content from "./content";
import Setting from "./setting";
import Order from "./order"

import Landing from "../views/backoffice/Landing.vue";
import Supplier from "./supplier";
import Product from "./product";
import Inventory from "./inventory";
import Return from "./return";
import Restaurant from "./restaurant"
import Tag from "./tag"
import Conditioning from "./conditioning";
import Loss from "./loss";
import User from "./user";
import Convenience from "./convenience";
import Reason from "./reason"
import StorageUnit from "./storageUnit";
import Survey from "./survey"
import Response from "./response"
import Audit from "./audit"

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        return {
            top: 0,
            behavior: 'smooth',
        }
    },
    routes: [
        {
            path: '/admin',
            name: 'admin',
            component: Landing,
        },
        ...Auth,
        ...User,
        ...Category,
        ...Page,
        ...Content,
        ...Setting,
        ...Order,
        ...Supplier,
        ...Product,
        ...Inventory,
        ...Return,
        ...Restaurant,
        ...Tag,
        ...Conditioning,
        ...Loss,
        ...Convenience,
        ...Reason,
        ...StorageUnit,
        ...Survey,
        ...Response,
        ...Audit
    ],
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const authRefs = storeToRefs(authStore);

    const authUser = authRefs.authUser;

    if (to.meta.allowAnonymous && to.path !== '/admin/login') {
        return next();
    }

    if (!authUser.value || !authUser.value.email) {
        await authStore.getAuthUser().then(() => {
            if (!authUser.value || !authUser.value.email) {
                window.location.replace('/admin/login');
                return next('/admin/login')
            }

            if (to.path === '/admin/login') {
                if(authUser?.value?.email) {
                    window.location.replace('/admin');
                }else {
                    return next();
                }
            }

            if(to.meta.authorize && Array.isArray(to.meta.authorize)) {
                if(to.meta.authorize.includes(authUser.value.role)) {
                    return next();
                }else {
                    return next({path: '/admin'});
                }
            }

            return next();
        }).catch(() => {
            if (to.path !== '/admin/login') {
                window.location.replace('/admin/login');
                return next('/admin/login')
            }
            return next();
        })
    } else {
        return next();
    }
})

export default router;
