export default {
    values: Object.freeze ({
        RETURN: Symbol(1),
        LOSS: Symbol(2),
    }),
    labels: {
        1: 'Retour',
        2: 'Perte',
    }
}

