<template>
    <div v-if="isSavePending || isFetchSinglePagePending"
         class="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-black/75 z-50">
        <ProgressSpinner/>
    </div>

    <template v-else>
        <Breadcrumb :home="breadCrumbsFirstItem" :model="breadCrumbsItems"/>

        <div class="mx-20 mt-4">
            <h2 class="ml-2 mb-3 text-3xl text-dark-slate-800 font-normal">
                {{ page ? page.translatable.title[locale] : 'Page' }}</h2>
            <h2 class="ml-2 text-4xl text-dark-slate-800">
                {{ route.params.page_id ? 'Modifier une page' : 'Ajouter une page' }}</h2>
        </div>
        <Card class="mx-20 my-5 shadow-2xl shadow-black">
            <template #content>
                <fieldset class="flex-col flex gap-8 mb-6">
                    <h3 class="mb-4 text-lg font-medium leading-6 text-gray-900">Informations générales</h3>
                    <div class="flex-1 flex flex-col gap-2">
                        <label for="title" class="text-dark-slate-600 uppercase tracking-widest">Titre</label>
                        <InputText
                            id="title"
                            type="text"
                            name="title"
                            v-model="form.title[locale]"
                            :class="form.invalid('title') ? 'p-invalid' : ''"
                            @change="form.validate('title')"
                        />
                        <small v-if="form.invalid('title')" class="p-error" id="text-error">
                            {{ form.errors.title }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="slug" class="text-dark-slate-600 uppercase tracking-widest">Slug</label>
                        <InputText
                            id="slug"
                            type="text"
                            name="slug"
                            v-model="form.slug[locale]"
                            :class="form.invalid('slug') ? 'p-invalid' : ''"
                            @change="form.validate('slug')"
                        />
                        <small v-if="form.invalid('slug')" class="p-error" id="text-error">
                            {{ form.errors.slug }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="method" class="text-dark-slate-600 uppercase tracking-widest">Méthode</label>
                        <InputText
                            id="method"
                            type="text"
                            name="method"
                            v-model="form.method"
                            :class="form.invalid('method') ? 'p-invalid' : ''"
                            @change="form.validate('method')"
                        />
                        <small v-if="form.invalid('method')" class="p-error" id="text-error">
                            {{ form.errors.method }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="template" class="text-dark-slate-600 uppercase tracking-widest">Template</label>
                        <InputText
                            id="template"
                            type="text"
                            name="template"
                            v-model="form.template"
                            :class="form.invalid('template') ? 'p-invalid' : ''"
                            @change="form.validate('template')"
                        />
                        <small v-if="form.invalid('template')" class="p-error" id="text-error">
                            {{ form.errors.template }}
                        </small>
                    </div>
                </fieldset>

                <fieldset class="flex-col flex gap-8 mb-6">
                    <h3 class="mb-4 text-lg font-medium leading-6 text-gray-900">SEO</h3>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="seo_type" class="text-dark-slate-600 uppercase tracking-widest">Type</label>
                        <InputText
                            id="seo_type"
                            type="text"
                            name="seo_type"
                            v-model="form.seo_type"
                            :class="form.invalid('seo_type') ? 'p-invalid' : ''"
                            @change="form.validate('seo_type')"
                        />
                        <small v-if="form.invalid('seo_type')" class="p-error" id="text-error">
                            {{ form.errors.seo_type }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="seo_title" class="text-dark-slate-600 uppercase tracking-widest">Titre</label>
                        <InputText
                            id="seo_title"
                            type="text"
                            name="seo_title"
                            v-model="form.seo_title[locale]"
                            :class="form.invalid('seo_title') ? 'p-invalid' : ''"
                            @change="form.validate('seo_title')"
                        />
                        <small v-if="form.invalid('seo_title')" class="p-error" id="text-error">
                            {{ form.errors.seo_title }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="seo_description"
                               class="text-dark-slate-600 uppercase tracking-widest">Description</label>
                        <Textarea
                            id="seo_description"
                            name="seo_description"
                            v-model="form.seo_description[locale]"
                            :class="form.invalid('seo_description') ? 'p-invalid' : ''"
                            @change="form.validate('seo_description')"
                        />
                        <small v-if="form.invalid('seo_description')" class="p-error" id="text-error">
                            {{ form.errors.seo_description }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="seo_image" class="text-dark-slate-600 uppercase tracking-wider">Illustration</label>
                        <FilePckr
                            id="seo_image"
                            name="seo_image"
                            v-model="form.seo_image"
                            :current-url="form.seo_image?.original_url"
                            @startLoad="handleStartLoad"
                            @endLoad="handleEndLoad"
                        />
                        <small v-if="form.invalid('seo_image')" class="p-error" id="text-error">
                            {{ form.errors.seo_image }}
                        </small>
                    </div>
                </fieldset>

                <fieldset class="flex-col flex gap-8 mb-6">
                    <h3 class="mb-4 text-lg font-medium leading-6 text-gray-900">Contenu</h3>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="content" class="text-dark-slate-600 uppercase tracking-widest">Texte</label>
                        <Editor
                            id="content"
                            name="content"
                            v-model="form.content[locale]"
                            :class="form.invalid('content') ? 'p-invalid' : ''"
                            @change="form.validate('content')"
                            editorStyle="height: 320px"
                        >
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <select class="ql-header">
                                        <option selected value="0">Paragraphe</option>
                                        <option value="1">Titre 1</option>
                                        <option value="2">Titre 2</option>
                                        <option value="3">Titre 3</option>
                                        <option value="4">Titre 4</option>
                                        <option value="5">Titre 5</option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-font">
                                        <option></option>
                                        <option value="serif"></option>
                                        <option value="monospace"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-bold"></button>
                                    <button class="ql-italic"></button>
                                    <button class="ql-underline"></button>
                                    <button class="ql-strike"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered"></button>
                                    <button class="ql-list" value="bullet"></button>
                                </span>
                                <span class="ql-formats">
                                    <select class="ql-align">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-clean"></button>
                                    <button class="ql-link"></button>
                                    <button class="ql-image"></button>
                                </span>
                            </template>
                        </Editor>
                        <small v-if="form.invalid('content')" class="p-error" id="text-error">
                            {{ form.errors.content }}
                        </small>
                    </div>

                    <!-- add slider -->
                    <h2 class="text-xl font-bold mb-6 flex flex-row items-center">
                        Slider
                    </h2>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="slider" class="text-dark-slate-600 uppercase tracking-wider">Slider</label>
                        <FilePckr
                            id="slider"
                            name="slider"
                            :chunkUploads="true"
                            @startLoad="handleStartLoad"
                            @endLoad="handleEndLoad"
                            multiple
                        />
                    </div>

                    <div v-if="page.slider?.data">
                        <Draggable
                            class="grid grid-cols-3 gap-2 mb-4"
                            handle=".input-handle"
                            v-model="page.slider.data"
                            @end="handleDragEnd"
                            item-key="id"
                        >
                            <template #item="{element, index}">
                                <div class="relative input-handle cursor-move">
                                    <template
                                        v-if="['video/mp4', 'video/ogg', 'video/x-msvideo'].includes(element.type)">
                                        <video controls width="360">
                                            <source :src="`${element.url}#t=0.1`" :type="element.mime_type"/>
                                        </video>
                                    </template>
                                    <template v-else>
                                        <img :src="element.url" alt="">
                                    </template>

                                    <button @click="handleDeleteImage(element.uuid)" type="button"
                                            class="bg-red-700 absolute top-1 left-1 hover:bg-red-400 transition-colors">
                                        <iconTimes class="w-4 h-4 fill-white"/>
                                    </button>
                                </div>
                            </template>
                        </Draggable>
                    </div>
                </fieldset>

                <div class="flex flex-row gap-2">
                    <Button label="Annuler" @click.prevent="router.go(-1)" severity="danger" size="small"><i
                        class="pi pi-times mr-2"></i>Annuler
                    </Button>
                    <Button label="Sauvegarder" severity="primary" size="small" @click.prevent="handleSubmit"><i
                        class="pi pi-check mr-2"></i>Sauvegarder
                    </Button>
                </div>
            </template>
        </Card>
    </template>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onBeforeUnmount, ref} from "vue";
import Breadcrumb from "primevue/breadcrumb";
import Card from 'primevue/card';
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Editor from "primevue/editor";
import Textarea from "primevue/textarea";
import FilePckr from "../../../components/file-pckr.vue";
import ProgressSpinner from "primevue/progressspinner";
import Draggable from "vuedraggable";
import IconTimes from "../../../../../svg/backoffice/times.svg?component"

import {useForm} from "laravel-precognition-vue";
import {useConfigsStore, usePagesStore} from "../../../store"
import {storeToRefs} from "pinia";
import {useToast} from "primevue/usetoast";
import {useRoute, useRouter} from "vue-router";

const toast = useToast();
const route = useRoute();
const router = useRouter();

const breadCrumbsFirstItem = computed(() => {
    return {label: 'Page(s)', to: '/admin/forms'}
})

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    if (page?.value?.id) {
        items.push({label: page?.value?.translatable?.title[locale.value] || 'N/A'})
    }

    items.push({label: page?.value?.id ? 'Modifier' : 'Ajouter'})

    return items;
});

const pagesStore = usePagesStore();
const pagesRefs = storeToRefs(pagesStore);
const isFetchSinglePagePending = pagesRefs.isFetchSinglePending;
const isSavePending = pagesRefs.isSavePending;
const page = ref(pagesRefs.single);

const configsStore = useConfigsStore();
const configsRefs = storeToRefs(configsStore);

const locale = configsRefs.locale;

const form = useForm(
    route.params.page_id ? 'patch' : 'post',
    route.params.page_id
        ? '/api/pages/' + route.params.page_id
        : '/api/pages',
    {
        title: {fr: '', en: ''},
        slug: {fr: '', en: ''},
        content: {fr: '', en: ''},
        method: null,
        template: null,
        seo_type: null,
        seo_title: {fr: '', en: ''},
        seo_description: {fr: '', en: ''},
        seo_image: null,
        form: <{ label: string, id: number } | null>null,
        slider: Array<string>
    }
);

onBeforeMount(async () => {
    if (route.params.page_id) {
        await pagesStore.getItem({id: String(route.params.page_id), includes: ['slider', 'form', 'seo_image']})
    } else {
        page.value = {
            translatable: {
                title: {
                    fr: '',
                    en: ''
                }
            }
        }
    }

    form.setData({
        ...page?.value,
        title: page?.value?.translatable?.title || {fr: '', en: ''},
        slug: page?.value?.translatable?.slug || {fr: '', en: ''},
        content: page?.value?.translatable?.content || {fr: '', en: ''},
        seo_title: page?.value?.translatable?.seo_title || {fr: '', en: ''},
        seo_description: page?.value?.translatable?.seo_description || {fr: '', en: ''},
    })
})

const handleSubmit = () => {
    pagesRefs.isSavePending.value = true;

    let untouchedData = form.data();

    form
        .setData({
            form: untouchedData?.form?.id || null,
        })
        .submit()
        .then(() => {
            if (route.params.form_id) {
                toast.add({severity: 'success', summary: 'Bravo', detail: 'La page a bien été modifié', life: 8000});
            } else {
                toast.add({severity: 'success', summary: 'Bravo', detail: 'La page a bien été créé', life: 8000});
            }

            return router.push({name: 'pages'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                toast.add({
                    'severity': 'warn',
                    'summary': 'Attention',
                    'detail': 'Il y a des erreurs dans le formulaire',
                    life: 8000
                })
            }
            if (error.response.status === 500) {
                toast.add({
                    'severity': 'error',
                    'summary': 'Attention',
                    'detail': 'Il y a une erreur serveur',
                    life: 8000
                })
            }
            form.setData({...untouchedData});
        })
        .finally(() => {
            pagesRefs.isSavePending.value = false;
        })
}

onBeforeUnmount(() => {
    pagesRefs.single.value = null;
})

const isFilepondPending = ref(false);

const handleStartLoad = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = true;
}

const handleEndLoad = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = false;
}

const handleDragEnd = () => {
    pagesStore.moveSlider(form.slider.data);
}

const handleDeleteImage = (uuid: string) => {
    pagesStore.deleteSlider(uuid);
}
</script>
