import {defineStore, storeToRefs} from 'pinia'
import {BaseStore} from "./base";
import {RestaurantType} from "../types/restaurant";
import {BaseStoreType} from "../types/base-store";
import {useConfigsStore} from "./config";
import Axios from "axios";
import {ref} from "vue";
import {ProductType} from "../types/product";

interface FetchProductsBySupplierType {
    restaurant_id: number,
    supplier_id: number,
    search?: Record<string, any>;
    desc?: boolean;
    sort_by?: string | null;
    includes?: string[];
    fields?: string[];
    appends?: string[];
    list?: boolean;
}

interface FetchProductsType {
    restaurant_id: number,
    search?: Record<string, any>;
    desc?: boolean;
    sort_by?: string | null;
    includes?: string[];
    fields?: string[];
    appends?: string[];
}

interface FetchOrdersType {
    restaurant_id: number,
    supplier_id: number,
    search?: Record<string, any>;
    desc?: boolean;
    sort_by?: string | null;
    includes?: string[];
    fields?: string[];
    appends?: string[];
    list?: boolean
}

export const useRestaurantsStore = defineStore('restaurants', () => {
    const resourceName = 'restaurants';
    const baseUrl = '/api/restaurants';

    const baseStore:BaseStoreType = new BaseStore<RestaurantType>({
        resourceName,
        baseUrl,
        useStore: useRestaurantsStore()
    });

    const isFetchSuppliersPending = ref(false);
    const suppliers = ref([]);

    const isFetchUsersPending = ref(false);
    const users = ref([]);

    const products = ref();
    const isFetchProductsPending = ref(false);

    const orders = ref();
    const isFetchOrdersPending = ref(false);

    const configStore = useConfigsStore();
    const restaurantsStore = useRestaurantsStore();

    const getSuppliers = ({id, search = {}, includes = [], sort_by = null, desc = false}: {id:string, search: any, includes:string[], sort_by:null|string, desc:boolean}) => {
        const configStore = useConfigsStore();

        isFetchSuppliersPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/${id}/suppliers`, {
            params : {
                ...filters,
                ...(includes.length > 0 && {include: includes.join('|')}),
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
            }
        })
            .then(response => {
                suppliers.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchSuppliersPending.value = false;
            });
    }

    const getUsers = () => {
        isFetchUsersPending.value = true;

        return Axios.get(`${baseUrl}/users?list=true`, {})
            .then(response => {
                users.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchSuppliersPending.value = false;
            });
    }

    const getProductsBySupplier = ({
            restaurant_id,
            supplier_id,
            search = {},
            desc = false,
            sort_by = null,
            includes = [],
            fields = [],
            appends = [],
            list = false
       }: FetchProductsBySupplierType) => {
        const restaurantsStore = useRestaurantsStore();

        isFetchProductsPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/${restaurant_id}/suppliers/${supplier_id}/products`, {
            params: {
                page: restaurantsStore.pagination.current_page,
                per_page: 1000,
                ...filters,
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
                ...(includes.length > 0 && {include: includes.join('|')}),
                ...(fields.length > 0 && {[`fields[${resourceName}]`]: fields.join(',')}),
                ...(appends.length > 0 && {append: appends.join(',')}),
                list: list
            },
        })
            .then(response => {
                products.value = response.data.data;
                products.value.map( (product:ProductType) => {
                    return {
                        ...product,
                        quantity: 0
                    }
                })
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchProductsPending.value = false;
            });
    }

    const getProducts = ({
           restaurant_id,
           search = {},
           desc = false,
           sort_by = null,
           includes = [],
           fields = [],
           appends = [],
       }: FetchProductsType) => {

        isFetchProductsPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/${restaurant_id}/products`, {
            params: {
                page: restaurantsStore.pagination.current_page,
                per_page: restaurantsStore.pagination.per_page,
                ...filters,
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
                ...(includes.length > 0 && {include: includes.join('|')}),
                ...(fields.length > 0 && {[`fields[${resourceName}]`]: fields.join(',')}),
                ...(appends.length > 0 && {append: appends.join(',')}),
            },
        })
            .then(response => {
                products.value = response.data.data;
                products.value = products.value.map( (product:ProductType) => {
                    return {
                        ...product,
                        quantity: null
                    }
                })
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchProductsPending.value = false;
            });
    }

    const getOrders = ({
         restaurant_id,
         supplier_id,
         search = {},
         desc = false,
         sort_by = null,
         includes = [],
         fields = [],
         appends = [],
         list = false
     }: FetchOrdersType) => {

        isFetchOrdersPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/${restaurant_id}/suppliers/${supplier_id}/orders`, {
            params: {
                page: restaurantsStore.pagination.current_page,
                per_page: restaurantsStore.pagination.per_page,
                ...filters,
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
                ...(includes.length > 0 && {include: includes.join('|')}),
                ...(fields.length > 0 && {[`fields[${resourceName}]`]: fields.join(',')}),
                ...(appends.length > 0 && {append: appends.join(',')}),
                list: list,
            },
        })
            .then(response => {
                orders.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchOrdersPending.value = false;
            });
    }

    const all = ref();
    const isFetchAllPending = ref(false);

    const getAll = ({
         search = {},
         desc = false,
         sort_by = null,
         includes = [],
         fields = [],
         appends = [],
     } = {} ) => {

        isFetchAllPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/all`, {
            params: {
                page: restaurantsStore.pagination.current_page,
                per_page: restaurantsStore.pagination.per_page,
                ...filters,
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
                ...(includes.length > 0 && {include: includes.join('|')}),
                ...(fields.length > 0 && {[`fields[${resourceName}]`]: fields.join(',')}),
                ...(appends.length > 0 && {append: appends.join(',')}),
            },
        })
            .then(response => {
                all.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchAllPending.value = false;
            });
    }

    return {
        ...baseStore,
        getSuppliers,
        suppliers,
        isFetchSuppliersPending,
        getUsers,
        users,
        getProductsBySupplier,
        isFetchProductsPending,
        products,
        getProducts,
        isFetchOrdersPending,
        orders,
        getOrders,
        getAll,
        all,
        isFetchAllPending
    };
});
