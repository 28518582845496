<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Panier
                </h1>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            :value="order.items"
            :loading="isFetchSinglePending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="order.items.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
            class="mb-6"
        >
            <Column header="Nom" #body="slotProps">
                {{ slotProps.data.product.name }}
                <template v-if="slotProps.data.product.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle"
                       v-tooltip="slotProps.data.product.note"
                    ></i>
                </template>
            </Column>

            <Column header="Prix" #body="slotProps">
                {{ Number(slotProps.data.product.inventory_price) }} €
            </Column>

            <Column header="Conditionnement" #body="slotProps">
                {{ slotProps.data?.product.inventory_conditioning_quantity }}
                {{ slotProps.data?.product.conditioning?.label }}
            </Column>

            <Column header="Utilisation" #body="slotProps">
                {{ slotProps.data.product.usage }}
            </Column>

            <Column header="Coût" #body="slotProps">
                {{ Number(slotProps.data.product.inventory_price *  slotProps.data?.quantity).toFixed(2) }} €
            </Column>

            <Column header="Commandé" field="quantity" />
        </DataTable>

        <div class="mb-6">
            <Textarea
                id="comment"
                name="comment"
                placeholder="Commentaire libre"
                class="w-full"
                v-model="order.note"
            />
        </div>

        <div class="mb-20">
            <h2 class="mb-6">Total :
                {{ order.items.reduce((sum:number, item:any) => sum + Number(item.quantity), 0) }}
                ({{ order.items.reduce((sum:number, item:any) => sum + (Number(item?.product?.inventory_price) * Number(item.quantity)), 0).toFixed(2) }} €)
            </h2>
            <div class="flex gap-2">
                <SplitButton
                    label="Valider ma dépanne"
                    @click="handleSubmitOrder(Number(ORDER_STATUS.values.PLANNED.description))"
                    :model="[{
                        label: 'Enregistrer comme brouillon',
                        command: () => { handleSubmitOrder(Number(ORDER_STATUS.values.DRAFT.description)) }
                    }]"
                    pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
                />

                <Button as="router-link" :to="{name: 'convenience-create-products'}">
                    Ajouter des produits
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";
    import {useConfigsStore, useOrdersStore} from "../../../../store";
    import {storeToRefs} from "pinia";

    import Textarea from "primevue/textarea";
    import InputText from "primevue/inputtext";
    import IconField from "primevue/iconfield";
    import InputIcon from "primevue/inputicon";
    import ProgressSpinner from "primevue/progressspinner";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import {useRoute, useRouter} from "vue-router";
    import SplitButton from "primevue/splitbutton";
    import {RestaurantType} from "../../../../types/restaurant";
    import useDebounce from "../../../../composables/debounce";
    import ORDER_TYPE from "../../../../enums/order-type";
    import ORDER_STATUS from "../../../../enums/order-status";
    import {useForm} from "laravel-precognition-vue";
    import Breadcrumbs from "../../../../components/partials/backoffice/breadcrumbs.vue";
    import Button from "primevue/button";

    const breadCrumbsFirstItem = computed( () => { return { label: 'Dépannes' , route: {name: 'conveniences' } } } );

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: order?.value?.restaurant.name})

        items.push({label: order?.value?.supplier.name})

        items.push({label: 'Validation panier'})

        return items;
    });

    const router = useRouter();

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const isFetchSinglePending = ordersRefs.isFetchSinglePending;
    const order = ref(ordersRefs.single);

    const handleGlobalFilterChange = (value:string) => {
        ordersStore.getItems({search: {search: value}})
    }

    const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
        handleGlobalFilterChange(event);
    }, 1000);

    const pagination = ref({
        per_page: 50,
        current_page: 1
    });

    onBeforeMount(() => {
        if(!order?.value?.restaurant_id || !order?.value?.supplier_id) {
            router.push({name: 'conveniences'})
        }
    });

    const handlePageChange = (event:any) => {

    }

    const handleSortChange = (event:any) => {

    }

    const form = useForm('post', '/api/orders',
        {
            'restaurant_id': null,
            'supplier_id': null,
            'status': null,
            'type': null,
            'note': null,
            'items': [],
            'received_note': '',
        }
    );

    const configsStore = useConfigsStore();

    const handleSubmitOrder = (status:number) => {
        // handle save as final or draft
        // type = 1 because it's an order
        order.value.status = Number(status);
        order.value.type = Number(ORDER_TYPE.values.CONVENIENCE.description);

        form
            .setData({...order.value})
            .submit()
            .then(() => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La dépanne a bien été créé'})

                return router.push({name: 'conveniences'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }
            })
            .finally(() => {
                ordersRefs.isSavePending.value = false;
            })

    }
</script>
