import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "23",
  height: "22.867"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      stroke: "#fff",
      "stroke-linecap": "round",
      "stroke-width": "3"
    }, [
      _createElementVNode("path", { d: "M1.5 1.5h20M1.5 11.434h20M1.5 21.367h20" })
    ], -1)
  ])))
}
export default { render: render }