<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Tags
                </h1>

                <Button @click="handleCreateOrEditTag">+ Ajouter un tag</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="tags"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" :field="`label`" :sortable="true" sort-field="label"></Column>

            <Column header="Action(s)" class="w-1/4" #body="slotProps">
                <div class="flex items-center gap-2">
                    <Button
                        link
                        label="Modifier"
                        @click.prevent="handleCreateOrEditTag(slotProps.data)"
                        v-tooltip="'Éditer'">
                        <i class="pi pi-pencil"></i>
                    </Button>
                    <Button
                        link
                        label="Supprimer"
                        @click.prevent="handleDeleteTag(slotProps.data)"
                        v-tooltip="'Supprimer'">
                        <i class="pi pi-trash"></i>
                    </Button>
                </div>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";

    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";

    import {useRoute, useRouter} from "vue-router";
    import {useConfirm} from "primevue/useconfirm";

    import {storeToRefs} from "pinia";
    import {useTagsStore, useConfigsStore} from "../../../store";

    import {TagType} from "../../../types/tag";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Search from "../../../components/partials/backoffice/search.vue";

    import useDatatableActions from "../../../composables/datatable-actions"

    const router = useRouter();
    const confirm = useConfirm()

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const tagsStore = useTagsStore();
    const tagsRefs = storeToRefs(tagsStore);
    const pagination = tagsRefs.pagination;
    const isFetchListPending = tagsRefs.isFetchListPending;
    const tags = tagsRefs.list;

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

    const breadCrumbsItems = computed(() => [{label: 'Tags'}]);

    const queryParams = ref({
        search: {
            search: null,
        },
        includes: [],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch} = useDatatableActions(pagination, queryParams, tagsStore.getItems)

    onBeforeMount(async () => {
        await tagsStore.getItems();
    })

    const handleDeleteTag = (tag:TagType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {tagsStore.deleteItem(tag.id)}
        })
    }

    const handleCreateOrEditTag = (tag:TagType) => {
        tagsRefs.single.value = null
        router.push({name: 'tag-save',  params: (tag ? {id: tag.id} : {}) });
    }
</script>
