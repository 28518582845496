<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title"> Audits </h1>

                <Button @click="handleCreateOrEditSurvey">+ Créer un nouvel audit</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <div class="flex mb-4">
            <Button
                link
                @click.prevent="handleChangeStatus(null)"
                class="py-3 pb-4 px-4 rounded-none outline-none"
                :pt="{root: {class: queryParams.search.status === null ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
            >
                Toutes
            </Button>
            <template v-for="status in SURVEY_STATUS.values">
                <Button
                    link
                    @click.prevent="handleChangeStatus(Number(status.description))"
                    class="py-3 pb-4 px-4 rounded-none outline-none"
                    :pt="{root: {class: Number(queryParams.search.status) === Number(status.description) ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                >
                    {{ SURVEY_STATUS.labels[status.description] }}
                </Button>
            </template>
        </div>

        <div>
            <div v-for="survey in surveys" class="border border-surface-200 p-6">
                <div class="flex justify-between mb-6">
                    <h2 class="text-xl font-bold">{{ survey.translatable.title[locale] }}</h2>
                    <div>
                        <Button
                            link
                            label="Voir"
                            @click.prevent="handleShowSurvey(survey)"
                            v-tooltip="'Voir les questions'">
                            <i class="pi pi-bars"></i>
                        </Button>
                        <Button
                            link
                            label="Voir"
                            @click.prevent="handleShowAudits(survey)"
                            v-tooltip="'Voir les audits'">
                            <i class="pi pi-file"></i>
                        </Button>
                        <Button
                            link
                            label="Modifier"
                            @click.prevent="handleCreateOrEditSurvey(survey)"
                            v-tooltip="'Éditer'">
                            <i class="pi pi-pencil"></i>
                        </Button>
                    </div>
                </div>
                <div v-html="survey.translatable.description[locale]" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import Search from "../../../components/partials/backoffice/search.vue";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
    import Button from "primevue/button";
    import {SurveyType} from "../../../types/survey";
    import useDatatableActions from "../../../composables/datatable-actions";
    import {computed, onBeforeMount, ref} from "vue";
    import {useSurveysStore, useConfigsStore} from "../../../store";
    import {storeToRefs} from "pinia";
    import {useRouter} from "vue-router";
    import {useConfirm} from "primevue/useconfirm";
    import SURVEY_STATUS from "../../../enums/survey-status";

    const router = useRouter()
    const confirm = useConfirm()

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const pagination = surveysRefs.pagination;
    const isFetchListPending = surveysRefs.isFetchListPending;
    const surveys = surveysRefs.list;

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore);

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });
    const breadCrumbsItems = computed(() => [{label: 'Audits'}]);

    const queryParams = ref({
        search: {
            search: null,
            status: null
        },
        includes: [],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch, handleChangeStatus} = useDatatableActions(pagination, queryParams, surveysStore.getItems)

    onBeforeMount( async () => {
        await surveysStore.getItems(queryParams);
    })

    const handleCreateOrEditSurvey = (survey:SurveyType) => {
        surveysRefs.single.value = null
        router.push({name: 'survey-save',  params: (survey ? {id: survey.id} : {}) });
    }

    const handleShowSurvey = (survey:SurveyType) => {
        surveysRefs.single.value = null
        router.push({name: 'survey-show',  params: (survey ? {id: survey.id} : {}) });
    }

    const handleShowAudits = (survey:SurveyType) => {
        surveysRefs.single.value = null
        router.push({name: 'survey-audits',  params: (survey ? {id: survey.id} : {}) });
    }
</script>
