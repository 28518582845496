<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ restaurant?.id ? 'Modifier un restaurant' : 'Ajouter un restaurant' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="name" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="name"
                    type="text"
                    name="name"
                    v-model="form.name"
                    :invalid="form.invalid('name')"
                    @change="form.validate('name')"
                />
                <small v-if="form.invalid('name')" class="text-red-500"> {{ form.errors.name }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="code" class="text-dark-slate-600 uppercase tracking-widest">Code</label>
                <InputText
                    id="code"
                    type="text"
                    name="code"
                    v-model="form.code"
                    :invalid="form.invalid('code')"
                    @change="form.validate('code')"
                />
                <small v-if="form.invalid('code')" class="text-red-500"> {{ form.errors.code }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="description" class="text-dark-slate-600 uppercase tracking-widest">Description</label>
                <InputText
                    id="description"
                    type="text"
                    name="description"
                    v-model="form.description[locale]"
                    :invalid="form.invalid('description')"
                    @change="form.validate('description')"
                />
                <small v-if="form.invalid('description')" class="text-red-500"> {{ form.errors.description }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="street" class="text-dark-slate-600 uppercase tracking-widest">Adresse</label>
                <InputText
                    id="address"
                    type="text"
                    name="address"
                    v-model="form.address"
                    :invalid="form.invalid('address')"
                    @change="form.validate('address')"
                />
                <small v-if="form.invalid('address')" class="text-red-500"> {{ form.errors.address }} </small>
            </div>

            <div>
                <div class="flex gap-2">
                    <div class="flex flex-col gap-2 flex-1">
                        <label for="postal_code" class="text-dark-slate-600 uppercase tracking-widest">Code postal</label>
                        <InputText
                            id="postal_code"
                            name="postal_code"
                            v-model="form.postal_code"
                            :invalid="form.invalid('postal_code')"
                            @change="form.validate('postal_code')"
                        />
                        <small v-if="form.invalid('postal_code')" class="text-red-500"> {{ form.errors.postal_code }} </small>
                    </div>

                    <div class="flex flex-col gap-2 flex-1">
                        <label for="city" class="text-dark-slate-600 uppercase tracking-widest">Ville</label>
                        <InputText
                            id="city"
                            name="city"
                            v-model="form.city"
                            :invalid="form.invalid('city')"
                            @change="form.validate('city')"
                        />
                        <small v-if="form.invalid('city')" class="text-red-500"> {{ form.errors.city }} </small>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <label for="vat_number" class="text-dark-slate-600 uppercase tracking-widest">N° de TVA</label>
                <InputText
                    id="vat_number"
                    name="vat_number"
                    v-model="form.vat_number"
                    :invalid="form.invalid('vat_number')"
                    @change="form.validate('vat_number')"
                />
                <small v-if="form.invalid('vat_number')" class="text-red-500"> {{ form.errors.vat_number }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="contact_email" class="text-dark-slate-600 uppercase tracking-widest">Email</label>
                <InputText
                    id="contact_email"
                    name="contact_email"
                    v-model="form.contact_email"
                    :invalid="form.invalid('contact_email')"
                    @change="form.validate('contact_email')"
                />
                <small v-if="form.invalid('contact_email')" class="text-red-500"> {{ form.errors.contact_email }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="contact_phone" class="text-dark-slate-600 uppercase tracking-widest">Téléphone</label>
                <InputText
                    id="contact_phone"
                    name="contact_phone"
                    v-model="form.contact_phone"
                    :invalid="form.invalid('contact_phone')"
                    @change="form.validate('contact_phone')"
                />
                <small v-if="form.invalid('contact_phone')" class="text-red-500"> {{ form.errors.contact_phone }} </small>
            </div>

            <div class="flex gap-2">
                <div class="flex align-items-center">
                    <Checkbox
                        v-model="form.status"
                        inputId="status"
                        name="status"
                        :binary="true"
                        :invalid="form.invalid('status')"
                    />
                    <label for="status" class="ml-2"> Actif </label>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <label for="can_see" class="text-dark-slate-600 uppercase tracking-widest">Utilisateurs pouvant accéder à ce restaurant</label>
                <MultiSelect
                    v-model="form.users"
                    :options="usersOptions"
                    display="chip"
                    optionLabel="name"
                    :invalid="form.invalid('users')"
                />
                <small v-if="form.invalid('users')" class="text-red-500"> {{ form.errors.users }} </small>
            </div>

            <div class="flex flex-col gap-2">
                <label for="tags" class="text-dark-slate-600 uppercase tracking-widest">Catégorie</label>
                <Select
                    v-model="form.category"
                    :options="categoriesOptions"
                    optionLabel="name"
                    :invalid="form.invalid('category')"
                />
                <small v-if="form.invalid('category')" class="text-red-500"> {{ form.errors.category }} </small>
            </div>

            <div>
                <Button @click="handleSubmit">
                    {{ restaurant?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";

    import Breadcrumb from "primevue/breadcrumb";
    import InputText from "primevue/inputtext";
    import Select from "primevue/select";
    import MultiSelect from "primevue/multiselect";
    import Checkbox from "primevue/checkbox";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useRestaurantsStore, useConfigsStore} from "../../../store";
    import RESTAURANT_TYPES from "../../../enums/restaurant-types"
    import {UserType} from "../../../types/user";
    import RESTAURANT_STATUS from "../../../enums/restaurant-status";

    const router = useRouter();
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Restaurants' , route: {name: 'restaurants'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (restaurant?.value?.id) { items.push({label: restaurant?.value?.name || 'N/A'}) }

        items.push({label: restaurant?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const restaurant = restaurantsRefs.single;
    const isFetchPending = restaurantsRefs.isFetchSinglePending;

    const categoriesOptions = Object.entries(RESTAURANT_TYPES.labels).map( type => {
        return {id: type[0], name: type[1]}
    });

    const usersOptions = ref<{ name: string, id: number }[]>([]);

    onBeforeMount(async () => {
        if (route.params.id) {
            await restaurantsStore.getItem({id: String(route.params.id), includes: ['users']})
        } else {
            restaurant.value = {
                name: null,
                description: {"fr": null},
                address: null,
                postal_code: null,
                city: null,
                code: null,
                vat_number: null,
                contact_email: null,
                contact_phone: null,
                status: 0,
                users: [],
                category: null
            }
        }

        await restaurantsStore.getUsers({list: true});

        usersOptions.value = restaurantsRefs.users.value.map((user: UserType) => {
            return {name: user?.name, id: user.id}
        });

        form.setData({
            ...restaurant?.value,
            category: categoriesOptions?.find((category:any) => Number(category.id) == Number(restaurant?.value?.category)) || null,
            users: restaurant?.value ? usersOptions?.value.filter((user:any) => restaurant?.value?.users?.data?.map( (user:any) => user.id).includes(Number(user.id))) : [],
            description: restaurant?.value?.translatable?.description || {fr: ''},
            status: restaurant?.value?.status === 1
        })
    })

    const form = useForm(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/restaurants/' + route.params.id
            : '/api/restaurants',
        {
            name: null,
            description: {"fr": null},
            code: null,
            address: null,
            postal_code: null,
            city: null,
            vat_number: null,
            contact_email: null,
            contact_phone: null,
            status: 0,
            users: [],
            category: null
        }
    );

    const handleSubmit = () => {
        restaurantsRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                category: untouchedData?.category?.id || null,
                users: untouchedData?.users?.map( (user:UserType) => user.id ) || [],
                status: untouchedData?.status ? Number(RESTAURANT_STATUS.values.ENABLED.description) : Number(RESTAURANT_STATUS.values.DISABLED.description),
            })
            .submit()
            .then(() => {
                if (route.params.form_id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le restaurant a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le restaurant a bien été créé'})
                }

                return router.push({name: 'restaurants'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                restaurantsRefs.isSavePending.value = false;
            })
    }
</script>
