import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {AuditType} from "../types/audit";
import {BaseStoreType} from "../types/base-store";
import Axios from "axios";
import {ref} from "vue";
import {useConfigsStore} from "./config";

export const useAuditsStore = defineStore('audits', () => {
    const resourceName = 'audits';
    const baseUrl = '/api/surveys/audits';

    const baseStore:BaseStoreType = new BaseStore<AuditType>({
        resourceName,
        baseUrl,
        useStore: useAuditsStore()
    });

    const auditsStore = useAuditsStore();
    const configsStore = useConfigsStore();

    const isCorrectedPending = ref<null|number>(null);

    const setCorrected = async ({audit_id, item_id, is_corrected, includes = []}:{audit_id:number, item_id: number, is_corrected: number, includes:string[]}) => {
        isCorrectedPending.value = item_id;

        return Axios.post(`${baseUrl}/${audit_id}/item/${item_id}/corrected`, {
            is_corrected: is_corrected,
            ...(includes.length > 0 && {include: includes.join('|')}),
        })
            .then(response => {
                auditsStore.single.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isCorrectedPending.value = null;
            });
    }

    const isCorrectionUploadPending = ref<null|number>(null);

    const uploadCorrected = async ({audit_id, item_id, survey_question_id, survey_response_item_id, uploads, includes = []}:{audit_id:number, item_id: number, survey_question_id: number, survey_response_item_id: number, uploads: string|undefined, includes:string[]}) => {
        isCorrectionUploadPending.value = item_id;

        return Axios.post(`${baseUrl}/${audit_id}/item/${item_id}/corrected/upload`, {
            illustration: uploads,
            survey_question_id: survey_question_id,
            survey_response_item_id: survey_response_item_id,
            ...(includes.length > 0 && {include: includes.join('|')}),
        })
            .then(response => {
                auditsStore.single.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isCorrectionUploadPending.value = null;
            });
    }

    return {
        ...baseStore,
        setCorrected,
        isCorrectedPending,
        isCorrectionUploadPending,
        uploadCorrected
    };
});
