import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/inventories/List.vue"
import Save from "../views/backoffice/inventories/Save.vue"

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/inventories',
        name: 'inventories',
        component: List,
    },
    {
        path: '/admin/inventories/:id/save',
        name: 'inventory-save',
        component: Save,
    }
]

export default module;
