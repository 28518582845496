<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex items-center">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Commandes du jour
                </h1>
            </div>
        </div>

        <DataTable
            :value="orders"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Fournisseur" :sortable="false" #body="slotProps">
                {{ slotProps.data.supplier.name }}
            </Column>

            <Column header="Magasin" :sortable="false" #body="slotProps">
                {{ slotProps.data.restaurant?.name }}
            </Column>

            <Column header="Livraison le" :sortable="false" #body="slotProps">
                {{ new Intl.DateTimeFormat('fr-BE').format(new Date(slotProps.data.delivery_at)) }}
            </Column>

            <Column header="Remarque" :sortable="false" field="note" />

            <Column key="actions" header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center gap-2">
                        <Button
                            link
                            label="Voir"
                            @click.prevent="handleCreateOrder(slotProps.data)"
                            v-tooltip="'Voir'">
                            <i class="pi pi-eye"></i>
                        </Button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import { useRouter} from "vue-router";
import {computed, onBeforeMount, onBeforeUnmount, ref} from "vue";
import {useOrdersStore} from "../../../store";
import {storeToRefs} from "pinia";

import {OrderType} from "../../../types/order";

import ORDER_TYPE from "../../../enums/order-type";

import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import Search from "../../../components/partials/backoffice/search.vue"
import {RestaurantType} from "@/backoffice/types/restaurant";

const router = useRouter()

const ordersStore = useOrdersStore();
const ordersRefs = storeToRefs(ordersStore);
const pagination = ordersRefs.pagination;
const isFetchListPending = ordersRefs.isFetchListPending;
const orders = ordersRefs.todaysOrder;

const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

const breadCrumbsItems = computed(() => [{label: 'Commandes du jour'}]);

onBeforeMount(async () => {
    await ordersStore.getToday({includes: ['supplier', 'restaurant']});
})

const handlePageChange = (event:any) => {
    pagination.value.per_page = event.rows;
    pagination.value.current_page = event.page + 1;
    ordersStore.getToday({includes: ['supplier', 'restaurant']});
}

const handleSortChange = (event:any) => {
    ordersStore.getToday({includes: ['supplier', 'restaurant'] })
}
const handleCreateOrder = (todayOrder:any) => {
    ordersRefs.single.value = {
        restaurant_id: todayOrder.restaurant.id,
        restaurant: todayOrder.restaurant,
        supplier_id: todayOrder.supplier.id,
        supplier: todayOrder.supplier,
    };

    router.push({ name: 'order-create-products' });
}
</script>
