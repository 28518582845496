<template>
    <div v-if="isSavePending || isFetchSingleCategoryPending" class="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-black/75 z-50">
        <ProgressSpinner />
    </div>

    <template v-else>
        <Breadcrumb :home="breadCrumbsFirstItem" :model="breadCrumbsItems" />

        <div class="mx-20 mt-4">
            <h2 class="ml-2 mb-3 text-3xl text-dark-slate-800 font-normal">{{ category ? category.name : 'Catégorie'}}</h2>
            <h2 class="ml-2 text-4xl text-dark-slate-800">{{ route.params.category_id ? 'Modifier une catégorie' : 'Ajouter une catégorie' }}</h2>
        </div>

        <Card class="mx-20 my-5 shadow-2xl shadow-black">
            <template #content>
                <div class="flex-1 flex flex-col gap-2 mb-6">
                    <label for="name" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                    <InputText
                        id="name"
                        type="name"
                        name="name"
                        v-model="form.name"
                        :class="form.invalid('name') ? 'p-invalid' : ''"
                        @change="form.validate('name')"
                    />
                    <small v-if="form.invalid('name')" class="p-error" id="text-error">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="flex flex-col gap-2 mb-6">
                    <label for="type" class="text-dark-slate-600 uppercase">Catégorie parente</label>
                    <Dropdown
                        v-model="form.category"
                        :options="categoriesOptions"
                        optionLabel="name"
                        placeholder="Choisissez une catégorie"
                        :class="form.invalid('category') ? 'p-invalid' : ''"
                    />
                    <small v-if="form.invalid('category')" class="p-error" id="text-error">
                        {{ form.errors.category }}
                    </small>
                </div>

                <div class="flex flex-row gap-2">
                    <Button label="Annuler" @click.prevent="router.go(-1)" severity="danger" size="small"><i class="pi pi-times mr-2"></i>Annuler</Button>
                    <Button label="Sauvegarder" severity="primary" size="small" @click.prevent="handleSubmit"><i class="pi pi-check mr-2"></i>Sauvegarder</Button>
                </div>
            </template>
        </Card>
    </template>
</template>

<script setup lang="ts">
    import {computed, onBeforeMount, onBeforeUnmount, ref} from "vue";
    import Breadcrumb from "primevue/breadcrumb";
    import Card from 'primevue/card';
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";

    import { useForm } from "laravel-precognition-vue";
    import {useCategoriesStore, useConfigsStore} from "../../../store"
    import { storeToRefs } from "pinia";
    import { useToast } from "primevue/usetoast";
    import { useRoute, useRouter } from "vue-router";
    import ProgressSpinner from "primevue/progressspinner";
    import Dropdown from "primevue/dropdown";
    import {CategoryType} from "../../../types/category";

    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const breadCrumbsFirstItem = ref({
        label: 'Catégorie(s)',
        to: { name: 'categories'}
    });

    const breadCrumbsItems = computed(() => {
        let items: {label: string, to?: {name: string, params?: any}} [] = [];

        if(category.value) {
            items.push({label: category?.value?.name || ''})
        }

        items.push({label: category?.value !== null ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);

    const locale = configsRefs.locale;

    const categoriesStore = useCategoriesStore();
    const categoriesRefs = storeToRefs(categoriesStore);
    const isFetchSingleCategoryPending = categoriesRefs.isFetchSinglePending;
    const isSavePending = categoriesRefs.isSavePending;
    const category = categoriesRefs.single;
    const categoriesOptions = ref<{ name: string, id: number }[]>([]);

    const form = useForm(
        route.params.category_id ? 'patch' : 'post',
        route.params.category_id
            ? '/api/categories/' + route.params.category_id
            : '/api/categories',
        {
            name: null,
            category: <{label: string, id: number} | null>null,
        }
    );

    onBeforeMount( async () => {
        if(route.params.category_id) {
            categoriesStore.getItem({id: String(route.params.category_id)})
        }else {
            category.value = null;
        }

        await categoriesStore.getItems({includes: ['categories']});
        categoriesOptions.value = categoriesRefs.list.value.map((category: CategoryType) => {
            return {name: category.name[locale.value], id: category.id}
        });

        form.setData({
            name: category?.value?.name || null,
            category: categoriesOptions.value?.find(parent_category => Number(parent_category.id) == Number(category?.value?.category_id)),
        })
    })

    const handleSubmit = () => {
        categoriesRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                category: untouchedData?.category?.id || null,
            })
            .submit()
            .then( () => {
                if(route.params.category_id) {
                    toast.add({severity: 'success', summary: 'Bravo', detail: 'La catégorie a bien été modifié', life: 8000});
                }else {
                    toast.add({severity: 'success', summary: 'Bravo', detail: 'La catégorie a bien été créé', life: 8000});
                }

                return router.push({name: 'configuration'});
            })
            .catch(error => {
                if(error.response.status === 422) {
                    toast.add({'severity': 'warn', 'summary': 'Attention', 'detail': 'Il y a des erreurs dans le formulaire', life: 8000})
                }
                if(error.response.status === 500) {
                    toast.add({'severity': 'error', 'summary': 'Attention', 'detail': 'Il y a une erreur serveur', life: 8000})
                }
                form.setData({...untouchedData});
            })
            .finally( () => {
                categoriesRefs.isSavePending.value = false;
            })
    }

    onBeforeUnmount( () => {
        categoriesRefs.single.value = null;
    })
</script>
