<template>
    <nav class="menu">
        <div class="flex items-center justify-center py-2 m-4 relative pt-6 sm:pt-0">
            <h1 class="hidden">Huggy's - Administration</h1>
            <IconTimes @click.prevent="handleCloseMenu" class="menu-closer w-10 h-10 fill-black absolute top-[-0.75em] right-[-0.75em]"/>
            <IconLogo class="h-full p-1 sm:p-6"/>
        </div>
        <!--TODO: menu filtering-->

<!--
        <template v-if="authStore.isAdmin()">
            <h2 class="menu-subtitle">CMS</h2>

            <router-link :to="{name: 'categories'}" class="menu-link" :class="['categories'].includes($route.name) ?? 'router-link-active'">Catégories</router-link>
            <router-link :to="{ name: 'pages' }" class="menu-link" :class="['pages'].includes($route.name) ?? 'router-link-active'">Pages</router-link>
            <router-link :to="{ name: 'contents' }" class="menu-link" :class="['contents'].includes($route.name) ?? 'router-link-active'">Contenus</router-link>
            <router-link :to="{ name: 'users' }" class="menu-link" :class="['users'].includes($route.name) ?? 'router-link-active'">Utilisateurs</router-link>
            <router-link :to="{ name: 'settings'}" class="menu-link" :class="['settings'].includes($route.name) ?? 'router-link-active'">Paramètres</router-link>
        </template>
-->

        <h2 class="menu-subtitle">Audit</h2>
        <router-link :to="{name: 'surveys'}" class="menu-link">Audits</router-link>
        <router-link :to="{name: 'responses'}" class="menu-link">Types de réponse</router-link>


        <h2 class="menu-subtitle">Commandes</h2>

        <router-link :to="{name: 'orders-today'}" class="menu-link">Commandes du jour ({{ orders.length }})</router-link>
        <router-link :to="{name: 'orders'}" class="menu-link">Commandes</router-link>
        <router-link :to="{name: 'conveniences'}" class="menu-link">Dépannes</router-link>
        <router-link :to="{name: 'suppliers'}" class="menu-link">Fournisseurs</router-link>
        <router-link :to="{name: 'products'}" class="menu-link">Produits</router-link>
        <router-link :to="{name: 'inventories'}" class="menu-link">Inventaire</router-link>
        <router-link :to="{name: 'returns'}" class="menu-link">Retours</router-link>
        <router-link :to="{name: 'losses'}" class="menu-link">Pertes</router-link>

        <h2 class="menu-subtitle" v-if="authStore.isAdmin()">Admin</h2>

        <router-link v-if="authStore.isAdmin()" :to="{name: 'restaurants'}" class="menu-link">Restaurants</router-link>
        <router-link v-if="authStore.isAdmin()" :to="{name: 'users'}" class="menu-link">Utilisateurs</router-link>
        <router-link v-if="authStore.isAdmin()" :to="{name: 'tags'}" class="menu-link">Tags</router-link>
        <router-link v-if="authStore.isAdmin()" :to="{name: 'conditionings'}" class="menu-link">Conditionnements</router-link>
        <router-link v-if="authStore.isAdmin()" :to="{name: 'reasons'}" class="menu-link">Raisons de retour</router-link>
        <router-link v-if="authStore.isAdmin()" :to="{name: 'storageUnits'}" class="menu-link">Packaging</router-link>

<!--        <div class="flex items-center justify-center py-8 w-4/5 mx-auto">
            <div class="rounded-full bg-slate-700 h-[45px] w-[45px] flex text-white items-center justify-center mr-2">
                <iconLaptop class="fill-white"/>
            </div>
            <a href="/" target="_blank" class="flex-1 text-white text-[15px]"><strong class="text-bold text-[14px]">Accès à la</strong> Plateforme</a>
        </div>-->
    </nav>
</template>

<script setup lang="ts">
    import IconTimes from "../../../../../svg/backoffice/times.svg?component"
    import IconLogo from "../../../../../svg/backoffice/huggys-alt.svg?component"
    import {useAuthStore, useOrdersStore} from "../../../store";
    import {storeToRefs} from "pinia";
    import {onBeforeMount, onBeforeUnmount, watch} from "vue";

    const emit = defineEmits(['onCloseMenu'])

    const authStore = useAuthStore();

    const ordersStore = useOrdersStore();
    const ordersRefs = storeToRefs(ordersStore);
    const orders = ordersRefs.todaysOrder;

    onBeforeMount(async () => {
        await ordersStore.getToday({includes: ['supplier', 'restaurant']});
    })

    onBeforeUnmount(async () => {
        await ordersStore.getToday({includes: ['supplier', 'restaurant']});
    })

    const handleCloseMenu = () => {
        emit('onCloseMenu');
    }
</script>
