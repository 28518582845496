<template>
    <div class="flex flex-col gap-4 mb-6">
        <div class="flex flex-col gap-2">
            <label for="product" class="text-dark-slate-600 uppercase tracking-widest">Produit</label>
            <Select
                id="product"
                name="product"
                optionLabel="name"
                :options="productsOptions"
                placeholder="Sélectionnez un produit"
                v-model="item.product"
                :loading="isProductsLoading"
                :pt="{
                    listContainer: {
                        class: 'overflow-scroll'
                    }
                }"
            >
                <template #value="slotProps">
                    <template v-if="slotProps.value">{{ slotProps.value.name }} ({{ slotProps.value.inventory_conditioning_quantity }} {{ slotProps.value.conditioning.label }})</template>
                    <template v-else>{{ slotProps.placeholder }}</template>
                </template>

                <template #option="slotProps">
                    {{ slotProps.option.name }} ({{ slotProps.option.inventory_conditioning_quantity }} {{ slotProps.option.conditioning.label }})
                </template>

            </Select>
        </div>

        <div class="flex flex-col gap-2 flex-1">
            <label for="quantity" class="text-dark-slate-600 uppercase tracking-widest">Quantité</label>
            <InputNumber
                id="quantity"
                name="quantity"
                :minFractionDigits="1"
                :maxFractionDigits="3"
                v-model="item.quantity"
            />
        </div>

        <div class="flex flex-col gap-2">
            <label for="complaint_reason" class="text-dark-slate-600 uppercase tracking-widest">Raison</label>
            <Select
                id="complaint_reason"
                name="complaint_reason"
                optionLabel="label"
                :options="reasons"
                placeholder="Sélectionnez une raison"
                v-model="item.complaint_reason"
                :loading="isReasonsLoading"
            />
        </div>

        <div class="flex flex-col gap-2 flex-1">
            <label for="complaint_reason_note" class="text-dark-slate-600 uppercase tracking-widest">Autre raison</label>
            <InputText
                id="complaint_reason_note"
                name="complaint_reason_note"
                v-model="item.complaint_reason_note"
            />
        </div>

        <div class="flex flex-col gap-4 justify-between mb-6">
            <FilePckr
                id="uploads"
                multiple
                v-model="item.uploads"
                @endLoad="handleLoadFile"
            />
        </div>
    </div>

    <Button @click.prevent="handleSubmit" label="Valider" />
</template>

<script lang="ts" setup>
    import {inject, onMounted, ref} from "vue";
    import InputText from "primevue/inputtext";
    import {useReasonsStore, useRestaurantsStore} from "../../store";
    import Button from "primevue/button";
    import InputNumber from "primevue/inputnumber";
    import FilePckr from "../../components/file-pckr.vue";
    import Select from "primevue/select";
    import {storeToRefs} from "pinia";

    import type {DynamicDialogInstance} from "primevue/dynamicdialogoptions";
    import {Ref} from "vue/dist/vue";

    const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef')

    const reasonsStore = useReasonsStore()
    const reasonsRefs = storeToRefs(reasonsStore)
    const reasons = reasonsRefs.list
    const isReasonsLoading = reasonsRefs.isFetchListPending

    const restaurantsStore = useRestaurantsStore()
    const restaurantsRefs = storeToRefs(restaurantsStore)
    const products = restaurantsRefs.products
    const productsOptions = ref([{}]);
    const isProductsLoading = restaurantsRefs.isFetchProductsPending;

    const item = ref({
        product: null,
        quantity: null,
        complaint_reason: null,
        complaint_reason_note: null,
        uploads: [],
    })

    onMounted(async () => {
        const params = dialogRef?.value?.data;

        await reasonsStore.getItems({search: {type: params.type }, list: true})

        await restaurantsStore.getProductsBySupplier({restaurant_id: params.complaint.restaurant_id, supplier_id: params.complaint.supplier_id, list: true})

        productsOptions.value = products.value;
    })

    const handleLoadFile = ({files, id}: { files: [], id: number }) => {
        item.value.uploads = files.map((file: { serverId: string }) => file.serverId)
    }

    const handleSubmit = () => {
        dialogRef.value.close(item.value);
    }
</script>
