export default {
    values: Object.freeze ({
        INTERNAL: Symbol(1),
        EXTERNAL: Symbol(2),
    }),
    labels: {
        1: 'Interne',
        2: 'Externe',
    }
}

