import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {ProductType} from "../types/product";
import {BaseStoreType} from "../types/base-store";
import Axios from "axios";
import {useConfigsStore} from "./config";
import {ref} from "vue";

export const useProductsStore = defineStore('products', () => {
    const resourceName = 'products';
    const baseUrl = '/api/products';

    const baseStore:BaseStoreType = new BaseStore<ProductType>({
        resourceName,
        baseUrl,
        useStore: useProductsStore()
    });

    const isFetchRestaurantsPending = ref(false);
    const restaurants = ref([]);
    const productsStore = useProductsStore();

    const getRestaurants = async ({id, search = {}, desc = false, sort_by = null}: {id: string, sort_by: string|null, search: any, desc: boolean}) => {
        const configStore = useConfigsStore();

        isFetchRestaurantsPending.value = true;

        let filters: Record<string, any> = {};

        Object.entries(search).forEach(filter => {
            filters[`filter[${filter[0]}]`] = filter[1] === null ? 'null' : filter[1];
        })

        return Axios.get(`${baseUrl}/${id}/restaurants`, {
            params: {
                page: productsStore.pagination.current_page,
                per_page: productsStore.pagination.per_page,
                ...filters,
                ...({include: ['product_restaurant']}),
                ...(sort_by !== null && {sort: `${desc ? '-' : ''}${sort_by}`}),
                translatable: 1,
            },
        })
            .then(response => {
                restaurants.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isFetchRestaurantsPending.value = false;
            });
    }

    const isSaveRestaurantsPending = ref(false);

    const saveRestaurants = async ({id, data}: {id: string, data: any}) => {
        const configStore = useConfigsStore();

        isSaveRestaurantsPending.value = true;

        return Axios.post(`${baseUrl}/${id}/restaurants`, {restaurants: data})
            .then(response => {
                restaurants.value = response.data.data;
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isSaveRestaurantsPending.value = false;
            });
    }

    return {
        ...baseStore,
        getRestaurants,
        isFetchRestaurantsPending,
        restaurants,
        saveRestaurants,
        isSaveRestaurantsPending
    };
});
