import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/returns/List.vue"
import CreateSupplier from "../views/backoffice/returns/create/Supplier.vue";
import Store from "../views/backoffice/returns/create/Products.vue";
import Show from "../views/backoffice/returns/Show.vue"

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/returns',
        children: [
            {
                path: '',
                name: 'returns',
                component: List,
            },
            {
                path: '/admin/returns/create/supplier',
                name: 'return-create-supplier',
                component: CreateSupplier,
            },
            {
                path: '/admin/returns/create/products',
                name: 'return-create-products',
                component: Store,
            },
            {
                path: '/admin/returns/:id/show',
                name: 'return-show',
                component: Show,
            }
        ]
    }
]

export default module;
