<template>
    <div v-if="isSavePending || isFetchSingleContentPending" class="w-full h-full absolute top-0 left-0 flex items-center justify-center bg-black/75 z-50">
        <ProgressSpinner />
    </div>

    <template v-else>
        <Breadcrumb :home="breadCrumbsFirstItem" :model="breadCrumbsItems" />

        <div class="mx-20 mt-4">
            <h2 class="ml-2 mb-3 text-3xl text-dark-slate-800 font-normal">{{ content && content.name ? content.name[locale] : 'Contenu'}}</h2>
            <h2 class="ml-2 text-4xl text-dark-slate-800">{{ route.params.content_id ? 'Modifier un contenu' : 'Ajouter un contenu' }}</h2>
        </div>

        <Card class="mx-20 my-5 shadow-2xl shadow-black">
            <template #content>
                <div class="flex-col flex gap-8 mb-6">
                    <div class="flex-1 flex flex-col gap-2">
                        <label for="name" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                        <InputText
                            id="name"
                            type="text"
                            name="name"
                            v-model="form.name"
                            :class="form.invalid('name') ? 'p-invalid' : ''"
                            @change="form.validate('name')"
                        />
                        <small v-if="form.invalid('name')" class="p-error" id="text-error">
                            {{ form.errors['name.' + locale] }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="type" class="text-dark-slate-600 uppercase">Type</label>
                        <Dropdown
                            id="type"
                            name="type"
                            v-model="form.type"
                            :options="typeOptions"
                            optionLabel="name"
                            placeholder="Choisissez un type"
                            :class="form.invalid('type') ? 'p-invalid' : ''"
                            @change="form.validate('type')"
                        />
                        <small v-if="form.invalid('type')" class="p-error" id="text-error">
                            {{ form.errors.type }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                        <label for="page" class="text-dark-slate-600 uppercase">Page</label>
                        <Dropdown
                            id="page"
                            name="page"
                            v-model="form.page"
                            :options="pageOptions"
                            optionLabel="name"
                            placeholder="Choisissez un page"
                            :class="form.invalid('page') ? 'p-invalid' : ''"
                            @change="form.validate('page')"
                        />
                        <small v-if="form.invalid('page')" class="p-error" id="text-error">
                            {{ form.errors.page }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2" v-if="Number(form?.type?.id) === Number(CONTENT_TYPES.values.T.description)">
                        <label for="name" class="text-dark-slate-600 uppercase tracking-widest">Titre</label>
                        <InputText
                            id="title"
                            type="text"
                            name="title"
                            label="Title"
                            v-model="form.title[locale]"
                            :class="form.invalid('title.' + locale) ? 'p-invalid' : ''"
                            @change="form.validate('title')"
                        />
                        <small v-if="form.invalid('title.' + locale)" class="p-error" id="text-error">
                            {{ form.errors['title.' + locale] }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2" v-if="Number(form?.type?.id) === Number(CONTENT_TYPES.values.CONTENT.description)">
                        <label for="content" class="text-dark-slate-600 uppercase tracking-widest">Contenu</label>
                        <Editor
                            id="content"
                            name="content"
                            v-model="form.content[locale]"
                            :class="form.invalid('content.' + locale) ? 'p-invalid' : ''"
                            @change="form.validate('content')"
                        />
                        <small v-if="form.invalid('content.' + locale)" class="p-error" id="text-error">
                            {{ form.errors['content.' + locale] }}
                        </small>
                    </div>

                    <div class="flex-1 flex flex-col gap-2" v-if="Number(form?.type?.id) === Number(CONTENT_TYPES.values.IMAGE.description)">
                        <label for="image" class="text-dark-slate-600 uppercase tracking-wider">Image</label>
                        <FilePckr
                            id="picture"
                            name="picture"
                            v-model="form.picture"
                            :current-url="form?.picture?.original_url"
                            @startLoad="handleStartLoad"
                            @endLoad="handleEndLoad"
                            :class="form.invalid('picture') ? 'p-invalid' : ''"
                            @change="form.validate('picture')"
                        />
                        <small v-if="form.invalid('picture')" class="p-error" id="text-error">
                            {{ form.errors['picture'] }}
                        </small>
                    </div>
                </div>
                <div class="flex flex-row gap-2">
                    <Button label="Annuler" @click.prevent="router.go(-1)" severity="danger" size="small"><i class="pi pi-times mr-2"></i>Annuler</Button>
                    <Button label="Sauvegarder" severity="primary" size="small" @click.prevent="handleSubmit"><i class="pi pi-check mr-2"></i>Sauvegarder</Button>
                </div>
            </template>
        </Card>
    </template>
</template>

<script setup lang="ts">
    import {computed, onBeforeMount, onBeforeUnmount, ref} from "vue";
    import Breadcrumb from "primevue/breadcrumb";
    import Card from 'primevue/card';
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import Editor from "primevue/editor";

    import { useForm } from "laravel-precognition-vue";
    import { useConfigsStore, useContentsStore, usePagesStore } from "../../../store"
    import { storeToRefs } from "pinia";
    import { useToast } from "primevue/usetoast";
    import { useRoute, useRouter } from "vue-router";

    import ProgressSpinner from "primevue/progressspinner";
    import Dropdown from "primevue/dropdown";
    import FilePckr from "../../../components/file-pckr.vue"
    import CONTENT_TYPES from "../../../enums/content-types";
    import {PageType} from "../../../types/page";

    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Contenu(s)' , to: '/admin/contents' }
    })

    const breadCrumbsItems = computed(() => {
        let items: {label: string, to?: {name: string, params?: any}} [] = [];

        if(content?.value?.id) {
            items.push({label: content?.value?.name || 'N/A'})
        }

        items.push({label: content?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const contentsStore = useContentsStore();
    const contentsRefs = storeToRefs(contentsStore);
    const isFetchSingleContentPending = contentsRefs.isFetchSinglePending;
    const isSavePending = contentsRefs.isSavePending;
    const content = ref(contentsRefs.single);

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);

    const locale = configsRefs.locale;

    const contentTypes = ref(CONTENT_TYPES);

    const pagesStore = usePagesStore();
    const pagesRefs = storeToRefs(pagesStore);

    const pageOptions = ref<{ name: string, id: number }[]>([]);

    const form = useForm(
        route.params.content_id ? 'patch' : 'post',
        route.params.content_id
            ? '/api/contents/' + route.params.content_id
            : '/api/contents',
        {
            'name': null,
            'title': {fr: '', en: ''},
            'sur_title': {fr: '', en: ''},
            'subtitle': {fr: '', en: ''},
            'content': {fr: '', en: ''},
            'picture_alt': {fr: '', en: ''},
            'type': null,
            'picture': null,
        }
    );

    const typeOptions = ref<{ name: string, id: number }[]>([]);

    typeOptions.value = Object.entries(contentTypes.value.labels).map( content => {
        return {id: content[0], name: content[1]}
    });

    onBeforeMount( async () => {
        if(route.params.content_id) {
            await contentsStore.getItem({id: String(route.params.content_id), includes: ['picture']})
        }else {
            content.value = {
                'name': null,
                'title': {fr: '', en: ''},
                'sur_title': {fr: '', en: ''},
                'subtitle': {fr: '', en: ''},
                'content': {fr: '', en: ''},
                'picture_alt': {fr: '', en: ''},
                'type': null,
                'picture': null,
            }
        }

        await pagesStore.getItems()

        pageOptions.value = pagesRefs.list.value.map((page: PageType) => {
            return {name: page.title, id: page.id}
        });

        form.setData({
            ...content.value,
            type: typeOptions.value.find( (type) => Number(type.id) === Number(content.value.type)),
            page: pageOptions.value.find( (page) => Number(page.id) === Number(content.value.page_id))
        })
    })

    const handleSubmit = () => {
        contentsRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                type: untouchedData?.type?.id || null,
                page: untouchedData?.page?.id || null,
            })
            .submit()
            .then( () => {
                if(route.params.content_id) {
                    toast.add({severity: 'success', summary: 'Bravo', detail: 'Le content a bien été modifié', life: 8000});
                }else {
                    toast.add({severity: 'success', summary: 'Bravo', detail: 'Le content a bien été créé', life: 8000});
                }

                return router.push({name: 'contents'});
            })
            .catch(error => {
                if(error.response.status === 422) {
                    toast.add({'severity': 'warn', 'summary': 'Attention', 'detail': 'Il y a des erreurs dans le content', life: 8000})
                }
                if(error.response.status === 500) {
                    toast.add({'severity': 'error', 'summary': 'Attention', 'detail': 'Il y a une erreur serveur', life: 8000})
                }
                form.setData({...untouchedData});
            })
            .finally( () => {
                contentsRefs.isSavePending.value = false;
            })
    }

    onBeforeUnmount( () => {
        contentsRefs.single.value = null;
    })

    const isFilepondPending = ref(false);

    const handleStartLoad = ({files, id}: {files: [], id: number}) => {
        form.setData({ [id]: files.map((file:{serverId:string}) => file.serverId) })
        isFilepondPending.value = true;
    }

    const handleEndLoad = ({files, id}: {files: [], id: number}) => {
        form.setData({ [id]: files.map((file:{serverId:string}) => file.serverId) })
        isFilepondPending.value = false;
    }
</script>
