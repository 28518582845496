export default {
    values: Object.freeze ({
        ROOT: Symbol(1),
        ADMIN: Symbol(2),
        INSPECTOR: Symbol(3),
        MANAGER: Symbol(4),
        TEAM: Symbol(5),
    }),
    labels: {
        1: 'Root',
        2: 'Administrateur',
        3: 'Inspecteur',
        4: 'Manager',
        5: 'Employé'
    }
}
