<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ reason?.id ? 'Modifier une raison de retour' : 'Ajouter une raison de retour' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="label" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="label"
                    type="text"
                    label="label"
                    v-model="form.label[locale]"
                    :invalid="form.invalid('label')"
                    @change="form.validate('label')"
                />
                <small v-if="form.invalid('label')" class="text-red-500"> {{ form.errors.label }} </small>
            </div>

            <div class="flex flex-col gap-2 flex-1">
                <label for="label" class="text-dark-slate-600 uppercase tracking-widest">Type</label>
                <Select
                    v-model="form.type"
                    :options="typesOption"
                    optionLabel="name"
                    placeholder="Sélectionnez un type"
                    :invalid="form.invalid('type')"
                />
                <small v-if="form.invalid('type')" class="text-red-500"> {{ form.errors.type }} </small>
            </div>

            <div>
                <Button @click="handleSubmit">
                    {{ reason?.id ? 'Modifier' : 'Ajouter' }}
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount} from "vue";

    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";
    import Select from "primevue/select";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useReasonsStore, useConfigsStore} from "../../../store";
    import COMPLAINT_TYPES from "../../../enums/complaint-types";
    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";

    const router = useRouter()
    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Raison(s)' , route: {name: 'reasons'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (reason?.value?.id) {
            items.push({label: reason?.value?.label || 'N/A'})
        }

        items.push({label: reason?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const reasonsStore = useReasonsStore();
    const reasonsRefs = storeToRefs(reasonsStore);
    const reason = reasonsRefs.single;
    const isFetchPending = reasonsRefs.isFetchSinglePending;

    const typesOption = Object.entries(COMPLAINT_TYPES.labels).map( type => {
        return {id: type[0], name: type[1]}
    });

    onBeforeMount(async () => {
        if (route.params.id) {
            await reasonsStore.getItem({id: String(route.params.id), includes: ['users']})
        } else {
            reason.value = {
                label: {fr: ""},
                type: null
            }
        }

        form.setData({
            ...reason?.value,
            label: reason?.value?.translatable?.label || {fr: ''},
            type: typesOption?.find((type:any) => Number(type.id) == Number(reason?.value?.type)) || null,
        })
    })

    const form = useForm(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/complaint-reasons/' + route.params.id
            : '/api/complaint-reasons',
        {
            label: {fr: ""},
            type: null
        }
    );

    const handleSubmit = () => {
        reasonsRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .setData({
                type: untouchedData?.type?.id || null,
            })
            .submit()
            .then(() => {
                if (route.params.form_id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La raison a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La raison a bien été créé'})
                }

                return router.push({name: 'reasons'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                reasonsRefs.isSavePending.value = false;
            })
    }
</script>
