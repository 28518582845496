<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        class="mb-6"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isFetchPending || isFetchRestaurantsPending || isSaveRestaurantsPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <h1 class="mr-6 text-5xl uppercase font-title">
                Gestion stock max pour {{ product?.name }}
            </h1>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <DataTable
                :value="product_restaurant"
                :loading="isFetchRestaurantsPending"
                :rows="pagination?.per_page"
                :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
                :totalRecords="pagination?.total"
                @page="handlePageChange($event)"
                @sort="handleSortChange($event)"
                lazy paginator showGridlines stripedRows removableSort
                currentPageReportTemplate="{currentPage}/{totalPages}"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
            >
                <Column header="Nom" field="name" :sortable="true" sort-field="name"></Column>

                <Column header="Qu. max. (information)" field="max_quantity_informative" :sortable="true" #body="slotProps">
                    <InputNumber
                        id="max_quantity_informative"
                        name="max_quantity_informative"
                        v-model="slotProps.data.max_quantity_informative"
                    />
                </Column>

                <Column header="Qu. max. (contraignant)" field="max_quantity_blocking" :sortable="true" #body="slotProps">
                    <InputNumber
                        id="max_quantity_blocking"
                        name="max_quantity_blocking"
                        v-model="slotProps.data.max_quantity_blocking"
                    />
                </Column>

                <Column header="Cons. par semaine" field="consumption_per_week" :sortable="true" #body="slotProps">
                    <InputNumber
                        id="consumption_per_week"
                        name="consumption_per_week"
                        :maxFractionDigits="2"
                        v-model="slotProps.data.consumption_per_week"
                    />
                </Column>
            </DataTable>

            <div>
                <Button @click="handleSubmit">Valider</Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref} from "vue";

    import InputText from "primevue/inputtext";
    import InputNumber from "primevue/inputnumber";
    import InputIcon from "primevue/inputicon";
    import IconField from "primevue/iconfield";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import Breadcrumb from "primevue/breadcrumb";
    import ProgressSpinner from "primevue/progressspinner";

    import {useRoute, useRouter} from "vue-router";
    import useDebounce from "../../../composables/debounce";

    import {storeToRefs} from "pinia";
    import {useProductsStore, useConfigsStore} from "../../../store";
    import {RestaurantType} from "@/backoffice/types/restaurant";
    import {ProductType} from "../../../types/product";

    const route = useRoute()
    const router = useRouter()

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const productsStore = useProductsStore();
    const productsRefs = storeToRefs(productsStore);
    const pagination = productsRefs.pagination;
    const isFetchPending = productsRefs.isFetchSinglePending;
    const isFetchRestaurantsPending = productsRefs.isFetchRestaurantsPending;
    const isSaveRestaurantsPending = productsRefs.isSaveRestaurantsPending;
    const product = productsRefs.single;
    const restaurants = productsRefs.restaurants;

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Produits' , route: {name: 'products'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (product?.value?.id) {
            items.push({label: product?.value?.name || 'N/A'})
        }

        items.push({label: 'Stock max'})

        return items;
    });

    let product_restaurant = ref();

    onBeforeMount(async () => {
        await productsStore.getItem({id: route.params.id});
        await productsStore.getRestaurants({id: String(route.params.id)});

        product_restaurant.value = restaurants.value.map( (restaurant:RestaurantType) => {
            return {
                id: restaurant.id,
                name: restaurant.name,
                max_quantity_informative: restaurant.products.find( (product:ProductType) => Number(product.id) === Number(route.params.id))?.product_restaurant.max_quantity_informative,
                max_quantity_blocking: restaurant.products.find( (product:ProductType) => Number(product.id) === Number(route.params.id))?.product_restaurant.max_quantity_blocking,
                consumption_per_week: restaurant.products.find( (product:ProductType) => Number(product.id) === Number(route.params.id))?.product_restaurant.consumption_per_week,
            }
        });
    })

    const handlePageChange = (event:any) => {
        pagination.value.per_page = event.rows;
        pagination.value.current_page = event.page + 1;
        productsStore.getRestaurants({id: String(route.params.id)});
    }

    const handleSortChange = (event:any) => {
        productsStore.getRestaurants({id: String(route.params.id), sort_by: event.sortField, desc: event.sortOrder === 1})
    }

    const handleGlobalFilterChange = (value:string) => {
        productsStore.getRestaurants({id: String(route.params.id), search: {label: value}})
    }

    const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
        handleGlobalFilterChange(event);
    }, 1000);

    const handleSubmit = () => {
        productsStore.saveRestaurants({id: product.value.id, data: product_restaurant.value}).then( () => {
            router.push({name: 'products'})
        });
    }
</script>
