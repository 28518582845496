<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Retourner des produits
                </h1>
            </div>
        </div>

        <DataTable
            :value="complaint?.items.data"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="complaint?.items?.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Produit retourné" #body="slotProps">
                {{ slotProps.data?.label }}
            </Column>

            <Column header="Prix" #body="slotProps">
                {{ Number(slotProps.data.extra_attributes.inventory_price) }} €
            </Column>

            <Column header="Conditionnement" #body="slotProps">
                {{ slotProps.data?.extra_attributes?.inventory_conditioning_quantity }}
                {{ slotProps.data?.extra_attributes?.conditioning_label }}
            </Column>

            <Column header="Quantité" field="quantity" :sortable="true" #body="slotProps">
                {{ slotProps.data?.quantity }}
            </Column>

            <Column header="Coût" #body="slotProps">
                {{ Number(slotProps.data.extra_attributes?.inventory_price *  slotProps.data?.quantity).toFixed(2) }} €
            </Column>

            <Column header="Raison" #body="slotProps">
                {{ slotProps.data?.reason?.label }}
                {{ slotProps.data?.complaint_reason_note }}
            </Column>
        </DataTable>

        <div class="flex flex-col gap-2 mb-6">
            <label for="order_id" class="text-dark-slate-600 uppercase tracking-widest">Référence commande</label>
            {{complaint?.order?.reference }}
        </div>

        <div class="flex gap-2 flex-col mb-6">
            <label for="note" class="text-surface-400 tracking-widest">Informations complémentaires</label>
            <div v-html="complaint?.note" />
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";
import {useComplaintsStore} from "../../../store";
import {storeToRefs} from "pinia";

import Column from "primevue/column"
import DataTable from "primevue/datatable"

import {useRoute} from "vue-router";
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";
import ProgressSpinner from "primevue/progressspinner";

const breadCrumbsFirstItem = computed( () => { return { label: 'Retours' , route: {name: 'returns' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: complaint?.value?.restaurant?.name})

    items.push({label: complaint?.value?.supplier?.name})

    items.push({label: 'Détail'})

    return items;
});

const route = useRoute()

const pagination = ref({
    per_page: 50
})

const complaintsStore = useComplaintsStore()
const complaintsRefs = storeToRefs(complaintsStore)
const complaint = complaintsRefs.single
const isFetchSinglePending = complaintsRefs.isFetchSinglePending

onBeforeMount(async () => {
    await complaintsStore.getItem({id: route.params.id, includes: ['restaurant', 'supplier', 'items', 'items.product.conditioning', 'items.reason']})
});

const handlePageChange = (event:any) => {

}

const handleSortChange = (event:any) => {

}

</script>
