<template>
    <template v-if="!isLoading">
        <div class="flex flex-col gap-2 mb-6">
            <label for="title" class="text-dark-slate-600 uppercase tracking-widest">Question</label>
            <InputText
                id="title"
                type="text"
                label="label"
                v-model="form.title[locale]"
                :invalid="form.invalid('title')"
                @change="form.validate('title')"
            />
            <small v-if="form.invalid('title')" class="text-red-500"> {{ form.errors.title }} </small>
        </div>

        <div class="flex flex-col gap-2 mb-6">
            <label for="note" class="text-dark-slate-600 uppercase tracking-widest">Commentaire</label>
            <InputText
                id="note"
                type="text"
                label="label"
                v-model="form.note[locale]"
                :invalid="form.invalid('note')"
                @change="form.validate('note')"
            />
            <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
        </div>

        <div class="flex flex-col gap-2 mb-6">
            <label for="is_public" class="text-dark-slate-600 uppercase tracking-widest">Public ?</label>
            <ToggleSwitch id="is_public" name="is_public" v-model="form.is_public" />
            <small v-if="form.invalid('is_public')" class="text-red-500"> {{ form.errors.is_public }} </small>
        </div>

        <div class="flex flex-col gap-2 mb-6">
            <label for="survey_response_id" class="text-dark-slate-600 uppercase tracking-widest">Type de réponses attendue</label>
            <Select
                id="survey_response_id"
                name="survey_response_id"
                optionLabel="title"
                :options="responses"
                placeholder="Sélectionnez une réponse attendue"
                v-model="form.survey_response_id"
            />
            <small v-if="form.invalid('survey_response_id')" class="text-red-500"> {{ form.errors.survey_response_id }} </small>
        </div>

        <div class="flex flex-col gap-2 mb-6">
            <label for="illustration" class="text-dark-slate-600 uppercase tracking-widest">Photo explicative</label>
            <FilePckr
                id="illustration"
                :current-url="params?.question?.illustration?.url"
                :accepted-file-types="config.media['mime-types'].image.join(',')"
                v-model="form.illustration"
                @start-load="handleStartLoadFiles"
                @end-load="handleEndLoadFiles"
            />
            <small v-if="form.invalid('illustration')" class="text-red-500"> {{ form.errors.illustration }} </small>
        </div>

        <Button @click.prevent="handleSubmit" label="Valider" />
    </template>
</template>

<script lang="ts" setup>
import {inject, onBeforeMount, ref} from "vue";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import {useForm} from "laravel-precognition-vue";
    import {useConfigsStore, useResponsesStore} from "../../store";
    import {storeToRefs} from "pinia";
    import ToggleSwitch from "primevue/toggleswitch";
    import Select from "primevue/select";
    import FilePckr from "../../components/file-pckr.vue";

    const dialogRef = inject('dialogRef');
    const isSavePending = ref(false);

    const configsStore = useConfigsStore();
    const { locale, config } = storeToRefs(configsStore)

    const params = ref(dialogRef.value.data);

    const responsesStore = useResponsesStore();
    const { list: responses, isFetchListPending: isLoading } = storeToRefs(responsesStore)

    onBeforeMount( async () => {
        await responsesStore.getItems();

        if(params?.value?.question?.id) {
            console.warn(params?.value?.question);
            form.setData({
                ...params.value.question,
                illustration: '',
                title: params.value.question.translatable.title.fr ? params.value.question.translatable.title : {fr: ''},
                note: params.value.question.translatable.note.fr ? params.value.question.translatable.note : {fr: ''},
                survey_response_id: responses.value.find( (response:any) => Number(response.id) === Number(params?.value?.question.survey_response_id))
            })
        }
    })

    const form = useForm(
        params?.value?.question?.id ? 'put' :'post',
        () => params?.value?.question?.id ?
            '/api/surveys/questions/' + params?.value?.question?.id :
            '/api/surveys/questions',
        {
            title: {fr: ''},
            note: {fr: ''},
            is_public: 0,
            survey_response_id: null,
            survey_section_id: params?.value?.section?.id,
            illustration: ''
        }
    );

    const handleSubmit = () => {
        isSavePending.value = true;

        const untouchedData = form.data();

        form
            .setData({
                is_public: untouchedData.is_public ? 1 : 0,
                survey_section_id: params?.value?.section?.id,
                survey_response_id: untouchedData.survey_response_id.id
            })
            .submit()
            .then(() => {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: params?.value?.question?.id ? 'La question a bien été modifiée' : 'La question a bien été ajoutée'})

                dialogRef.value.close()
            })
            .catch(error => {
                form.setData({...untouchedData});

                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                }
                if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                }
            })
            .finally(() => {
                isSavePending.value = false;
            })
    }

    const isFilepondPending = ref(false);

    const handleStartLoadFiles = ({files, id}: { files: [], id: number }) => {
        form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)[0]})
        isFilepondPending.value = true;
    }

    const handleEndLoadFiles = ({files, id}: { files: [], id: number }) => {
        form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)[0]})
        isFilepondPending.value = false;
    }
</script>
