import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/losses/List.vue"
import CreateSupplier from "../views/backoffice/losses/create/Supplier.vue";
import Store from "../views/backoffice/losses/create/Products.vue";
import Show from "../views/backoffice/losses/Show.vue";

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/losses',
        children: [
            {
                path: '',
                name: 'losses',
                component: List,
            },
            {
                path: '/admin/losses/create/supplier',
                name: 'loss-create-supplier',
                component: CreateSupplier,
            },
            {
                path: '/admin/losses/create/products',
                name: 'loss-create-products',
                component: Store,
            },
            {
                path: '/admin/losses/:id/show',
                name: 'loss-show',
                component: Show,
            }
        ]
    }
]

export default module;
