import Save from "../views/backoffice/pages/Save.vue";
import List from "../views/backoffice/pages/List.vue";

import { RouteRecordRaw } from "vue-router";

const module: Array<RouteRecordRaw> = [
    {
        path: '/admin/pages',
        name: 'pages',
        component: List,
    },
    {
        path: '/admin/pages/save/:page_id?',
        name: 'page-save',
        component: Save,
    },
];

export default module;
