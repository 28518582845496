<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Pertes
                </h1>
            </div>

            <div>
                <Button
                    label="+ Ajouter"
                    @click.prevent="handleAddProduct"
                    size="small"
                />
            </div>
        </div>

        <small v-if="form.invalid('items')" class="text-red-500"> {{ form.errors.items }} </small>

        <DataTable
            :value="complaint?.items"
            :rows="1000"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            @sort="handleSortChange($event)"
            paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats, cliquez sur
                    <Button
                        label="+ Ajouter"
                        @click.prevent="handleAddProduct"
                        size="small"
                        class="mx-2"
                    />
                </p>
            </template>

            <Column header="Produits perdus" field="name" #body="slotProps">
                {{ slotProps.data?.product?.name }}
            </Column>

            <Column header="Prix" #body="slotProps">
                {{ Number(slotProps.data.product.inventory_price) }} €
            </Column>

            <Column header="Conditionnement" field="conditioning" :sortable="true" #body="slotProps">
                {{ slotProps.data?.product?.inventory_conditioning_quantity }}
                {{ slotProps.data?.product?.conditioning?.label }}
            </Column>

            <Column header="Quantité" field="quantity" #body="slotProps">
                {{ slotProps.data?.quantity }}
            </Column>

            <Column header="Coût" #body="slotProps">
                {{ Number(slotProps.data.product.inventory_price *  slotProps.data?.quantity) }} €
            </Column>

            <Column header="Raison" field="raisons" #body="slotProps">
                {{ slotProps.data?.complaint_reason?.label }}
                {{ slotProps.data?.complaint_reason_note }}
            </Column>
        </DataTable>

        <div class="flex gap-2 flex-col mb-6">
            <label for="note" class="text-surface-400 tracking-widest">Informations complémentaires</label>
            <Editor
                id="note"
                name="note"
                class="w-full"
                v-model="form.note"
                :invalid="form.invalid('note')"
                @change="form.validate('note')"
            />
            <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
        </div>

        <div>
            <Button @click="handleSubmit">
                Ajouter
            </Button>
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, onUnmounted, ref} from "vue";
import {useComplaintsStore, useConfigsStore, useRestaurantsStore} from "../../../../store";
import {storeToRefs} from "pinia";

import Button from "primevue/button"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import Editor from "primevue/editor"

import {useRouter} from "vue-router";
import {useDialog} from "primevue/usedialog";
import AddProductToComplaint from "../../../../components/dialogs/AddProductToComplaint.vue";
import {useForm} from "laravel-precognition-vue";
import COMPLAINT_TYPES from "../../../../enums/complaint-types"
import Breadcrumbs from "../../../../components/partials/backoffice/breadcrumbs.vue";

const breadCrumbsFirstItem = computed( () => { return { label: 'Pertes' , route: {name: 'losses' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: complaint?.value?.restaurant.name})

    items.push({label: complaint?.value?.supplier.name})

    items.push({label: 'Choix produits'})

    return items;
});

const router = useRouter()
const dialog = useDialog()

const complaintsStore = useComplaintsStore();
const complaintsRefs = storeToRefs(complaintsStore);
const complaint = complaintsRefs.single;

const restaurantsStore = useRestaurantsStore()
const restaurantsRefs = storeToRefs(restaurantsStore)
const products = restaurantsRefs.products

const configsStore = useConfigsStore()

onBeforeMount(async () => {
    if (!complaint?.value?.restaurant_id || !complaint?.value?.supplier_id) {
        complaint.value = null;
        await router.push({name: 'losses'})
    }
});

onUnmounted(() => {
    complaint.value = null;
    products.value = null;
})

const handlePageChange = (event: any) => {

}

const handleSortChange = (event: any) => {

}

const form = useForm(
    'post',
    '/api/complaints',
    {
        'type': null,
        'order_id': null,
        'supplier_id': null,
        'restaurant_id': null,
        'note': '',
        'items': [],
    }
);

const handleSubmit = (event: any) => {
    complaintsRefs.isSavePending.value = true;

    const untouchedData = form.data();

    form.setData({
        type: Number(COMPLAINT_TYPES.values.LOSS.description),
        order_id: untouchedData?.order_id?.id || null,
        restaurant_id: complaint?.value?.restaurant_id || null,
        supplier_id: complaint?.value?.supplier_id || null,
        items: complaint?.value?.items?.map((item: any) => {
            if (item.product) {
                return {
                    product_id: item.product.id,
                    quantity: item.quantity,
                    complaint_reason_id: item.complaint_reason?.id,
                    complaint_reason_note: item.complaint_reason_note,
                    uploads: item.uploads
                }
            }
        })
    })
        .submit()
        .then(() => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La perte a bien été déclarée'})

            return router.push({name: 'losses'});
        })
        .catch(error => {
            if (error?.response?.status === 422) {
                configsStore.addToast({
                    severity: 'warn',
                    summary: 'Attention',
                    detail: 'Il y a des erreurs dans le formulaire'
                })
            }
            if (error?.response?.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            complaintsRefs.isSavePending.value = false;
        })
}

const handleAddProduct = () => {
    dialog.open(AddProductToComplaint, {
        props: {
            header: 'Ajouter un produit perdu',
            modal: true
        },
        data: {
            complaint: complaint,
            type: Number(COMPLAINT_TYPES.values.LOSS.description)
        },
        onClose: (payload: any) => {
            if (payload.data?.quantity && payload.data?.product?.id) {
                complaint.value.items.push(payload.data);
            }
        }
    })
}
</script>
