import {RouteRecordRaw} from "vue-router";

import List from "../views/backoffice/suppliers/List.vue"
import Save from "../views/backoffice/suppliers/Save.vue"
import Show from "../views/backoffice/suppliers/Show.vue"
import Schedule from "../views/backoffice/suppliers/Schedule.vue"

const module : Array<RouteRecordRaw> = [
    {
        path: '/admin/suppliers',
        children: [
            {
                path: '',
                name: 'suppliers',
                component: List,
            },
            {
                path: '/admin/suppliers/:id?/save',
                name: 'supplier-save',
                component: Save,
                meta: { authorize: [1] }
            },
            {
                path: '/admin/suppliers/:id?/show',
                name: 'supplier-show',
                component: Show,
            },
            {
                path: '/admin/suppliers/:id/schedule',
                name: 'supplier-schedule',
                component: Schedule,
            }
        ]
    },
]

export default module;
