<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div v-if="isFetchPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    {{ conditioning?.id ? 'Modifier un conditionnement' : 'Ajouter un conditionnement' }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
                <label for="label" class="text-dark-slate-600 uppercase tracking-widest">Nom</label>
                <InputText
                    id="label"
                    name="label"
                    v-model="form.label[locale]"
                    :invalid="form.invalid('label')"
                    @change="form.validate('label')"
                />
                <small v-if="form.invalid('label')" class="text-red-500"> {{ form.errors.label }} </small>
            </div>

            <div>
                <Button @click="handleSubmit">{{ conditioning?.id ? 'Modifier' : 'Ajouter' }}</Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount} from "vue";

    import InputText from "primevue/inputtext";
    import Breadcrumb from "primevue/breadcrumb";
    import Button from "primevue/button";

    import {useRoute, useRouter} from "vue-router";
    import {useForm} from "laravel-precognition-vue";

    import {storeToRefs} from "pinia";
    import {useConditioningsStore, useConfigsStore} from "../../../store";
    import ProgressSpinner from "primevue/progressspinner";
    import Breadcrumbs from "@/backoffice/components/partials/backoffice/breadcrumbs.vue";

    const route = useRoute()
    const router = useRouter();

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Conditionnements' , route: {name: 'conditionings'} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        if (conditioning?.value?.id) {
            items.push({label: conditioning?.value?.label || 'N/A'})
        }

        items.push({label: conditioning?.value?.id ? 'Modifier' : 'Ajouter'})

        return items;
    });

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);
    const locale = configsRefs.locale;

    const conditioningsStore = useConditioningsStore();
    const conditioningsRefs = storeToRefs(conditioningsStore);
    const conditioning = conditioningsRefs.single;
    const isFetchPending = conditioningsRefs.isFetchSinglePending;

    onBeforeMount(async () => {
        if (route.params.id) {
            await conditioningsStore.getItem({id: String(route.params.id)})
        } else {
            conditioning.value = {
                label: {
                    fr: '',
                    en: ''
                }
            }
        }

        form.setData({
            ...conditioning?.value,
            label: conditioning?.value?.translatable?.label || {fr: ""}
        })
    })

    const form = useForm(
        route.params.id ? 'patch' : 'post',
        route.params.id
            ? '/api/conditionings/' + route.params.id
            : '/api/conditionings',
        {
            label: {fr: '', en: ''},
        }
    );

    const handleSubmit = () => {
        conditioningsRefs.isSavePending.value = true;

        let untouchedData = form.data();

        form
            .submit()
            .then(() => {
                if (route.params.form_id) {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le conditionnement a bien été modifié'})
                } else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le conditionnement a bien été créé'})
                }

                return router.push({name: 'conditionings'});
            })
            .catch(error => {
                if (error.response.status === 422) {
                    configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
                } else if (error.response.status === 500) {
                    configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
                } else {
                   configsStore.addToast({severity: 'error', summary: 'Attention', detail: error.response.data.message})
                }

                form.setData({...untouchedData});
            })
            .finally(() => {
                conditioningsRefs.isSavePending.value = false;
            })
    }
</script>
