<template>
    <Breadcrumb :home="breadCrumbsFirstItem" />

    <div class="mx-20 mt-4 mb-4">
        <h2 class="mr-2 text-4xl text-dark-slate-800 font-semibold mb-6">Configuration</h2>
        <div class="flex items-center  mb-4">
            <h3 class="text-2xl mr-6">Catégories</h3>
            <Button @click.prevent="handleCreateOrEditCategory" severity="primary" class="w-[130px] flex justify-center text-[15px]"><i class="pi pi-plus-circle mr-2" style="font-size: 1.5rem"></i>Ajouter</Button>
        </div>
        <template v-for="category in categories">
            <h4 class="capitalize text-lg mb-2">
                {{ category.name[locale] }} ({{ category.id}}) <ProgressSpinner v-if="isCategoryDeletePending === category.id"/>
                <Button label="Modifier"
                        @click.prevent="handleCreateOrEditCategory(category)"
                        severity="secondary"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Éditer'">
                    <i class="pi pi-pencil"></i>
                </Button>
                <Button
                    label="Supprimer"
                    @click.prevent="handleDeleteCategory(category)"
                    severity="danger"
                    size="small"
                    class="mr-2"
                    v-tooltip="'Supprimer'">
                    <i class="pi pi-trash"></i>
                </Button>
            </h4>
            <ul class="list-disc pl-4 mb-6">
                <li v-for="category in category.categories" class="capitalize mb-2">
                    {{ category.name[locale] }} ({{ category.id}}) <ProgressSpinner v-if="isCategoryDeletePending === category.id"/>
                    <Button
                        label="Modifier"
                        @click.prevent="handleCreateOrEditCategory(category)"
                        severity="secondary"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Éditer'">
                        <i class="pi pi-pencil"></i>
                    </Button>
                    <Button
                        label="Supprimer"
                        @click.prevent="handleDeleteCategory(category)"
                        severity="danger"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Supprimer'">
                        <i class="pi pi-trash"></i>
                    </Button>
                </li>
            </ul>
        </template>
    </div>
</template>

<script lang="ts" setup>
import {useCategoriesStore, useConfigsStore} from "../../../store";
import {storeToRefs} from "pinia";
import {onBeforeMount, ref} from "vue";
import Button from "primevue/button";
import {CategoryType} from "../../../types/category";
import {useRouter} from "vue-router";
import Breadcrumb from "primevue/breadcrumb";
import ProgressSpinner from "primevue/progressspinner";

const router = useRouter();

const categoriesStore = useCategoriesStore();
const categoriesRefs = storeToRefs(categoriesStore);
const categories = categoriesRefs.list;
const isCategoryDeletePending = ref(categoriesRefs.isDeletePending)

const configsStore = useConfigsStore();
const configsRefs = storeToRefs(configsStore);

const locale = configsRefs.locale;

const breadCrumbsFirstItem = ref({
    label: 'Catégorie(s)'
});

onBeforeMount( () => {
    categoriesStore.getItems({includes: ['categories']});
})
const handleCreateOrEditCategory = (category:CategoryType) => {
    categoriesRefs.single.value = null
    router.push({name: 'category-save',  params: (category ? {category_id: category.id} : {}) });
}

import {useConfirm} from "primevue/useconfirm";
const confirm = useConfirm()

const handleDeleteCategory = (category:CategoryType) => {
    confirm.require({
        message: 'Êtes vous sur de vouloir supprimer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        acceptClass: 'p-button-danger',
        accept: () => categoriesStore.deleteItem(category.id)
    })
}
</script>
