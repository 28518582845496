<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        class="mb-6"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-8 justify-between items-center">
            <div class="flex items-center gap-2">
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Détail la commande #{{ order.reference }}
                </h1>
            </div>
        </div>

        <div class="mb-4">
            <p><strong>Restaurant</strong>: {{ order?.restaurant?.name }}</p>
            <p><strong>Fournisseur</strong>: {{ order?.supplier?.name }}</p>
        </div>

        <div class="flex justify-end mb-6">
            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon
                        class="pi pi-search"
                        pt:root="bg-white border-none"
                    />
                    <InputText
                        placeholder="Recherche"
                        @update:modelValue="handleGlobalFilterChangeDebounced"
                        pt:root="border-b p-3"
                    />
                </IconField>
            </div>
        </div>

        <DataTable
            :value="order.items.data"
            :loading="isFetchSinglePending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :globalFilterFields="['status']"
            :totalRecords="order.items.data.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy
            paginator
            showGridlines
            stripedRows
            removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                root: {class: 'mb-12'}
            }"
        >
            <Column header="Nom" field="name" :sortable="true" #body="slotProps">
                <span class="mr-1" v-if="form.items.find( (item:any) => item.id === slotProps.data.id)" :class="form.items.find( (item:any) => item.id === slotProps.data.id).invoiced_quantity > slotProps.data.received_quantity ? 'text-red-500' : ''">
                    {{slotProps.data.product.name }}
                </span>
                <template v-if="slotProps.data.product.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle"
                       v-tooltip="slotProps.data.product.note"
                    ></i>
                </template>
            </Column>

            <Column header="Prix" field="price" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.price.toFixed(2) }} €
            </Column>

            <Column header="Conditionnement" field="conditioning" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.conditioning_quantity }}
                {{ slotProps.data.product.conditioning.label }}
            </Column>

            <Column header="Q. Commandée" field="quantity" :sortable="true" />

            <Column header="Q. Reçue" field="received_quantity" :sortable="true"/>

            <Column header="Q. Facturée" field="invoiced_quantity" :sortable="true" #body="slotProps">
                <InputText v-if="form.items.find( (item:any) => item.id === slotProps.data.id)" placeholder="Quantité facturée" v-model="form.items.find( (item:any) => item.id === slotProps.data.id).invoiced_quantity"></InputText>
                <p><small v-if="slotProps.data.invoiced_quantity && slotProps.data.invoiced_quantity != slotProps.data.received_quantity" class="text-orange-500">Êtes-vous sur de la quantité encodée&nbsp;?</small></p>
            </Column>
        </DataTable>

        <div class="mb-6 flex justify-between">
            <div>
                <p><strong>TOTAL COMMANDE:</strong>
                    {{ order.items.data.reduce((sum:number, item:any) => sum + item.quantity, 0) }}
                    ({{ order.items.data.reduce((sum:number, item:any) => sum + (item.price*item.quantity), 0).toFixed(2) }} €)
                </p>
                <p><strong>TOTAL REÇU:</strong>
                    {{ order.items.data.reduce((sum:number, item:any) => sum + Number(item.received_quantity), 0) }}
                    ({{ order.items.data.reduce((sum:number, item:any) => sum + (Number(item.price) * Number(item.received_quantity)), 0).toFixed(2) }} €)
                </p>
                <p>
                    <strong>TOTAL FACTURE:</strong>
                    <span class="ml-1" :class="
                    order.items.data.reduce((sum:number, item:any) => sum + Number(item.invoiced_quantity), 0) >
                    order.items.data.reduce((sum:number, item:any) => sum + Number(item.received_quantity), 0) ? 'text-red-500' : ''">
                         {{ order.items.data.reduce((sum:number, item:any) => sum + Number(item.invoiced_quantity), 0) }}
                        ({{ order.items.data.reduce((sum:number, item:any) => sum + (Number(item.price) * Number(item.invoiced_quantity)), 0).toFixed(2) }} €)
                    </span>
                </p>
            </div>
            <div class="flex flex-col gap-2">
                <label for="invoice_number" class="text-dark-slate-600 uppercase font-bold tracking-widest">N° de facture*</label>
                <InputText
                    id="invoice_number"
                    type="text"
                    ref="invoice_number"
                    placeholder="Encodez le n° de facture"
                    v-model="form.invoice_number"
                    :invalid="form.invalid('invoice_number')"
                    @change="form.validate('invoice_number')"
                />
                <small v-if="form.invalid('invoice_number')" class="text-red-500"> {{ form.errors.invoice_number }} </small>
            </div>
        </div>

        <div class="flex gap-2 flex-col mb-6">
            <label for="note" class="text-surface-400 tracking-widest">Commentaires</label>
            <Editor
                id="note"
                name="note"
                class="w-full"
                v-model="form.note"
                :invalid="form.invalid('note')"
                @change="form.validate('note')"
            />
            <small v-if="form.invalid('note')" class="text-red-500"> {{ form.errors.note }} </small>
        </div>

        <div class="flex flex-col gap-4 justify-between mb-6">
            <div class="flex-1">
                <FilePckr
                    id="uploads"
                    multiple
                    v-model="form.uploads"
                    @end-load="handleLoadFile"
                />
            </div>
            <div>
                <Button @click.prevent="handleSubmit">Valider</Button>
            </div>
        </div>

        <div class="flex gap-4 flex-col">
            <Logs :logs="logs" />
        </div>
    </div>
</template>
<script setup lang="ts">
import Breadcrumb from "primevue/breadcrumb";
import {computed, onBeforeMount, ref} from "vue";
import {useConfigsStore, useOrdersStore} from "../../../store";
import {storeToRefs} from "pinia";

import Editor from "primevue/editor";
import Button from "primevue/button"
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import ProgressSpinner from "primevue/progressspinner";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";
import FilePckr from "../../../components/file-pckr.vue";
import useDebounce from "../../../composables/debounce";

import Logs from "../../../components/partials/backoffice/logs.vue"

const breadCrumbsFirstItem = computed( () => { return { label: 'Commandes' , route: {name: 'orders' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: order?.value?.restaurant.name})

    items.push({label: order?.value?.supplier.name})

    items.push({label: 'Réconcilier'})

    return items;
});

const router = useRouter();
const route = useRoute()

const ordersStore = useOrdersStore();
const ordersRefs = storeToRefs(ordersStore);
const isFetchSinglePending = ordersRefs.isFetchSinglePending;
const order = ordersRefs.single;
const logs = ordersRefs.logs

onBeforeMount(async () => {
    await ordersStore.getItem({id: route.params.id, includes: ['items', 'items.product', 'items.product.conditioning']})

    await ordersStore.getLogs({id: Number(route.params.id)});

    form.setData({
        items : order?.value?.items.data,
        status: order?.value?.status
    })
});

const handleGlobalFilterChange = (value:string) => {
    ordersStore.getItems({search: {search: value}})
}

const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
    handleGlobalFilterChange(event);
}, 1000);

const selectedStatus = ref(0)

const pagination = ref({
    per_page: 50,
    current_page: 1
});

const handlePageChange = (event:any) => {

}

const handleSortChange = (event:any) => {

}

const isFilepondPending = ref(false);

const handleLoadFile = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = true;
}

const form = useForm(
    'post',
    '/api/orders/' + route.params.id + '/invoiced',
    {
        invoice_number: null,
        uploads: [],
        note: null,
        items: [],
    }
);

const configsStore = useConfigsStore();

const handleSubmit = () => {
    ordersRefs.isSavePending.value = true;

    let untouchedData = form.data();

    form
        .submit()
        .then(() => {
            configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'La commande a bien été modifiée'})

            return router.push({name: 'orders'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }

            form.setData({...untouchedData});
        })
        .finally(() => {
            ordersRefs.isSavePending.value = false;
        })
}
</script>
