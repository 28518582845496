<template>
    <Breadcrumb :home="breadCrumbsFirstItem" />

    <DataTable
        scrollable scrollHeight="100vh"
        class="mx-20"
        :value="pages"
        :loading="isFetchListPending"
        :rows="pagination?.per_page"
        :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
        :globalFilterFields="['name']"
        :totalRecords="pagination?.total"
        @page="handlePageChange($event)"
        @sort="handleSortChange($event)"
        lazy
        paginator
        showGridlines
        stripedRows
        removableSort
        currentPageReportTemplate="{currentPage}/{totalPages}"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    >
        <template #empty> Aucune page pour l'instant </template>
        <template #loadingicon>
            <ProgressSpinner />
        </template>
        <template #header>
            <div class="flex justify-between py-4 px-2">
                <div class="flex items-center">
                    <h2 class="mr-2 text-4xl text-dark-slate-800">Page(s)</h2>
                    <Button @click.prevent="handleCreateOrEditPage" severity="primary" class="w-[130px] flex justify-center text-[15px]"><i class="pi pi-plus-circle mr-2"/>Ajouter</Button>
                </div>

                <div class="flex">
                    <span class="p-input-icon-left text-dark-slate-700">
                        <i class="pi pi-search" />
                        <InputText
                            placeholder="Recherche"
                            v-model="filters['global'].value"
                            class="small-input w-[230px] h-[40px] placeholder-dark-slate-700"
                            @update:modelValue="handleGlobalFilterChangeDebounced"
                        />
                    </span>
                </div>
            </div>
        </template>
        <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="col.sortable" :body="col.body">
            <template v-if="col.filterable" #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" placeholder="Search by name" />
            </template>
        </Column>
        <Column key="actions" header="Action(s)">
            <template #body="slotProps">
                <div class="flex items-center gap-2">
                    <Button
                        label="Voir"
                        @click.prevent="handleShowPage(slotProps.data)"
                        severity="secondary"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Voir'">
                        <i class="pi pi-eye"></i>
                    </Button>
                    <Button
                        label="Modifier"
                        @click.prevent="handleCreateOrEditPage(slotProps.data)"
                        severity="secondary"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Éditer'">
                        <i class="pi pi-pencil"></i>
                    </Button>
                    <Button
                        label="Supprimer"
                        @click.prevent="handleDeletePage(slotProps.data)"
                        severity="danger"
                        size="small"
                        class="mr-2"
                        v-tooltip="'Supprimer'">
                        <i class="pi pi-trash"></i>
                    </Button>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script setup lang="ts">
    import {ref, onBeforeMount, computed} from "vue";
    import Breadcrumb from "primevue/breadcrumb";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import {useConfigsStore, usePagesStore} from "../../../store";
    import { storeToRefs } from "pinia";
    import useDebounce from "../../../composables/debounce";
    import ProgressSpinner from "primevue/progressspinner";
    import {useRoute, useRouter} from "vue-router";
    import {useConfirm} from "primevue/useconfirm";
    import {PageType} from "../../../types/page";

    const router = useRouter();
    const confirm = useConfirm()

    const breadCrumbsFirstItem = computed( () => {
        return { label: 'Page(s)' , to: '/admin/pages' }
    })

    const configsStore = useConfigsStore();
    const configsRefs = storeToRefs(configsStore);

    const columns = [
        { field: 'id', header: 'ID'},
        { field: 'title', header: 'Titre', sortable: false, filterable: false},
    ];

    const pagesStore = usePagesStore();
    const pagesRefs = storeToRefs(pagesStore);

    const pagination = pagesRefs.pagination;
    const isFetchListPending = pagesRefs.isFetchListPending;
    const pages = pagesRefs.list;

    const fetchPagesList = () => {
        pagesStore.getItems({includes: []});
    }

    const handlePageChange = (event:any) => {
        pagination.value.per_page = event.rows;
        pagination.value.current_page = event.page + 1;
        fetchPagesList();
    }

    const handleSortChange = (event:any) => {
        pagesStore.getItems({sort_by: event.sortField })
    }

    const handleGlobalFilterChange = () => {
        pagesStore.getItems()
    }

    const handleGlobalFilterChangeDebounced = useDebounce(() => {
        handleGlobalFilterChange();
    }, 500);

    onBeforeMount(() => {
        fetchPagesList();
    })

    const handleDeletePage = (page:PageType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => pagesStore.deleteItem(page.id)
        })
    }

    const handleCreateOrEditPage = (page:PageType) => {
        pagesRefs.single.value = null
        router.push({name: 'page-save',  params: (page ? {page_id: page.id} : {}) });
    }

    const handleShowPage = (page:PageType) => {
        window.open(page.url);
    }
</script>
