<template>
    <Toast />
    <DynamicDialog />
    <ConfirmDialog />

    <template v-if="isBackoffice">
        <div class="app-wrapper">
            <transition name="slide">
                <AppMenu v-if="isMenuOpen" @onCloseMenu="handleCloseMenu"/>
            </transition>

            <main class="main">
                <header v-if="route.name !== 'login'" class="header flex items-center">
                    <div class="px-2 flex items-center menu-opener">
                        <IconBurger @click.prevent="handleOpenMenu" class="cursor-pointer"/>
                    </div>
                    <div class="flex items-center p-10">
<!--                        <Avatar image="./../../img/default-user.png" class="mr-4 border-white border-2" size="large" shape="circle" />-->
                        <div class="text-white flex">
                            <p class="text-[15px] flex items-center justify-between">
                                <i class="mr-2 pi pi-shield text-yellow-200"></i>
                                {{ authUser?.firstname }} {{ authUser?.lastname }}
                            </p>
                            <p @click="handleOpenUserMenu" class="flex items-center font-bold text-[14px] cursor-pointer">
                                Connecté
                                <i class="ml-2 pi pi-angle-down"></i>
                                <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
                            </p>
                        </div>
                    </div>
                </header>
                <section>
                    <RouterView/>
                </section>
            </main>

            <transition name="fade">
                <div class="menu-backdrop" v-if="isMenuOpen" @click.prevent="handleCloseMenu"></div>
            </transition>
        </div>
    </template>
    <template v-else-if="isAnonymousHandlingAuth">
        <main>
            <section>
                <RouterView/>
            </section>
        </main>
    </template>
</template>

<script setup lang="ts">
    import Avatar from "primevue/avatar";
    import Menu from "primevue/menu"
    import Toast from 'primevue/toast';
    import DynamicDialog from 'primevue/dynamicdialog';
    import ConfirmDialog from 'primevue/confirmdialog';

    import AppMenu from "../components/partials/backoffice/menu.vue"

    import IconBurger from "../../../svg/backoffice/burger.svg?component"
    import IconEpekta from "../../../svg/backoffice/epekta.svg?component"

    import { useRoute } from 'vue-router';

    import {onBeforeMount, onBeforeUnmount, ref} from "vue";
    import {useAuthStore, useConfigsStore} from "../store";
    import {storeToRefs} from "pinia";

    const route = useRoute();

    const authStore = useAuthStore();
    const authRefs = storeToRefs(authStore);

    const configStore = useConfigsStore();
    const {locale, config} = storeToRefs(configStore);

    let isMenuOpen = ref(true);

    const menu = ref();

    const items = ref([
        {
            label: 'Déconnexion',
            icon: 'pi pi-sign-out',
            command: () => {
                authStore.logout()
            }
        }
    ]);

    const handleOpenUserMenu = (event:Event) => {
        menu.value.toggle(event);
    };

    onBeforeMount( () => {
        if(window.innerWidth < 900) {
            isMenuOpen.value = false;
        }

        window.addEventListener('resize', () => {
            isMenuOpen.value = window.innerWidth >= 900;
        })

        configStore.getServerConfig()
    })

    onBeforeUnmount( () => {
        window.removeEventListener('resize', () => {

        })
    })

    const handleOpenMenu = () => {
        console.warn("open menu")
        isMenuOpen.value = true;
    }

    const handleCloseMenu = () => {
        isMenuOpen.value = false;
    }

    const authUser = authRefs.authUser;

    const isBackoffice = ref(route.path.startsWith('/admin') && route.name !== 'login' && route.name !== 'forgot-password' && route.name !== 'reset-password');
    const isAnonymousHandlingAuth = ref(['login', 'forgot-password', 'reset-password'].includes(route.name));
</script>

<style>
    .slide-enter-active {
        animation: slide-in 0.5s;
    }
    .slide-leave-active {
        animation: slide-in 0.5s reverse;
    }
    @keyframes slide-in {
        0% {
            transform: translateX(-300px);
        }
        100% {
            transform: translateX(0px);
        }
    }

    .fade-enter-active {
        animation: fade-in 0.5s;
    }
    .fade-leave-active {
        animation: fade-in 0.5s reverse;
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
</style>
