<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>
    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex justify-between items-start">
            <div class="mb-4 flex items-center gap-2">
                <h1 class="text-5xl uppercase font-title">
                    {{ new Intl.DateTimeFormat('fr-BE').format(new Date(audit.created_at)) }} -
                    {{ audit.restaurant.name }} -
                    {{ audit?.translatable?.title[locale] }}
                </h1>
            </div>
        </div>

        <div class="flex flex-col">
            <div v-for="section in audit?.sections?.data">
                <div class="bg-surface-50 p-2 rounded border border-surface-100 flex items-center justify-between cursor-pointer hover:opacity-80" @click="handleOpenSection(section.id)" v-if="section?.items?.data.filter( (item) => item.response_type !== 1).length > 0">
                    <div class="flex gap-10 items-center transition-opacity">
                        <h3 class="flex items-center gap-1">
                            <i class="pi" :class="openedSection === section.id ? 'pi-angle-up' : 'pi-angle-down'" />
                            {{ section.translatable.title[locale] }}
                        </h3>
                    </div>
                </div>
                <Transition name="fadeHeight">
                    <div v-show="openedSection === section.id" class="p-2 rounded-b-md border border-surface-100 transition-all">
                        <template v-for="item in section?.items?.data">
                            <div class="p-2 rounded-md relative mb-2" v-if="Number(item.response_type) !== 1">
                                <div v-show="Number(isCorrectedPending) === Number(item.id)" class="overlay bg-black/50 absolute w-full h-full top-0 left-0 flex items-center justify-center z-50">
                                    <ProgressSpinner pt:root="h-16 w-16"/>
                                </div>
                                <div class="flex gap-4 mb-2">
                                    <h3 class="font-bold">{{ item.extra_attributes.question }}</h3>
                                </div>
                                <p> {{ item.note }} </p>
                                <p class="flex flex-row-reverse gap-2">
                                    <label :for="`isCorrected-${item.id}`" >Vérifiée ?</label>
                                    <Checkbox
                                        :inputId="`isCorrected-${item.id}`"
                                        :name="`isCorrected-${item.id}`"
                                        v-model="isCorrected"
                                        :value="item.id"
                                        @change="handleChangeCorrection(item)"
                                    />
                                </p>
                            </div>
                        </template>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, onBeforeMount, ref } from "vue";

    import ProgressSpinner from "primevue/progressspinner";
    import Checkbox from "primevue/checkbox";

    import {useRoute} from "vue-router";

    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";

    import {storeToRefs} from "pinia";
    import {useSurveysStore, useConfigsStore} from "../../../store";
    import {useAuditsStore} from "../../../store/audit";
    import AddImageToAuditItemCorrection from "../../../components/dialogs/AddImageToAuditItemCorrection.vue";
    import {useDialog} from "primevue/usedialog";

    const route = useRoute()

    const breadCrumbsFirstItem = computed( () => {
        return { label: survey?.value?.translatable?.title[locale.value] , route: {name: 'survey-audits', params: {id: route.params.survey_id}} }
    })

    const breadCrumbsItems = computed(() => {
        let items: { label: string, to?: { name: string, params?: any } } [] = [];

        items.push({label: 'Contrôle'})

        return items;
    });

    const configsStore = useConfigsStore();
    const {locale} = storeToRefs(configsStore)

    const surveysStore = useSurveysStore();
    const surveysRefs = storeToRefs(surveysStore);
    const survey = surveysRefs.single;

    const auditsStore = useAuditsStore();
    const { single: audit, isFetchSinglePending, isCorrectedPending } = storeToRefs(auditsStore);
    const isCorrected = ref([])

    onBeforeMount(async () => {
        await auditsStore.getItem({id: String(route.params.id), includes: ['items', 'sections.items', 'sections.items.response', 'sections', 'restaurant', 'itemsCorrected']})
        isCorrected.value = audit.value.items.data.map( (item:any) => item.is_corrected ? item.id : null).filter( (item:number) => item)
    })

    const openedSection = ref(-1);
    const openedItem = ref(0);

    const handleOpenSection = (section_id:number) => {
        openedSection.value = openedSection.value === section_id ? 0 : section_id;
        openedItem.value = audit?.value?.sections?.data?.find( (section:any) => Number(section.id) === section_id)?.items?.data[0].survey_question_id
    }

    const dialog = useDialog();

    const handleChangeCorrection = async (item:any) => {
        await auditsStore.setCorrected({
            audit_id: Number(route.params.id),
            item_id: item.id,
            is_corrected: isCorrected.value.map( (id:number) => id).includes(item.id) ? 1 : 0,
            includes: ['items', 'sections.items', 'sections.items.response', 'sections', 'restaurant', 'itemsCorrected']
        })

        if(isCorrected.value.map( (id:number) => id).includes(item.id)) {
            dialog.open(AddImageToAuditItemCorrection, {
                props: {
                    header: 'Ajouter une image',
                    modal: true
                },
                data: {
                    item: item
                },
                onClose: () => {
                    console.warn("boite fermée")
                }
            })
        }
    }
</script>

<style>
    .fadeHeight-enter-active,
    .fadeHeight-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.01, 1, 0.01);
        max-height: 250px;
    }
    .fadeHeight-enter,
    .fadeHeight-leave-to
    {
        opacity: 0;
        max-height: 0;
    }
</style>
