<template>
    <ProgressSpinner v-if="isLoading" />
    <div v-else v-for="(events, date) in logs">
        <h3 class="font-bold mb-2 capitalize">{{ getHumanizedCreateAt(date) }}</h3>

        <template v-if="events[0].event === 'created'">
            <h4 class="mb-3">Commande effectuée</h4>
        </template>

        <template v-else>
            <template v-if="events[0].event === 'updated'">
                <h4 class="mb-3">Commande modifiée</h4>
            </template>

            <div v-for="event in events">
                <template v-if="event.event === 'updated'">
                    <ul class="pl-4" v-for="(value, name) in event.properties.old">
                        <li class="ml-4 list-disc">
                            <div class="flex gap-2">
                                <template v-if="String(name) === 'status'">
                                    Le <strong>statut</strong> est passé de "{{ ORDER_STATUS.labels[value] }}" à "{{ ORDER_STATUS.labels[event.properties.attributes[name]]}}"
                                </template>
                                <template v-else-if="String(name) === 'delivery_number'">
                                    <template v-if="value !== ''">
                                        <strong>N° du bon de livraison</strong>: {{ event.properties.attributes[name] }}
                                    </template>
                                    <template v-else>
                                        Le n° du bon de livraison est passé de {{ value }} à {{ event.properties.attributes[name] }}
                                    </template>
                                </template>
                                <template v-else-if="String(name) === 'total_amount'">
                                    <template v-if="value !== ''">
                                        <strong>Nombre d'article</strong>: {{ event.properties.attributes[name] }}
                                    </template>
                                    <template v-else>
                                        Le nombre d'article est passé de {{ value }} à {{ event.properties.attributes[name] }}
                                    </template>
                                </template>
                                <template v-else-if="String(name) === 'credit_note_number'">
                                    <template v-if="value !== ''">
                                        <strong>N° de la note de crédit</strong>: {{ event.properties.attributes[name] }}
                                    </template>
                                    <template v-else>
                                        Le n° de la note de crédit est passé de {{ value }} à {{ event.properties.attributes[name] }}
                                    </template>
                                </template>
                                <template v-else-if="String(name) === 'invoice_number'">
                                    <template v-if="value !== ''">
                                        <strong>N° de facture</strong>: {{ event.properties.attributes[name] }}
                                    </template>
                                    <template v-else>
                                        Le n° de facture est passé de {{ value }} à {{ event.properties.attributes[name] }}
                                    </template>
                                </template>

                                <!--
                                <template v-else-if="String(name) === 'received_note'">
                                    <template v-if="value !== ''">
                                        <strong>Note de réception</strong>: <span v-html="event.properties.attributes[name]"/>
                                    </template>
                                    <template v-else>
                                        La note de réception est passé de "<span v-html="value"/>" à "<span v-html="event.properties.attributes[name]"/>"
                                    </template>
                                </template>
                                <template v-else-if="String(name) === 'invoiced_note'">
                                    <template v-if="value !== ''">
                                        <strong>Note de facturation</strong>: <span v-html="event.properties.attributes[name]"/>
                                    </template>
                                    <template v-else>
                                        La note de facturation est passé de "<span v-html="value"/>" à "<span v-html="event.properties.attributes[name]"/>"
                                    </template>
                                </template>
                                -->
                                <template v-else-if="String(name) === 'planned_at'">
                                    <template v-if="value !== ''">
                                        <strong>Planifié le</strong>: {{ getHumanizedDate(event.properties.attributes[name]) }}
                                    </template>
                                    <template v-else>
                                        Planifié le à change de {{ getHumanizedDate(value) }} à {{ getHumanizedDate(event.properties.attributes[name]) }}
                                    </template>
                                </template>
                                <template v-else-if="String(name) === 'received_at'">
                                    <template v-if="value !== ''">
                                        <strong>Reçu le</strong>: {{ getHumanizedDate(event.properties.attributes[name]) }}
                                    </template>
                                    <template v-else>
                                        Reçu le à changé de {{ getHumanizedDate(value) }} à {{ getHumanizedDate(event.properties.attributes[name]) }}
                                    </template>
                                </template>
                                <template v-else>
                                    <div><strong>{{ name }}</strong> est passé de {{ value }} à</div>
                                    <div class="inline" v-html="event.properties.attributes[name]" />
                                </template>
                            </div>
                        </li>
                    </ul>
                </template>

                <template v-if="event.event === 'z-note'">
                    <div class="flex gap-1">
                        <p class="italic">
                            Commentaire
                            <template v-if="event.notify_by_email">
                                (envoyé par email)
                            </template>
                        </p>:

                        <div>
                            <div v-html="event.description"></div>
                            <template v-if="event.uploads.data && event.uploads.data.length > 0">
                                <template v-for="upload in event.uploads.data">
                                    <a :href="upload.url" class="font-bold" target="_blank">{{ upload.name }}</a><br>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>

                <template v-if="event.event === 'z-note-invoiced'">
                    <div class="flex gap-1">
                        <p class="italic">
                            Note de facturation
                        </p>:

                        <div>
                            <div v-html="event.description"></div>
                            <template v-if="event.uploads.data && event.uploads.data.length > 0">
                                <template v-for="upload in event.uploads.data">
                                    <a :href="upload.url" class="font-bold" target="_blank">{{ upload.name }}</a><br>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>

                <template v-if="event.event === 'z-note-received'">
                    <div class="flex gap-1">
                        <p class="italic">
                            Note de réception
                        </p>:

                        <div>
                            <div v-html="event.description"></div>
                            <template v-if="event.uploads.data && event.uploads.data.length > 0">
                                <template v-for="upload in event.uploads.data">
                                    <a :href="upload.url" class="font-bold" target="_blank">{{ upload.name }}</a><br>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">

    import ORDER_STATUS from "../../../enums/order-status"
    import ProgressSpinner from "primevue/progressspinner";

    defineProps(['logs', 'isLoading'])

    const getHumanizedCreateAt = (date:any) => {
        const [datePart, timePart] = date.split(' ');
        // Split the date into year, month, and day
        const [year, month, day] = datePart.split('-').map(Number);
        // Split the time into hours, minutes, seconds, and milliseconds
        const [hours, minutes, seconds, milliseconds] = timePart.split(':').map(Number);

        // Create a new Date object using the parsed values
        const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);

        if(parsedDate) {
            return new Intl.DateTimeFormat(
                'fr-BE',
                {
                    day: "numeric",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }
            ).format(parsedDate)
        }
    }

    const getHumanizedDate = (date:any) => {

        if(date) {
            return new Intl.DateTimeFormat(
                'fr-BE',
                {
                    day: "numeric",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }
            ).format(new Date(date))
        }
    }
</script>
