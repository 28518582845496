<template>
    <Breadcrumbs
        :bread-crumbs-first-item="breadCrumbsFirstItem"
        :bread-crumbs-items="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Restaurants
                </h1>

                <Button @click="handleCreateOrEditRestaurant">+ Ajouter un restaurant</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="restaurants"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" field="name" :sortable="true" />

            <Column header="Manager" field="manager" :sortable="true" #body="slotProps">
                {{ slotProps.data.contact_email }}
            </Column>

            <Column header="Adresse" field="address" :sortable="true" />

            <Column header="Statut" field="is_active" :sortable="true" #body="slotProps">
                <Tag :severity="slotProps.data.status ? 'success' : 'danger'">{{slotProps.data.status ? 'Actif' : 'Inactif' }}</Tag>
            </Column>

            <Column key="actions" header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center gap-2">
                        <Button
                            link
                            label="Modifier"
                            @click.prevent="handleCreateOrEditRestaurant(slotProps.data)"
                            v-tooltip="'Éditer'">
                            <i class="pi pi-pencil"></i>
                        </Button>
                        <Button
                            link
                            label="Supprimer"
                            @click.prevent="handleDeleteRestaurant(slotProps.data)"
                            v-tooltip="'Supprimer'">
                            <i class="pi pi-trash"></i>
                        </Button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue"

    import DataTable from "primevue/datatable"
    import Column from "primevue/column"
    import Button from "primevue/button"
    import Tag from "primevue/tag"

    import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue"
    import Search from "../../../components/partials/backoffice/search.vue"

    import {useRouter} from "vue-router"
    import {useConfirm} from "primevue/useconfirm"

    import {storeToRefs} from "pinia";
    import { useRestaurantsStore } from "../../../store"

    import {RestaurantType} from "../../../types/restaurant"
    import useDatatableActions from "../../../composables/datatable-actions";

    const router = useRouter()
    const confirm = useConfirm()

    const restaurantsStore = useRestaurantsStore();
    const restaurantsRefs = storeToRefs(restaurantsStore);
    const pagination = restaurantsRefs.pagination;
    const isFetchListPending = restaurantsRefs.isFetchListPending;
    const restaurants = restaurantsRefs.list;

    const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });
    const breadCrumbsItems = computed(() => [{label: 'Restaurants'}]);

    const queryParams = ref({
        search: {
            search: null,
        },
        includes: [],
        sort_by: null,
        desc: false,
    })

    const {handlePageChange, handleSortChange, handleGlobalSearch} = useDatatableActions(pagination, queryParams, restaurantsStore.getItems)

    onBeforeMount(() => {
        restaurantsStore.getItems();
    })

    const handleDeleteRestaurant = (restaurant:RestaurantType) => {
        confirm.require({
            message: 'Êtes vous sur de vouloir supprimer ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Supprimer',
            rejectLabel: 'Annuler',
            acceptClass: 'p-button-danger',
            accept: () => {restaurantsStore.deleteItem(restaurant.id)}
        })
    }

    const handleCreateOrEditRestaurant = (restaurant:RestaurantType) => {
        restaurantsRefs.single.value = null
        router.push({name: 'restaurant-save',  params: (restaurant ? {id: restaurant.id} : {}) });
    }
</script>
