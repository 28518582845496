import {defineStore, storeToRefs} from 'pinia'
import {BaseStore} from "./base";
import {SurveyType} from "../types/survey";
import {BaseStoreType} from "../types/base-store";
import {useConfigsStore} from "./config";
import Axios from "axios";
import router from "../router";
import {ref} from "vue";

export const useSurveysStore = defineStore('surveys', () => {
    const resourceName = 'surveys';
    const baseUrl = '/api/surveys';

    const baseStore:BaseStoreType = new BaseStore<SurveyType>({
        resourceName,
        baseUrl,
        useStore: useSurveysStore()
    });

    const configsStore = useConfigsStore();
    const surveysStore = useSurveysStore();

    const deleteSection = (section_id:number) => {
        surveysStore.isDeletePending = section_id;

        return Axios.delete(baseUrl + '/sections/' + section_id, )
            .then(response => {
                const index = surveysStore.single.sections.data.findIndex( (item:any) => item.id === section_id );

                if (index !== -1) {
                    surveysStore.single.sections.data.splice(index, 1);
                }

                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Suppression réussie !'});
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 403) {
                    if(error.response.data.message === 'This action is unauthorized.') {
                        error.response.data.message =  'Action non autorisée';
                    }
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: error.response.data.message});
                }
                if (error.response.status === 401) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: 'Vous n\'avez pas accès'});
                    router.go(0);
                }
                if (error.response.status === 400) {
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                surveysStore.isDeletePending = null;
            });
    }

    const isMoveSectionPending = ref<boolean>(false);

    const moveSections = (movedFields:[] = []) => {
        isMoveSectionPending.value = true;

        return Axios.post('/api/sort/surveySection', {ids: movedFields.map((field:any) => field.id)})
            .then(response => {
                if(response.data.status === 'error') {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: "Resource can't be moved"})
                }else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Resource moved'})
                }
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isMoveSectionPending.value = false;
            });
    }

    const moveQuestions = (movedFields:[] = []) => {
        isMoveSectionPending.value = true;

        return Axios.post('/api/sort/surveyQuestion', {ids: movedFields.map((field:any) => field.id)})
            .then(response => {
                if(response.data.status === 'error') {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: "Resource can't be moved"})
                }else {
                    configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Resource moved'})
                }
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 400) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                isMoveSectionPending.value = false;
            });
    }

    return {
        ...baseStore,
        deleteSection,
        moveSections,
        moveQuestions,
        isMoveSectionPending
    };
});
