<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />

    <div class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Packagings
                </h1>

                <Button @click="handleCreateOrEditStorageUnit">+ Ajouter un packaging</Button>
            </div>

            <Search @search="handleGlobalSearch" />
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="storageUnits"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #empty>
                <p class="flex items-center justify-center p-5 text-lg font-bold">Aucun résultats</p>
            </template>

            <Column header="Nom" field="unit" :sortable="true" sort-field="label"></Column>

            <Column header="Fournisseur" field="supplier" :sortable="true" sort-field="supplier.name" #body="slotProps">
                {{ slotProps.data.supplier.name }}
            </Column>

            <Column header="Action(s)" class="w-1/4" #body="slotProps">
                <div class="flex items-center gap-2">
                    <Button
                        link
                        label="Modifier"
                        @click.prevent="handleCreateOrEditStorageUnit(slotProps.data)"
                        v-tooltip="'Éditer'">
                        <i class="pi pi-pencil"></i>
                    </Button>
                    <Button
                        link
                        label="Supprimer"
                        @click.prevent="handleDeleteStorageUnit(slotProps.data)"
                        v-tooltip="'Supprimer'">
                        <i class="pi pi-trash"></i>
                    </Button>
                </div>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import {useRouter} from "vue-router";
import {useConfirm} from "primevue/useconfirm";

import {storeToRefs} from "pinia";
import {useStorageUnitsStore} from "../../../store";

import Search from "../../../components/partials/backoffice/search.vue"
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";

import {StorageUnitType} from "../../../types/storage-unit";

import useDatatableActions from "../../../composables/datatable-actions"

const router = useRouter();
const confirm = useConfirm()

const storageUnitsStore = useStorageUnitsStore();
const storageUnitsRefs = storeToRefs(storageUnitsStore);
const pagination = storageUnitsRefs.pagination;

const isFetchListPending = storageUnitsRefs.isFetchListPending;
const storageUnits = storageUnitsRefs.list;

const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

const breadCrumbsItems = computed(() => [{label: 'Packagings'}]);

const queryParams = ref({
    search: {
        search: null,
    },
    includes: ['supplier'],
    sort_by: null,
    desc: false,
})

const {handlePageChange, handleSortChange, handleGlobalSearch} = useDatatableActions(pagination, queryParams, storageUnitsStore.getItems)

onBeforeMount(() => {
    storageUnitsStore.getItems(queryParams.value);
})

const handleDeleteStorageUnit = (storageUnit:StorageUnitType) => {
    confirm.require({
        message: 'Êtes vous sur de vouloir supprimer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        acceptClass: 'p-button-danger',
        accept: () => {storageUnitsStore.deleteItem(storageUnit.id)}
    })
}

const handleCreateOrEditStorageUnit = (storageUnit:StorageUnitType) => {
    storageUnitsRefs.single.value = null
    router.push({name: 'storageUnit-save',  params: (storageUnit ? {id: storageUnit.id} : {}) });
}
</script>
