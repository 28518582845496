<template>
    <Breadcrumb
        :home="breadCrumbsFirstItem"
        :model="breadCrumbsItems"
        class="mb-6"
        :pt="{
            root: {class: 'bg-primary-200 py-5 px-10'},
            list: {class: 'flex'}
        }"
    >
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span class="text-black font-bold">{{ item.label }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </template>
    </Breadcrumb>

    <div v-if="isFetchSupplierPending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10">
        <div class="flex mb-10 justify-between">
            <div class="flex items-center gap-2">
                <h1 class="mr-6 text-5xl uppercase font-title">
                    Produits de {{ supplier?.name }}
                </h1>

                <Button @click="handleCreateOrEditProduct">+ Ajouter un produit</Button>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" v-model="filters['global'].value" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="products.filter( (product:ProductType) => product.status === selectedStatus)"
            :loading="isFetchListPending"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="pagination?.total"
            :filters="filters"
            stateStorage="session"
            stateKey="dt-state-supplier-products"
            :globalFilterFields="['id', 'name', 'price', 'usage']"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <template #header>
                <div class="flex mb-4">
                    <Button
                        link
                        @click.prevent="handleChangeStatus(1)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: selectedStatus === 1 ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Actif
                    </Button>
                    <Button
                        link
                        @click.prevent="handleChangeStatus(0)"
                        class="py-3 pb-4 px-4 rounded-none outline-none"
                        :pt="{root: {class: selectedStatus === 0 ? 'border-0 border-b border-b-2 border-b-primary-400 text-primary-400' : 'border-none'}}"
                    >
                        Inactif
                    </Button>
                </div>
            </template>

            <Column header="ID" field="id" :sortable="true" sort-field="id"></Column>

            <Column header="Nom" :sortable="true" sort-field="name" #body="slotProps">
                {{slotProps.data?.translatable?.name[locale]}}
                <small>({{ slotProps.data?.conditioning_quantity }} {{ slotProps.data?.conditioning?.translatable?.label[locale] }})</small>
            </Column>

            <Column header="Prix" field="price" :sortable="true" sort-field="price" #body="slotProps">
                {{ slotProps.data.price }} €
            </Column>

            <Column header="Utilisation" field="usage" :sortable="true" sort-field="use"></Column>

            <Column header="Tag(s)" field="tags" :sortable="false" sort-field="supplier" #body="slotProps">
                <template v-for="tag in slotProps.data.tags.slice(0, 2)">
                    <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                </template>
                <template v-if="slotProps.data.tags.length > 2">
                    (+{{ slotProps.data.tags.length - 2 }})
                </template>
            </Column>

            <Column key="actions" header="Action(s)">
                <template #body="slotProps">
                    <div class="flex items-center">
                        <Button
                            label="Modifier"
                            @click.prevent="handleCreateOrEditProduct(slotProps.data)"
                            link
                            v-tooltip="'Éditer'">
                            <i class="pi pi-pencil"></i>
                        </Button>
                        <Button
                            label="Gestion des quantités maximum"
                            @click.prevent="handleStockManagementProduct(slotProps.data)"
                            link
                            v-tooltip="'Gestion des quantités maximum'">
                            <i class="pi pi-barcode"></i>
                        </Button>
                        <Button
                            label="Supprimer"
                            @click.prevent="handleDeleteProduct(slotProps.data)"
                            link
                            v-tooltip="'Supprimer'">
                            <i class="pi pi-trash"></i>
                        </Button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";

import InputText from "primevue/inputtext";
import InputIcon from "primevue/inputicon";
import IconField from "primevue/iconfield";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Tag from "primevue/tag";

import {useRoute, useRouter} from "vue-router";
import {useConfirm} from "primevue/useconfirm";
import useDebounce from "../../../composables/debounce";

import {storeToRefs} from "pinia";
import {useConfigsStore, useProductsStore, useSuppliersStore} from "../../../store";

import {ProductType} from "../../../types/product";
import ProgressSpinner from "primevue/progressspinner";

const route = useRoute()
const router = useRouter();
const confirm = useConfirm()

const configsStore = useConfigsStore()
const { locale } = storeToRefs(configsStore)

const productsStore = useProductsStore();
const productsRefs = storeToRefs(productsStore);
const pagination = productsRefs.pagination;
const isFetchListPending = productsRefs.isFetchListPending;
const products = productsRefs.list;

const suppliersStore = useSuppliersStore();
const suppliersRefs = storeToRefs(suppliersStore);
const isFetchSupplierPending = suppliersRefs.isFetchSinglePending;
const supplier = suppliersRefs.single;

const breadCrumbsFirstItem = computed( () => { return { label: 'Accueil' , route: '/admin' } });

const breadCrumbsItems = computed(() => [{label: 'Produits'}]);

const filters = ref({ global: { value: null } } );

onBeforeMount(() => {
    let sessionFilters = sessionStorage['dt-state-supplier-products'] ? JSON.parse(sessionStorage['dt-state-supplier-products']) : null;
    filters.value.global.value = sessionFilters?.filters?.global?.value;

    suppliersStore.getItem({id: String(route.params.id)})

    if(filters.value.global.value) {
        productsStore.getItems({search: {supplier_id: String(route.params.id), search: filters.value.global.value}, includes: ['tags', 'supplier', 'conditioning']})
    }else{
        productsStore.getItems({search: {supplier_id: String(route.params.id)}, includes: ['tags', 'supplier', 'conditioning']})
    }
})

const selectedStatus = ref(1);

const handlePageChange = (event:any) => {
    pagination.value.per_page = event.rows;
    pagination.value.current_page = event.page + 1;

    if(filters.value.global.value) {
        productsStore.getItems({search: {supplier_id: String(route.params.id), search: filters.value.global.value}, includes: ['tags', 'supplier', 'conditioning']})
    }else{
        productsStore.getItems({search: {supplier_id: String(route.params.id)}, includes: ['tags', 'supplier', 'conditioning']})
    }
}

const handleSortChange = (event:any) => {
    productsStore.getItems({
        search: {supplier_id: String(route.params.id)},
        sort_by: event.sortField,
        desc: event.sortOrder === 1, includes: ['tags', 'supplier', 'conditioning']
    })

    if(filters.value.global.value) {
        productsStore.getItems({
            search: {supplier_id: String(route.params.id), search: filters.value.global.value},
            sort_by: event.sortField,
            desc: event.sortOrder === 1, includes: ['tags', 'supplier', 'conditioning']
        })
    }else{
        productsStore.getItems({
            search: {supplier_id: String(route.params.id)},
            sort_by: event.sortField,
            desc: event.sortOrder === 1, includes: ['tags', 'supplier', 'conditioning']
        })
    }
}

const handleGlobalFilterChange = (value:string) => {
    let sessionFilters = sessionStorage['dt-state-supplier-products'] ? JSON.parse(sessionStorage['dt-state-supplier-products']) : null;
    sessionFilters.filters.global.value = value;
    sessionStorage['dt-state-supplier-products'] = JSON.stringify(sessionFilters);

    productsStore.getItems({
        search: {search: value, supplier_id: String(route.params.id)},
        includes: ['tags', 'supplier', 'conditioning']
    })
}

const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
    handleGlobalFilterChange(event);
}, 1000);

const handleDeleteProduct = (product:ProductType) => {
    confirm.require({
        message: 'Êtes vous sur de vouloir supprimer ?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Supprimer',
        rejectLabel: 'Annuler',
        acceptClass: 'p-button-danger',
        accept: () => {productsStore.deleteItem(product.id)}
    })
}

const handleCreateOrEditProduct = (product:ProductType) => {
    productsRefs.single.value = null
    router.push({name: 'product-save',  params: (product ? {id: product.id} : {}) });
}

const handleChangeStatus = async (newStatus:any) => {
    selectedStatus.value = newStatus;
    await productsStore.getItems({search: {supplier_id: String(route.params.id)}, includes: ['tags']})
}

const handleStockManagementProduct = (product:ProductType) => {
    router.push({name: 'product-stock',  params: {id: product.id} });
}

const handleTakeInventory = (restaurant_id:number) => {
    router.push({name: 'restaurant-inventory', params: {id: restaurant_id}});
}
</script>
