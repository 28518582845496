<template>
    <Breadcrumbs
        :breadCrumbsFirstItem="breadCrumbsFirstItem"
        :breadCrumbsItems="breadCrumbsItems"
    />


    <div v-if="isFetchSinglePending" class="h-[85.5vh] w-full flex items-center justify-center bg-black/75">
        <ProgressSpinner />
    </div>

    <div v-else class="mx-10 mb-10 py-10">
        <div class="flex mb-20 justify-between items-end">
            <div>
                <h1 class="mr-6 mb-2 text-5xl uppercase font-title">
                    Modifier la dépanne {{ order.reference }}
                </h1>
                <p><strong>Fournisseur</strong>: {{ order.supplier_restaurant.name }}</p>
                <p><strong>Restaurant</strong>: {{ order.restaurant.name }}</p>
            </div>

            <div>
                <IconField :ptOptions="{position: 'left'}">
                    <InputIcon class="pi pi-search" />
                    <InputText placeholder="Recherche" @update:modelValue="handleGlobalFilterChangeDebounced" />
                </IconField>
            </div>
        </div>

        <DataTable
            scrollable scrollHeight="100vh"
            :value="order?.items.data"
            :rows="pagination?.per_page"
            :rowsPerPageOptions="[5, 25, 50, 100, 500, 1000]"
            :totalRecords="order?.items?.data?.length"
            @page="handlePageChange($event)"
            @sort="handleSortChange($event)"
            lazy paginator showGridlines stripedRows removableSort
            currentPageReportTemplate="{currentPage}/{totalPages}"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            :pt="{
                header: {class: 'text-surface-600 font-bold text-sm'},
                mask: 'absolute z-50 flex items-center justify-center w-full h-full bg-black/50',
                loadingIcon: 'text-primary-200 animate-spin h-20 w-20'
            }"
        >
            <Column header="Nom" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.name }}
                <template v-if="slotProps.data.product.note">
                    <i class="text-orange-500 pi pi-exclamation-triangle"
                       v-tooltip="slotProps.data.product.note"
                    ></i>
                </template>
            </Column>

            <Column header="Prix" :sortable="true" #body="slotProps">
                {{ slotProps.data.price }} €
            </Column>

            <Column header="Conditionnement" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.conditioning_quantity }}
                {{ slotProps.data.product.conditioning.label }}
            </Column>

            <Column header="Utilisation" :sortable="true" #body="slotProps">
                {{ slotProps.data.product.usage }}
            </Column>

            <Column header="Tags" field="tags" :sortable="false" #body="slotProps">
                <template v-for="tag in slotProps.data.product.tags.slice(0, 2)">
                    <Tag class="mr-1" :style="`background-color: #${tag.color}`">{{ tag.label }}</Tag>
                </template>
                <template v-if="slotProps.data.product.tags.length > 2">
                    (+{{ slotProps.data.tags.length - 2 }})
                </template>
            </Column>

            <Column header="Commande max" field="max" :sortable="true" #body="slotProps">
                <p>{{ slotProps.data.product.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_informative }}
                    {{ slotProps.data.product.conditioning.label }} max (conseillée)</p>
                <p>{{ slotProps.data.product.restaurants.find( (restaurant:RestaurantType) => restaurant.id === order.restaurant_id)?.product_restaurant.max_quantity_blocking }}
                    {{ slotProps.data.product.conditioning.label }} max (limite)</p>
            </Column>

            <Column header="Q. commandée">
                <template #body="slotProps">
                    <InputNumber
                        placeholder="Quantité à commander"
                        v-model="slotProps.data.quantity"
                        :min="1"
                    />
                </template>
            </Column>
        </DataTable>

        <div>
            <Textarea
                id="comment"
                name="comment"
                placeholder="Commentaire libre"
                class="w-full"
                v-model="order.note"
            />
        </div>

        <div class="mb-20">
            <h2 class="mb-2">Total : 4 (187,90 €)</h2>
            <SplitButton
                label="Valider ma commande"
                @click="handleSubmitOrder(ORDER_STATUS.values.PLANNED.description)"
                :model="[{
                    label: 'Enregistrer comme brouillon',
                    command: () => { handleSubmitOrder(ORDER_STATUS.values.DRAFT.description) }
                }]"
                pt:pcmenu:item="p-2 cursor-pointer hover:opacity-80"
            />
        </div>

        <div class="flex gap-4 flex-col">
            <Logs :logs />
        </div>
    </div>
</template>
<script setup lang="ts">
import {computed, onBeforeMount, ref} from "vue";
import {useConfigsStore, useOrdersStore} from "../../../store";
import {storeToRefs} from "pinia";

import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import ProgressSpinner from "primevue/progressspinner";
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import {useRoute, useRouter} from "vue-router";
import {useForm} from "laravel-precognition-vue";
import useDebounce from "../../../composables/debounce";
import {RestaurantType} from "../../../types/restaurant";
import InputNumber from "primevue/inputnumber";
import Tag from "primevue/tag";
import ORDER_STATUS from "../../../enums/order-status";
import Textarea from "primevue/textarea";
import SplitButton from "primevue/splitbutton";
import ORDER_TYPE from "../../../enums/order-type";

import Logs from "../../../components/partials/backoffice/logs.vue"
import Breadcrumbs from "../../../components/partials/backoffice/breadcrumbs.vue";

const breadCrumbsFirstItem = computed( () => { return { label: 'Dépannes' , route: {name: 'conveniences' } } } );

const breadCrumbsItems = computed(() => {
    let items: { label: string, to?: { name: string, params?: any } } [] = [];

    items.push({label: order?.value?.restaurant.name})

    items.push({label: order?.value?.supplier_restaurant.name})

    items.push({label: 'Modifier'})

    return items;
});

const router = useRouter();
const route = useRoute()

const ordersStore = useOrdersStore();
const ordersRefs = storeToRefs(ordersStore);
const isFetchSinglePending = ordersRefs.isFetchSinglePending;
const order = ordersRefs.single
const logs = ordersRefs.logs

const pagination = ref({
    per_page: 50,
    current_page: 1
});

const handleGlobalFilterChange = (value:string) => {
    ordersStore.getItems({search: {search: value}})
}

const handleGlobalFilterChangeDebounced = useDebounce((event:any) => {
    handleGlobalFilterChange(event);
}, 1000);

onBeforeMount(() => {
    ordersStore.getItem({id: route.params.id, includes: ['items', 'items.product', 'items.product.tags', 'items.product.conditioning', 'items.product.restaurants']});

    ordersStore.getLogs({id: Number(route.params.id)});

    form.setData({
        items : order?.value?.items.data,
    })
});

const handlePageChange = (event:any) => {

}

const handleSortChange = (event:any) => {

}
const form = useForm('put', '/api/orders/' + route.params.id,
    {
        items: [],
        note: '',
        status: null
    }
);

const isFilepondPending = ref(false);

const handleLoadReceipt = ({files, id}: { files: [], id: number }) => {
    form.setData({[id]: files.map((file: { serverId: string }) => file.serverId)})
    isFilepondPending.value = true;
}

const configsStore = useConfigsStore();

const handleSubmitOrder = (status:number) => {
    // handle save as final or draft
    // type = 1 because it's an order
    order.value.status = Number(status);
    order.value.type = Number(ORDER_TYPE.values.CONVENIENCE.description);

    form
        .setData({
            ...order.value,
            items: order.value.items.data.map( (item:any) => {
                return {
                    id: item.id,
                    product_id: item.product_id,
                    quantity: item.quantity
                }
            })
        })
        .submit()
        .then(() => {
            if (route.params.form_id) {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le tag a bien été modifié'})
            } else {
                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Le tag a bien été créé'})
            }

            return router.push({name: 'conveniences'});
        })
        .catch(error => {
            if (error.response.status === 422) {
                configsStore.addToast({severity: 'warn', summary: 'Attention', detail: 'Il y a des erreurs dans le formulaire'})
            }
            if (error.response.status === 500) {
                configsStore.addToast({severity: 'error', summary: 'Attention', detail: 'Il y a une erreur serveur'})
            }
        })
        .finally(() => {
            ordersRefs.isSavePending.value = false;
        })

}
</script>
