import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {ReasonType} from "../types/reason";
import {BaseStoreType} from "../types/base-store";

export const useReasonsStore = defineStore('reasons', () => {
    const resourceName = 'reasons';
    const baseUrl = '/api/complaint-reasons';

    const baseStore:BaseStoreType = new BaseStore<ReasonType>({
        resourceName,
        baseUrl,
        useStore: useReasonsStore()
    });

    return {
        ...baseStore,
    };
});
