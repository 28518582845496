import {defineStore} from 'pinia'
import {BaseStore} from "./base";
import {ResponseType} from "../types/response";
import {BaseStoreType} from "../types/base-store";
import Axios from "axios";
import router from "../router";
import {useConfigsStore} from "./config";

export const useResponsesStore = defineStore('responses', () => {
    const resourceName = 'responses';
    const baseUrl = '/api/surveys/responses';

    const baseStore:BaseStoreType = new BaseStore<ResponseType>({
        resourceName,
        baseUrl,
        useStore: useResponsesStore()
    });

    const configsStore = useConfigsStore();
    const responsesStore = useResponsesStore();

    const deleteResponseItem = (response_id:number, item_id:number) => {
        responsesStore.isDeletePending = item_id;

        return Axios.delete(baseUrl + '/' + response_id + '/items/' + item_id, )
            .then(response => {
                const index = responsesStore.single.items.data.findIndex( (item:any) => item.id === item_id );

                if (index !== -1) {
                    responsesStore.single.items.data.splice(index, 1);
                }

                configsStore.addToast({severity: 'success', summary: 'Bravo', detail: 'Suppression réussie !'});
            })
            .catch(error => {
                console.warn(error);
                if (error.response.status === 404) {
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: 'Resource(s) non trouvée'});
                }
                if (error.response.status === 403) {
                    if(error.response.data.message === 'This action is unauthorized.') {
                        error.response.data.message =  'Action non autorisée';
                    }
                    configsStore.addToast({severity: 'warn', summary: 'Oups...', detail: error.response.data.message});
                }
                if (error.response.status === 401) {
                    console.warn(error.response);
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: 'Vous n\'avez pas accès'});
                    router.go(0);
                }
                if (error.response.status === 400) {
                    configsStore.addToast({severity: 'error', summary: 'Erreur', detail: error.response.data.message});
                }
            })
            .finally(() => {
                responsesStore.isDeletePending = null;
            });
    }

    return {
        ...baseStore,
        deleteResponseItem
    };
});
